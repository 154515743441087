import React, { useState, useEffect } from 'react';
import styles from "./UserArea.module.css";
import { connect } from 'react-redux';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { editUserDetails, cleaningOdretRes } from '../../../actions/RequestUserDatabase';
import { loadingSpinner } from '../../../actions/Loading';
import { useHistory } from 'react-router-dom';
const CryptoJS = require("crypto-js");

const UserArea = props => {
    const cookies = new Cookies();

    const orders = props.orders;
    const userData = props.userData;
    const uData = props.userData.userData;

    const history = useHistory();

    const [screenName, setScreenName] = useState('orders');
    const [mailErr, setMailErr] = useState('');
    const [businessNumberErr, setBusinessNumberErr] = useState('');
    const [postalCodeErr, setPostalCodeErr] = useState('');
    const [accountDetails, setAccountDetails] = useState({});
    useEffect(() => {
        setAccountDetails({
            name: uData?.name || '',
            lastName: uData?.lastName || '',
            phone: uData?.phone || '',
            mail: uData?.mail || '',
            address: uData?.address || '',
            companyName: uData?.companyName || '',
            businessNumber: uData?.businessNumber || '',
            postalCode: uData?.postalCode || '',
            moreInfo: uData?.moreInfo || '',
        });
    }, [uData]);
    const onClickSave = () => {
        if (mailErr || businessNumberErr || postalCodeErr) {
            alert('שים לב להערות באדום!');
            return;
        };
        const password = CryptoJS.AES.decrypt(cookies.get('passwordCrypto'), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
        props.editUserDetails(accountDetails, { userId: userData.userData.id, password: password });
        props.loadingSpinner(true);
    };

    const divOrders = () => {
        return Object.keys(orders).map((key, index) => (
            <div key={index} className={styles.contining_orders}>
                <div className={styles.orders_contining_b}>
                    <div className={styles.contind_img_orders}>
                        <div>
                            {orders[key].productImage ? <img src={orders[key].productImage} /> : ''}
                        </div>
                    </div>
                    <div className={styles.contind_details_orders}>
                        <h6>מספר הזמנה: {orders[key].orderNumFull}-{orders[key].orderItemNum}</h6>
                        <h5>{orders[key].productName}</h5>
                        {orders[key].size ? <p ><b className={styles.contind_details_orders_b}>גודל: </b>רוחב: {JSON.parse(orders[key].size).widthSize} - גובה: {JSON.parse(orders[key].size).heightSize}</p> : ''}
                        {JSON.parse(orders[key].productDetails).map((item, index) => (
                            <p key={index}><b className={styles.contind_details_orders_b}>{item.k}: </b>{item.v}</p>
                        ))}
                    </div>
                </div>
                <div className={styles.orders_contining_c}>
                    <div>
                        <p className={styles.orders_price}>
                            הוזמן בתאריך:
                            <br />
                            {moment(orders[key].createdAt).format('D/M/Y')}
                        </p>
                    </div>
                    <div>
                        <h6 className={styles.orders_price}>כמות {orders[key].quantity}</h6>
                    </div>
                    <div>
                        <h5 className={styles.orders_price}>{orders[key].price} ₪</h5>
                    </div>
                </div>
            </div>
        ));
    };
    const nameChange = e => {
        const mewDetails = { ...accountDetails };
        mewDetails['name'] = e.currentTarget.value;
        setAccountDetails(mewDetails);
    };
    const lastNameChange = e => {
        const mewDetails = { ...accountDetails };
        mewDetails['lastName'] = e.currentTarget.value;
        setAccountDetails(mewDetails);
    };
    const phoneChange = e => {
        const mewDetails = { ...accountDetails };
        mewDetails['phone'] = e.currentTarget.value;
        setAccountDetails(mewDetails);
    };
    const mailChange = e => {
        const mewDetails = { ...accountDetails };
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(e.currentTarget.value)) {
            setMailErr('האמייל לא תקין');
            mewDetails['mail'] = e.currentTarget.value;
            setAccountDetails(mewDetails);
        } else {
            setMailErr('');
            mewDetails['mail'] = e.currentTarget.value;
            setAccountDetails(mewDetails);
        };
    };
    const addressChange = e => {
        const mewDetails = { ...accountDetails };
        mewDetails['address'] = e.currentTarget.value;
        setAccountDetails(mewDetails);
    };
    const businessNumberChange = e => {
        const mewDetails = { ...accountDetails };
        if (e.currentTarget.value.length !== 9) {
            setBusinessNumberErr('שים לב! המספר הייב להיות 9 ספרות.');
        } else {
            setBusinessNumberErr('');
        }
        mewDetails['businessNumber'] = e.currentTarget.value;
        setAccountDetails(mewDetails);
    };
    const postalCodeChange = e => {
        const mewDetails = { ...accountDetails };
        if (e.currentTarget.value.length !== 7) {
            setPostalCodeErr('שים לב! המספר הייב להיות 7 ספרות.');
        } else {
            setPostalCodeErr('');
        }
        mewDetails['postalCode'] = e.currentTarget.value;
        setAccountDetails(mewDetails);
    };
    const companyNameChange = e => {
        const mewDetails = { ...accountDetails };
        mewDetails['companyName'] = e.currentTarget.value;
        setAccountDetails(mewDetails);
    };
    const moreInfoChange = e => {
        const mewDetails = { ...accountDetails };
        mewDetails['moreInfo'] = e.currentTarget.value;
        setAccountDetails(mewDetails);
    };
    return (
        <div className={styles.continer_all}>
            <div className={styles.continer_right}>
                <div className={styles.contining_customer_selection}>
                    <div onClick={() => setScreenName('orders')} className={styles.button_customer_selection}>הזמנות</div>
                    <div onClick={() => setScreenName('personalInformation')} className={styles.button_customer_selection}>איזור אישי</div>
                    <div onClick={() => history.push("/reset-password")} className={styles.button_customer_selection}>לאפס סיסמה</div>

                </div>
            </div>
            {screenName === 'orders' ? <div className={styles.continer_left}>
                <div className={styles.contining_title}>
                    <h2>הזמנות שלי</h2>
                    <div className={styles.line_below_title}></div>
                </div>
                <div className={styles.continer_body}>
                    <div className={styles.contining_right}>
                        <div className={styles.contining_orders_top}>
                            <h4>הזמנות ({Object.keys(orders).length})</h4>
                        </div>
                        {divOrders()}
                    </div>
                </div>
            </div> : ''}
            {screenName === 'personalInformation' ? <div className={styles.continer_left}>
                <div className={styles.contining_title}>
                    <h2>אזור אישי</h2>
                    <div className={styles.line_below_title}></div>
                </div>
                <div className={styles.account_details}>
                    <label >שם פרטי</label>
                    <input type="text" onChange={nameChange} placeholder="הכנס את שם הפרטי" value={accountDetails.name} />
                    <label >שם משפחה</label>
                    <input type="text" onChange={lastNameChange} placeholder="הכנס את שם המשפחה" value={accountDetails.lastName} />
                    <label >מס' טלפון</label>
                    <input type="number" onChange={phoneChange} placeholder="הכנס את המס' טלפון" value={accountDetails.phone} />
                    <label >אמייל</label>
                    {mailErr ? <div className={styles.err_message}>{mailErr}</div> : ''}
                    <input dir='ltr' style={{ textAlign: 'right' }} type="text" onChange={mailChange} placeholder="הכנס את האמייל" value={accountDetails.mail} />
                    <label >כתובת</label>
                    <input type="text" onChange={addressChange} placeholder="הכנס את הכתובת" value={accountDetails.address} />
                    <label >מיקוד</label>
                    {postalCodeErr ? <div className={styles.err_message}>{postalCodeErr}</div> : ''}
                    <input className={styles.inputNum} type="number" onChange={postalCodeChange} placeholder="הכנס את המס' בן 7 ספרות" value={accountDetails.postalCode} />
                    <label >מס' עוסק / עמותה / ת.ז.</label>
                    {businessNumberErr ? <div className={styles.err_message}>{businessNumberErr}</div> : ''}
                    <input type="number" onChange={businessNumberChange} placeholder="הכנס את המס' בן 9 ספרות" value={accountDetails.businessNumber} />
                    <label >שם החברה</label>
                    <input type="text" onChange={companyNameChange} placeholder="הכנס את שם החברה" value={accountDetails.companyName} />
                    <label >פרטים נוספים</label>
                    <textarea type="text" onChange={moreInfoChange} placeholder="הכנס פרטים נוספים" value={accountDetails.moreInfo} />
                    <div onClick={() => onClickSave()} className={styles.button}>שמור</div>
                </div>
            </div> : ''}
        </div>
    );
};


const mapStateToProps = state => {
    return {
        orders: state.orders,
        userData: state.users,
    }
}
export default connect(mapStateToProps, { editUserDetails, cleaningOdretRes, loadingSpinner })(UserArea);

