import React, { useEffect } from 'react';
import styles from "./LocationMap.module.scss";
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';


const LocationMap = ({}) => {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className={styles.continer}>
            <h1>מיקום במפה</h1>
            <iframe src={`https://maps.google.com/maps?q=32.0780324, 34.8420265&z=15&output=embed`} frameborder="0" ></iframe>
        </div >
    );
};


const mapStateToProps = state => {
    return {}
}
export default connect(mapStateToProps, {})(LocationMap);