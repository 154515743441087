const paiperWidth = 0.0001364541833;
export const calculatorExtraShipping = ({
    widthSize,
    heightSize,
    thereIsSize,
    allProperties,
    shippingBoxes,
    quantity,
    extraShippingPrice,
}) => {
    if (extraShippingPrice) {

        extraShippingPrice = JSON.parse(extraShippingPrice);
        const type = extraShippingPrice.type;

        switch (type) {
            case 'לפי אריזות':
                return calculateTypeBoxes({
                    widthSize,
                    heightSize,
                    thereIsSize,
                    allProperties,
                    shippingBoxes,
                    quantity,
                });
            case 'לפי כמויות':
                console.log('extraShippingPrice----');
                console.log(extraShippingPrice);
                const data = extraShippingPrice.data;
                return calculateTypeByQuantities({ quantity, data });
            default:
                return false;
        }

    } else {
        return false;
    };
};

const calculateTypeByQuantities = ({ quantity, data }) => {
    const extraShippingPrice = priceAccordingToQuantities({
        priceByQuantitiesJSON: data,
        quantity: quantity,
    });

    return {
        extraShippingPrice: extraShippingPrice,
        type: 'לפי אריזות',
    };
};

const calculateTypeBoxes = ({
    widthSize,
    heightSize,
    thereIsSize,
    allProperties,
    shippingBoxes,
    quantity,
}) => {
    if (thereIsSize) {

        const product3D = productCalculation3D({ widthSize, heightSize, allProperties });

        let resultMowMuchInBox = {
            quantityInBox: 0,
            depthMixQuantityInBox: 0,
            widthMixQuantityInBox: 0,
            allQuantityInBox: 0,
            box: {},
            boxId: 0,
        };

        shippingBoxes.forEach(item => {
            const objData = howMuchInBox({ product3D, box3D: item });
            if (objData.allQuantityInBox > resultMowMuchInBox.allQuantityInBox) {
                objData.box = item;
                objData.boxId = item.id;
                resultMowMuchInBox = objData;
            }
        });

        const quantityOfBoxes = Math.ceil(+quantity / resultMowMuchInBox.allQuantityInBox);

        const extraShippingPrice = priceAccordingToQuantities({
            priceByQuantitiesJSON: resultMowMuchInBox.box.price,
            quantity: (+resultMowMuchInBox.box.paymentFromAmount - 1) > 0 ? quantityOfBoxes - (+resultMowMuchInBox.box.paymentFromAmount - 1) : quantityOfBoxes,
        });

        return {
            quantityOfBoxes: quantityOfBoxes,
            extraShippingPrice: extraShippingPrice,
            resultMowMuchInBox: resultMowMuchInBox,
            type: 'לפי אריזות',
        };

    } else {
        return false;
    }
};

const productCalculation3D = ({ widthSize, heightSize, allProperties }) => {
    const size3D = {
        height: heightSize,
        width: widthSize,
        depth: 0,
    }
    Object.keys(allProperties)
        .map(key => allProperties[key])
        .filter(item => item.selectePaperWeight)
        .forEach(item => {
            const width = +item.selectePaperWeightValue * paiperWidth;
            switch (item.printingSidesValue) {
                case 'צד 1':
                    size3D.depth = size3D.depth + (width * +item.numberPagesValue);
                    break;
                case 'דו צדדי':
                    size3D.depth = size3D.depth + ((width * +item.numberPagesValue) / 2);
                    break;
                default:
                    return;
            }
        });
    return size3D;
};

const howMuchInBox = ({ product3D, box3D }) => {
    const variationA = howMuchInBoxCalculation({
        depth: product3D.height,
        height: product3D.depth,
        width: product3D.width,
        box3D: JSON.parse(box3D.data),
    });
    // console.log('###variationA###');
    // console.log(variationA);

    const variationB = howMuchInBoxCalculation({
        depth: product3D.width,
        height: product3D.depth,
        width: product3D.height,
        box3D: JSON.parse(box3D.data),
    });
    // console.log('###variationB###');
    // console.log(variationB);
    if (variationA.allQuantityInBox > variationB.allQuantityInBox) {
        return variationA;
    } else {
        return variationB;
    };
};

const howMuchInBoxCalculation = ({ depth, height, width, box3D }) => {
    // console.log('-------howMuchInBoxCalculation----------');
    if ((box3D.depth / depth) < 1 || (box3D.height / height) < 1 || (box3D.width / width) < 1) {
        return {
            quantityInBox: 0,
            depthMixQuantityInBox: 0,
            widthMixQuantityInBox: 0,
            allQuantityInBox: 0,
        };
    };
    let quantityInBox = 0;
    let depthMixQuantityInBox = 0;
    let widthMixQuantityInBox = 0;
    let allQuantityInBox = 0;
    let depthQ = 0;
    let heightQ = 0;
    let widthQ = 0;


    depthQ = Math.floor(box3D.depth / depth);
    heightQ = Math.floor(box3D.height / height);
    widthQ = Math.floor(box3D.width / width);

    quantityInBox = (heightQ * widthQ) * depthQ;
    allQuantityInBox = (heightQ * widthQ) * depthQ;

    const depthModulo = +box3D.depth - (depth * depthQ);
    const widthModulo = +box3D.width - (width * widthQ);

    // console.log({ depth, height, width, box3D });
    // console.log((box3D.depth / depth));
    // console.log((box3D.height / height));
    // console.log((box3D.width / width));
    // console.log('quantityInBox.....');
    // console.log('depthModulo---');
    // console.log(depthModulo);
    // console.log('widthModulo---');
    // console.log(widthModulo);
    // console.log(quantityInBox);

    if (height < depthModulo) {
        const variationDepthModuloA = howMuchInBoxCalculation({
            depth: height,
            height: depth,
            width: width,
            box3D: {
                depth: depthModulo,
                height: box3D.height,
                width: box3D.width,
            },
        });
        const variationDepthModuloB = howMuchInBoxCalculation({
            depth: height,
            height: width,
            width: depth,
            box3D: {
                depth: depthModulo,
                height: box3D.height,
                width: box3D.width,
            },
        });
        if (variationDepthModuloA.allQuantityInBox > variationDepthModuloB.allQuantityInBox) {
            if (variationDepthModuloA.allQuantityInBox > 0) depthMixQuantityInBox = depthMixQuantityInBox + variationDepthModuloA.allQuantityInBox;
        } else {
            if (variationDepthModuloB.allQuantityInBox > 0) depthMixQuantityInBox = depthMixQuantityInBox + variationDepthModuloB.allQuantityInBox;
        }
        allQuantityInBox = allQuantityInBox + depthMixQuantityInBox;
    };

    if (height < widthModulo) {
        const variationWidthModuloA = howMuchInBoxCalculation({
            depth: width,
            height: depth,
            width: height,
            box3D: {
                depth: box3D.depth,
                height: box3D.height,
                width: widthModulo,
            },
        });
        const variationWidthModuloB = howMuchInBoxCalculation({
            depth: depth,
            height: width,
            width: height,
            box3D: {
                depth: box3D.depth,
                height: box3D.height,
                width: widthModulo,
            },
        });
        if (variationWidthModuloA.allQuantityInBox > variationWidthModuloB.allQuantityInBox) {
            if (variationWidthModuloA.allQuantityInBox > 0) widthMixQuantityInBox = widthMixQuantityInBox + variationWidthModuloA.allQuantityInBox;
        } else {
            if (variationWidthModuloB.allQuantityInBox > 0) widthMixQuantityInBox = widthMixQuantityInBox + variationWidthModuloB.allQuantityInBox;
        }
        allQuantityInBox = allQuantityInBox + widthMixQuantityInBox;
    };


    return {
        quantityInBox: quantityInBox,
        depthMixQuantityInBox: depthMixQuantityInBox,
        widthMixQuantityInBox: widthMixQuantityInBox,
        allQuantityInBox: allQuantityInBox,
    };
};

const priceAccordingToQuantities = ({ priceByQuantitiesJSON, quantity }) => {
    let priceByQuantities = priceByQuantitiesJSON;
    if (isJson(priceByQuantitiesJSON)) {
        priceByQuantities = JSON.parse(priceByQuantitiesJSON);
    };
    let priceForPricing = 0;
    let quantityForPricing = 0;
    priceByQuantities.map(item => {
        if (quantity >= +item.quantity && quantityForPricing < +item.quantity) {
            priceForPricing = +item.price;
            quantityForPricing = +item.quantity;
        };
    });
    if (priceForPricing === 0) priceByQuantities.map(item => {
        if (quantityForPricing > +item.quantity || quantityForPricing === 0) {
            priceForPricing = +item.price;
            quantityForPricing = +item.quantity;
        };
    });;
    priceForPricing = priceForPricing * quantity;
    return Math.ceil(priceForPricing);
};

const isJson = str => {
    try {
        JSON.parse(str);
        if (JSON.parse(str) === null) return false;
    } catch (e) {
        return false;
    }
    return true;
};
