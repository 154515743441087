import React, { useState, useEffect } from 'react';
import styles from "./OrderDetailsPayment.module.scss";
import { connect } from 'react-redux';
import DisplayMore from '../../components/DisplayMore/DisplayMore';

const OrderDetailsPayment = ({
    orders,
    thereIsShipping,
    deliveriesData,
    setPriceDelivery,
    setFullAddress,
    VAT,
    price,
    setThereIsShipping,
    extraPriceForShipping,
    priceDelivery,
}) => {


    const [selectedDelivery, setSelectedDelivery] = useState('בחר עיר');
    const [street, setStreet] = useState('');
    const [houseNumber, setHouseNumber] = useState('');

    useEffect(() => {
        setFullAddress({ city: selectedDelivery, street: street, houseNumber: houseNumber });
    }, [selectedDelivery, street, houseNumber]);

    const checkboxDeliveryChange = (e) => {
        setThereIsShipping(e)
        if (thereIsShipping) {
            setSelectedDelivery('בחר עיר');
            setPriceDelivery(0);
            setStreet('');
            setHouseNumber('');
        };
    };

    const deliverySelectionDiv = () => {
        return deliveriesData.map((item, index) => (
            <option key={index}>{item.city}</option>
        ))
    };
    const deliveryChange = (e) => {
        setSelectedDelivery(e.currentTarget.value);
        return deliveriesData.map(item => {
            if (item.city === e.currentTarget.value) setPriceDelivery(item.price);
        });
    };
    const streetChange = (e) => {
        setStreet(e.currentTarget.value);
    };
    const houseNumberChange = (e) => {
        setHouseNumber(e.currentTarget.value);
    };

    const sumPrice = price + priceDelivery + (thereIsShipping ? extraPriceForShipping : 0);
    return (
        <div className={styles.continer}>
            <div className={styles.title}>
                פרטי הזמנה:
            </div>
            <div className={styles.category_titles}>
                <div style={{ width: '160px' }} className={styles.category_text}>
                    מוצר
                </div>
                <div style={{ width: '53px' }} className={styles.category_text}>
                    מפרט
                </div>
                <div style={{ width: '60px' }} className={styles.category_text}>
                    כמות
                </div>
                <div style={{ width: '100px' }} className={styles.category_text}>
                    סכום ביניים
                </div>
            </div>

            <div className={styles.product_list_box} id={styles.contining_items_style_15}>
                {
                    Object.keys(orders).map((key, index) => (
                        <div key={index} className={styles.contining_orders}>
                            <div className={styles.image_and_title}>
                                <div className={styles.contind_img_orders}>
                                    <img src={orders[key].productImage} />
                                </div>
                                <h5>{orders[key].productName}</h5>
                            </div>
                            <DisplayMore carts={orders} keyB={key} />
                            <div className={styles.orders_quantity}>
                                {orders[key].quantity}
                            </div>
                            <div className={styles.orders_price_box}>
                                <div className={styles.orders_price}>
                                    {orders[key].price} ש''ח
                                </div>
                                {thereIsShipping && <div>
                                    {orders[key].extraPriceForShipping ?
                                        <>
                                            <div className={styles.extraPriceForShipping_title}>תוספת משלוח</div>
                                            <div className={styles.extraPriceForShipping_price}>{orders[key].extraPriceForShipping} ₪</div>
                                        </>
                                        : ''}
                                    {orders[key].extraShipping ?
                                        orders[key].extraShipping.extraShippingPrice ?
                                            <>
                                                <div className={styles.extraPriceForShipping_title}>תוספת משלוח</div>
                                                <div className={styles.extraPriceForShipping_price}>{orders[key].extraShipping.extraShippingPrice} ₪</div>
                                            </>
                                            : ''
                                        : ''}
                                </div>}
                            </div>
                        </div>
                    ))}
            </div>
            <div className={styles.delivery_title}>בחר סוג משלוח: </div>
            <div className={styles.delivery_question_box} onChange={e => checkboxDeliveryChange(e.target.value)}>
                <div className={styles.delivery_line_box}>
                    <input type="radio" value={''} name="gender" defaultChecked />
                    <p>איסוף עצמי. רח' כהנמן 100 בני ברק</p>
                </div>
                <div className={styles.delivery_line_box}>
                    <input type="radio" value={true} name="gender" />
                    <p>שליח עד הבית</p>
                </div>
            </div>
            {thereIsShipping && <div className={styles.contining_delivery_details}>
                <select type="text" value={selectedDelivery} onChange={deliveryChange}>
                    <option disabled>בחר עיר</option>
                    {deliverySelectionDiv()}
                </select>
                <input type="text" onChange={streetChange} placeholder="הכנס את שם הרחוב" value={street} />
                <input type="number" onChange={houseNumberChange} placeholder="הכנס את מס' בית" value={houseNumber} />
            </div>}
            <div className={styles.contining_delivery_payment}>
                <p> סה''כ לתשלום מוצרים {price.toFixed(2)} ₪</p>
                {thereIsShipping && <p> לתשלום משלוח  {priceDelivery.toFixed(2)} ₪</p>}
                {thereIsShipping && <p> לתשלום תוספת משלוח  {extraPriceForShipping.toFixed(2)} ₪</p>}
                <p> מע''מ  {((sumPrice * VAT) - sumPrice).toFixed(2)} ₪</p>
            </div>
            <div className={styles.contining_payment}>
                <h5>
                    <p>סה"כ</p>
                    <p>
                        {(sumPrice * VAT).toFixed(2)}
                        <span>ש''ח</span>
                    </p>
                </h5>
            </div>
        </div>
    );
};


const mapStateToProps = state => {
    return {
        deliveriesData: state.deliveries,
        VAT: state.VAT,
    }
}
export default connect(mapStateToProps, {})(OrderDetailsPayment);

