import React, { useEffect, useState } from 'react';
import styles from "./Questionnaire.module.scss";
import { connect } from 'react-redux';

const questionnaireData = [
    {
        question: 'האם יש אפשרות לחיתוך?  ',
        answer: ''
    },
    {
        question: 'האם חייבים להשאיר שוליים לבנים? ',
        answer: ''
    },
    {
        question: 'האם חייבים להשאיר שוליים לבנים? ',
        answer: ''
    },
    {
        question: 'האם חייבים להשאיר שוליים לבנים? ',
        answer: ''
    },
    {
        question: 'האם חייבים להשאיר שוליים לבנים? ',
        answer: 'כן כדי שהמכונה תוכל להחזיק את הגליון חייב להשאר שוליים פנימיים של 2 ס"מ הגודל המלא של ההדפסה 46/66'
    },
]

const Questionnaire = ({ quesData }) => {

    const [questionData, setQuestionData] = useState(quesData);

    useEffect(() => {
        if (quesData.length === 0 || !quesData) {
            setQuestionData(questionnaireData);
        };
    }, [quesData]);

    const openAnswer = index => {
        // const newQuestionData = [...questionData];
        const newQuestionData = questionData.map(item => {
            item.isOpen = false;
            return item;
        });
        newQuestionData[index].isOpen = true;
        setQuestionData(newQuestionData);
    };

    return (
        <div className={styles.continer}>
            {
                questionData.map((item, index) => (
                    <div key={index} className={styles.ques_item_box}>
                        <div className={styles.question_text} style={item.isOpen ? {backgroundColor: '#FF1694'} : {}} onClick={() => openAnswer(index)}>
                            {item.question}
                        </div>
                        {item.isOpen && <div className={styles.answer_text}>
                            {item.answer}
                        </div>}
                    </div>
                ))
            }
        </div>
    );
};


const mapStateToProps = state => {
    return {}
}
export default connect(mapStateToProps, {})(Questionnaire);

