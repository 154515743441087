import React, { useState, useEffect } from 'react';
import styles from "./ChangePassword.module.scss";
import { connect } from 'react-redux';
import { MDBIcon } from 'mdbreact';
import { Link, useHistory } from "react-router-dom";
import { changePasswordFun, variableCleaning } from "../../../actions/RequestUserDatabase";

const ChangePassword = props => {
    const amail = props.match.params.mail;
    const encryption = props.match.params.encryption;
    const usersData = props.users;

    const [password, setPassword] = useState('');
    const [passwordVerification, setPasswordVerification] = useState('');
    const [passwordVerificationErr, setPasswordVerificationErr] = useState('');
    const [succeeded, setSucceeded] = useState(false);
    const [errMessage, setErrMessage] = useState(false);

    useEffect(() => {
        if (usersData.type === 'succeeded') {
            setSucceeded(true);
        };
        if (usersData.type === 'mailIsNotExisist') {
            setErrMessage('משהו השתבש בתהליך המייל שגוי, נסה שוב מהתחלה');
        };
        if (usersData.type === 'securityBreach') {
            setErrMessage('משהו השתבש בתהליך פריצת הבטחה, נסה שוב מהתחלה');
        };
    }, [usersData]);

    const handleSudmit = () => {
        if (passwordVerificationErr) {
            return;
        }

        if (password !== passwordVerification) {
            setPasswordVerificationErr('*הסיסמה לא אותו דבר!');
            return;
        }

        setErrMessage('');
        props.changePasswordFun({
            mail: amail,
            password: password.trim(),
            encryption: encryption,
        })
    };

    const passwordVerificationChange = (e) => {
        if (password === e.currentTarget.value) {
            setPasswordVerificationErr('');
        }
        setPasswordVerification(e.currentTarget.value);
    };

    return (
        <div className={styles.signup}>
            {succeeded ? <div className={styles.signup_classic}>
                <h2>הסיסמה למייל  {usersData.data.mail} אופסה בהצלחה</h2>
                <div className={styles.form}>
                    <Link to="/">
                        <button onClick={() => props.variableCleaning('resetPassword')} className={styles.btn}>חזור למסך הבית</button>
                    </Link>
                    <Link to="/login">
                        <button onClick={() => props.variableCleaning('resetPassword')} className={styles.btn}>חזור למסך התחברות</button>
                    </Link>
                </div>
            </div> :
                <div className={styles.signup_classic}>
                    <h2>לאפס סיסמה</h2>
                            {errMessage ? <div className={styles.err_message}>{errMessage}</div> : ''}
                    <div className={styles.form}>
                        <fieldset className={styles.password}>
                            <label> סיסמה חדשה</label>
                            <input onChange={e => setPassword(e.currentTarget.value)} value={password} type="password" placeholder="סיסמה"></input>
                            <MDBIcon className={styles.MDBIcon} icon="unlock-alt" />
                        </fieldset>
                        <fieldset className={styles.password}>
                            <label>אימת סיסמה</label>
                            <input onChange={passwordVerificationChange} value={passwordVerification} type="password" placeholder="אימת סיסמה"></input>
                            <MDBIcon className={styles.MDBIcon} icon="unlock-alt" />
                            {passwordVerificationErr ? <div className={styles.err_message}>{passwordVerificationErr}</div> : ''}
                        </fieldset>
                        <button onClick={() => handleSudmit()} className={styles.btn}>המשך</button>
                    </div>
                </div>
            }
        </div>
    );
};


const mapStateToProps = state => {
    return {
        allData: state.allCalculatorDatabase,
        webmaster: state.webmaster,
        users: state.users.changePassword,
    }
}
export default connect(mapStateToProps, { changePasswordFun, variableCleaning })(ChangePassword);
