export const LOADING = 'LOADING';
export const RUN_MESSAGE_SUCCEEDED = 'RUN_MESSAGE_SUCCEEDED';

export const loadingSpinner = (yesOrNo) => {
    return async (dispatch) => {
        dispatch({ type: LOADING, payload: yesOrNo});
    }
};
export const runMessageSucceeded = (yesOrNo, text) => {
    return async (dispatch) => {
        dispatch({ type: RUN_MESSAGE_SUCCEEDED, payload: {yesOrNo, text}});
    }
};
