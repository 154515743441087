import { CHANGE_LOCATION_PATH } from "../actions/locationPath";

const defaultState = {
    tab: { name: '', id: '' },
    category: { name: '', id: '' },
    categorySku: { name: '', id: '' },
    productSku: { name: '', id: '' },
    product: { name: '', id: '' },
};

const variable = (state = defaultState, action) => {
    switch (action.type) {
        case CHANGE_LOCATION_PATH:
            const newState = {...state};
            newState[action.payload.key] = action.payload.value 
            return newState;
        default:
            return state;

    }
}

export default variable;