import { isArray } from 'lodash';

import axios from 'axios';

const { LOADING, RUN_MESSAGE_SUCCEEDED } = require('./Loading');
const {
    CHANGE_UPLOADING_STATUS,
    CHANGE_PERCENTAGE_UPLOADING,
    FINISH_PERCENTAGE_UPLOADING,
} = require('./uploadingFile/uploadingFile');

const {
    UPLOAD_FILE_TO_LOCAL_STORAGE,
} = require('./uploadingFile/uploadingFileLocalStorage');

export const SING_UP = 'SING_UP';
export const LOGIN = 'LOGIN';
export const LOGIN_COOKIES = 'LOGIN_COOKIES';
export const CLENING = 'CLENING';
export const CHANGE_VALUE_USER = 'CHANGE_VALUE_USER';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const GET_USER_CART = 'GET_USER_CART';
export const LOAD_SHOPPING_CART = 'LOAD_SHOPPING_CART';
export const LOAD_ORDERS = 'LOAD_ORDERS';
export const UPTATE_USER_ORDER = 'UPTATE_USER_ORDER';
export const UPTATE_USER_CART = 'UPTATE_USER_CART';
export const UPTATE_USER_CART_LOCAL_STORIGE = 'UPTATE_USER_CART_LOCAL_STORIGE';
export const DELETE_USER_CART = 'DELETE_USER_CART';
export const CLEAR_USER_CART = 'CLEAR_USER_CART';
export const SET_DATA_PAYMENT = 'SET_DATA_PAYMENT';
export const ADD_USER_ORDERS = 'ADD_USER_ORDERS';
export const DELETE_FROM_CART = 'DELETE_FROM_CART';
export const UPTATE_USER_DATA = 'UPTATE_USER_DATA';
export const APPROVER_PAYMENT = 'APPROVER_PAYMENT';
export const UPDATE_CART_FROM_LOCAL_STORAGE = 'UPDATE_CART_FROM_LOCAL_STORAGE';
export const DELETE_USER_CART_LOCAL_STORIGE = 'DELETE_USER_CART_LOCAL_STORIGE';
export const DELETE_FROM_CARTLOCAL_STORAGE = 'DELETE_FROM_CARTLOCAL_STORAGE';
export const UPDATE_MANAGER_STATUS = 'UPDATE_MANAGER_STATUS';



let url = process.env.REACT_APP_SERVER_NODE_URL;

if (localStorage.getItem('StubMode') === 'yes') url = process.env.REACT_APP_SERVER_NODE_URL_DEVELOP;



export const singUp = (obj) => {
    return async (dispatch) => {
        axios({
            method: 'post',
            url: url + '/authentication-user/sign-up',
            data: { data: obj }
        })
            .then(result => {
                dispatch({ type: SING_UP, payload: result.data });
            })
            .catch(function (error) {
                console.log(error);
            });

    }
};
export const LoginFunCookies = (obj) => {
    return async (dispatch) => {
        axios({
            method: 'post',
            url: url + '/authentication-user/Login',
            data: { data: obj }
        })
            .then(result => {
                console.log(result);
                dispatch({ type: LOGIN_COOKIES, payload: result.data });
                dispatch({ type: LOAD_SHOPPING_CART, payload: result.data.shoppingCarts });
                dispatch({ type: LOAD_ORDERS, payload: result.data.orders });
                dispatch({ type: UPDATE_MANAGER_STATUS, payload: result.data.data });
            })
            .catch(function (error) {
                console.log(error);
            });

    }
};
export const updateUnLoginUserCartLocalStorage = () => {
    return async (dispatch) => {
        const cart = JSON.parse(localStorage.getItem('userCart'));
        dispatch({ type: UPDATE_CART_FROM_LOCAL_STORAGE, payload: cart });
    }
};
export const LoginFun = (obj) => {
    return async (dispatch) => {
        axios({
            method: 'post',
            url: url + '/authentication-user/Login',
            data: { data: obj }
        })
            .then(result => {
                dispatch({ type: LOGIN, payload: result.data });
                dispatch({ type: LOAD_SHOPPING_CART, payload: result.data.shoppingCarts });
                dispatch({ type: LOAD_ORDERS, payload: result.data.orders });
                dispatch({ type: UPDATE_MANAGER_STATUS, payload: result.data.data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};
export const LoginFunGoogle = (obj) => {
    return async (dispatch) => {
        axios({
            method: 'post',
            url: url + '/authentication-user/Login-google',
            data: { data: obj }
        })
            .then(result => {
                dispatch({ type: LOGIN, payload: result.data });
                dispatch({ type: LOAD_SHOPPING_CART, payload: result.data.shoppingCarts });
                dispatch({ type: LOAD_ORDERS, payload: result.data.orders });
                dispatch({ type: UPDATE_MANAGER_STATUS, payload: result.data.data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};
export const resetPasswordFun = (obj) => {
    return async (dispatch) => {
        axios({
            method: 'post',
            url: url + '/authentication-user/reset-password',
            data: { data: obj }
        })
            .then(result => {
                dispatch({ type: RESET_PASSWORD, payload: result.data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};
export const changePasswordFun = (obj) => {
    return async (dispatch) => {
        axios({
            method: 'post',
            url: url + '/authentication-user/change-password',
            data: { data: obj }
        })
            .then(result => {
                dispatch({ type: CHANGE_PASSWORD, payload: result.data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};
export const variableCleaning = (variableToCleaning) => {
    return async (dispatch) => { dispatch({ type: CLENING, payload: variableToCleaning }) }
};
export const changeValueUser = (key, value) => {
    return async (dispatch) => { dispatch({ type: CHANGE_VALUE_USER, payload: { key, value } }) }
};

export const addToUserCartFun = (obj) => {
    // users
    return async (dispatch, getState) => {
        if (getState().users.userData.id) {
            axios({
                method: 'post',
                url: url + '/authentication-user/add-to-user-cart',
                data: { data: obj, userId: getState().users.userData.id }
            })
                .then(result => {
                    dispatch({ type: UPTATE_USER_CART, payload: result.data.cartData });
                    result.data.filehendler.map(item => {
                        const objItem = obj;
                        if (objItem.file) {
                            if (objItem.fileID === item.fileID) {
                                if (isArray(objItem.file)) {
                                    objItem.file.forEach(element => {
                                        if (element) saveCartFile(item, element, dispatch, url);
                                    });
                                } else {
                                    saveCartFile(item, objItem.file, dispatch, url);
                                };
                            }
                        }
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            dispatch({ type: UPTATE_USER_CART_LOCAL_STORIGE, payload: obj });
            dispatch({ type: UPLOAD_FILE_TO_LOCAL_STORAGE, payload: { fileID: obj.fileID, file: obj.file } });
        }
    }
};
const saveCartFile = (item, file, dispatch, url) => {
    let data = new FormData();
    data.append('cartId', item.numOrder);
    data.append('file', file);
    dispatch({ type: CHANGE_UPLOADING_STATUS, payload: true });
    let config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: function (progressEvent) {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            dispatch({
                type: CHANGE_PERCENTAGE_UPLOADING, payload: {
                    numOrder: item.numOrder,
                    percentage: percentCompleted,
                    title: 'מעלה קובץ לסל מספר:',
                }
            });
        },
    };
    axios.post(url + '/file-hendler/save-by-cart', data, config)
        .then(function (res) {
            dispatch({ type: FINISH_PERCENTAGE_UPLOADING, payload: { numOrder: item.numOrder } });
        })
        .catch(function (err) {
            console.log(err);
        });
}
export const deleteUserCartFun = (obj) => {
    return async (dispatch, getState) => {
        if (getState().users.userData.id) {
            axios({
                method: 'post',
                url: url + '/authentication-user/delete-user-cart',
                data: obj
            })
                .then(() => {
                    dispatch({ type: DELETE_USER_CART, payload: obj.id });
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            dispatch({ type: DELETE_USER_CART_LOCAL_STORIGE, payload: obj.localStorageId });
        }
    }
};
export const clearUserCartFun = () => {
    return async (dispatch) => {
        dispatch({ type: CLEAR_USER_CART, payload: {} });
    }
};
export const sedOrderPayment = (obj) => {
    return async (dispatch) => {
        dispatch({ type: SET_DATA_PAYMENT, payload: obj });
    }
};
export const approvedPayment = (obj) => {
    return async (dispatch) => {
        dispatch({ type: APPROVER_PAYMENT, payload: obj });
    }
};

export const addUserOdretFun = (obj, ids) => {
    console.log(obj);
    return async (dispatch, getState) => {
        axios({
            method: 'post',
            url: url + '/authentication-user/add-user-order',
            data: { data: obj, ids }
        })
            .then(result => {
                if (getState().users.userData.id) {
                    dispatch({ type: ADD_USER_ORDERS, payload: result.data });
                    dispatch({ type: DELETE_FROM_CART, payload: obj.ordersObj });
                    dispatch({ type: LOAD_ORDERS, payload: result.data.orders });
                } else {
                    dispatch({ type: LOAD_ORDERS, payload: result.data.orders });
                    dispatch({ type: ADD_USER_ORDERS, payload: result.data });
                    dispatch({ type: DELETE_FROM_CARTLOCAL_STORAGE, payload: obj.ordersObj });
                };
                result.data.filehendler.map(item => {
                    Object.keys(obj.ordersObj).map(key => {
                        const objItem = obj.ordersObj[key];
                        if (objItem.file) {
                            if (objItem.fileID === item.fileID) {
                                if (isArray(objItem.file)) {
                                    objItem.file.forEach(element => {
                                        if (element) saveOrderFile(item, element, dispatch, url);
                                    });
                                } else {
                                    saveOrderFile(item, objItem.file, dispatch, url);
                                };
                            }
                        }
                    })
                });
                if (!getState().users.userData.id) {
                    const uploadingFileArr = getState().uploadingFileLocalStorageData.uploadingFileArr;
                    result.data.filehendlerLocalStorage.map(item => {
                        uploadingFileArr.map(objItem => {
                            if (objItem.file) {
                                if (objItem.fileID === item.fileID) {
                                    if (isArray(objItem.file)) {
                                        objItem.file.forEach(element => {
                                            if (element) saveOrderFile(item, element, dispatch, url);
                                        });
                                    } else {
                                        saveOrderFile(item, objItem.file, dispatch, url);
                                    };
                                }
                            }
                        })
                    });
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }
};
const saveOrderFile = (item, file, dispatch, url) => {
    let data = new FormData();
    data.append('numOrder', item.numOrder);
    data.append('file', file);
    dispatch({ type: CHANGE_UPLOADING_STATUS, payload: true });
    let config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: function (progressEvent) {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            dispatch({ type: CHANGE_PERCENTAGE_UPLOADING, payload: { numOrder: item.numOrder, percentage: percentCompleted, title: 'מעלה קובץ של מספר הזמנה:' } });
        },
    };
    axios.post(url + '/file-hendler/save-by-order', data, config)
        .then(function (res) {
            dispatch({ type: FINISH_PERCENTAGE_UPLOADING, payload: { numOrder: item.numOrder } });
        })
        .catch(function (err) {
            console.log(err);
        });
}

export const fileTest = (file) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_UPLOADING_STATUS, payload: true });
        let data = new FormData();
        data.append('numOrder', 'SAL-2020-3006-1');
        data.append('file', file);
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: function (progressEvent) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                dispatch({ type: CHANGE_PERCENTAGE_UPLOADING, payload: { numOrder: 'SAL-2022-23-8', percentage: percentCompleted, title: 'מעלה קובץ של בדיקה:' } });
            },
        };
        axios.post(url + '/file-hendler/save-by-order', data, config)
            .then(function (res) {
                dispatch({ type: FINISH_PERCENTAGE_UPLOADING, payload: { numOrder: 'SAL-2022-23-8' } });
            })
            .catch(function (err) {
                console.log(err);
            });
    }
};
export const cleaningOdretRes = () => {
    return async (dispatch) => {
        dispatch({ type: ADD_USER_ORDERS, payload: {} });
    }
};
export const editUserDetails = (obj, userId) => {
    return async (dispatch) => {
        axios({
            method: 'post',
            url: url + '/authentication-user/edit-user-details',
            data: { data: obj, userId }
        })
            .then(result => {
                // dispatch({ type: UPTATE_USER_DATA, payload: result.data });
                dispatch({ type: LOADING, payload: false });
                dispatch({ type: RUN_MESSAGE_SUCCEEDED, payload: { yesOrNo: true, text: 'הפרטים שלך נשמרו בהצלחה' } });
                window.location.reload()

            })
            .catch(function (error) {
                console.log(error);
            });
    }
};