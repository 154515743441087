import React, { useState } from 'react';
import styles from "./AboutUs.module.scss";
import { connect } from 'react-redux';
import SimpleImageSlider from "react-simple-image-slider";
import flashA from '../assets/flash/flashA.jpg';
import flashB from '../assets/flash/flashB.jpg';
import flashC from '../assets/flash/flashC.jpg';
import flashD from '../assets/flash/flashD.jpg';
import flashH from '../assets/flash/flashH.jpg';
import flashK from '../assets/flash/flashK.jpg';
import flashL from '../assets/flash/flashL.jpg';
import { useHistory } from 'react-router-dom';

const arrImages = [
    { url: flashA },
    { url: flashB },
    { url: flashC },
    { url: flashD },
    { url: flashH },
    { url: flashK },
    { url: flashL },
];


const AboutUs = props => {

    const history = useHistory();


    return (
        <div className={styles.continer}>
            <div className={styles.right_content}>
                <div className={styles.black_box}>
                    <div className={styles.top_text}>
                        להדפיס
                    </div>
                    <div className={styles.bottom_text}>
                        מבלי לצאת מהבית
                    </div>
                </div>
                <div className={styles.text_box}>
                    כל הפקות דפוס
                    <br/>
                    במקום אחד
                </div>
                <div onClick={() => history.push("/home-page/all-products")} className={styles.button_box}>
                   לכל המוצרים
                </div>
            </div>
            <SimpleImageSlider
                width='70vw'
                height='33vw'
                images={arrImages}
                showBullets={false}
                showNavs={false}
                autoPlay={true}
                slideDuration={2}
            />
        </div>
    );
};


const mapStateToProps = state => {
    return {}
}
export default connect(mapStateToProps, {})(AboutUs);