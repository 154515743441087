import styles from "./App.module.scss";

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Header from './components/Header/Header';
import { Switch, Route } from "react-router-dom";
import { changeValueUser, LoginFunCookies } from './actions/RequestUserDatabase';
import { requestAllDeliveries } from './actions/deliveriesDatabase';
import Spinner from './components/components/Spinner/Spinner';
import UploadingFile from './components/components/UploadingFile/UploadingFile';
import MessageSucceeded from './components/components/MessageSucceeded/MessageSucceeded';
import Body from './components/Body/Body';
import Login from './components/Authentication/Login/Login';
import SignUp from './components/Authentication/SignUp/SignUp';
import ResetPassword from './components/Authentication/ResetPassword/ResetPassword';
import ChangePassword from './components/Authentication/ChangePassword/ChangePassword';
import Cart from './components/User/Cart/Cart';
import OrderPayment from './components/User/OrderPayment/OrderPayment';
import UserArea from './components/User/UserArea/UserArea';
import Footer from './components/Footer/Footer';
import Item from './components/Body/components/HomePage/SaleProducts/Item/Item';
import { dataSaleProducts } from './components/Body/components/HomePage/SaleProducts/dataSaleProducts';
import { updateUnLoginUserCartLocalStorage } from './actions/RequestUserDatabase';

import Cookies from 'universal-cookie';
import DisplayTabs from './components/Body/components/DisplayTabs/DisplayTabs';
import CalculatorProductsScreen from './components/Body/components/CalculatorProductsScreen/CalculatorProductsScreen';
import ModelsSKUScreen from './components/Body/components/ModelsSKUScreen/ModelsSKUScreen';
import { Redirect } from "react-router-dom";
import ErrorMessage from "./components/components/ErrorMessage/ErrorMessage";
import StubMode from "./components/components/StubMode/StubMode";
import WebsiteUnderDevelopment from "./components/pages/WebsiteUnderDevelopment/WebsiteUnderDevelopment";
import EditProduct from "./components/pages/EditProduct/EditProduct";
const CryptoJS = require("crypto-js");



const App = props => {

  const usersData = props.users;
  const isManager = props.webmaster.isManager;
  let websiteUnderDevelopment = true;
  const isAdmin = props.user.userData.userType === 'management';
  if (isAdmin) websiteUnderDevelopment = false;
  useEffect(() => {
    const cookies = new Cookies();
    let mailCrypto = cookies.get('mailCrypto');
    let passwordCrypto = cookies.get('passwordCrypto');
    if (mailCrypto && passwordCrypto) {
      props.requestAllDeliveries();
      mailCrypto = CryptoJS.AES.decrypt(mailCrypto, process.env.REACT_APP_SECRET_KEY);
      passwordCrypto = CryptoJS.AES.decrypt(passwordCrypto, process.env.REACT_APP_SECRET_KEY);
      mailCrypto = mailCrypto.toString(CryptoJS.enc.Utf8);
      passwordCrypto = passwordCrypto.toString(CryptoJS.enc.Utf8);
      props.LoginFunCookies({
        mail: mailCrypto,
        password: passwordCrypto,
      });
    } else {
      props.requestAllDeliveries();
      props.updateUnLoginUserCartLocalStorage();
    };
  }, []);
  useEffect(() => {
    if (usersData.type === 'succeeded') {
      props.changeValueUser('isLogin', true);
    };
  }, [usersData]);
  return (
    <div className={styles.App_continer}>
      {isManager && <StubMode />}
      {props.uploadingFileData.status && <UploadingFile />}
      {props.loading.loadingSpinner && <Spinner />}
      {props.loading.messageSucceeded && <MessageSucceeded />}
      {props.popupHeandlerData.ErrorMessage && <ErrorMessage />}

      {websiteUnderDevelopment ? '' : <Header />}
      <Switch>
        <Route exact path="/">
          <Redirect to="/home-page" />
        </Route>
        <Route path="/home-page">
          {websiteUnderDevelopment ? <WebsiteUnderDevelopment /> : <Body />}
        </Route>
        {dataSaleProducts.map((item, index) => (
          <Route key={index} path={'/' + item.path}>
            {websiteUnderDevelopment ? <WebsiteUnderDevelopment /> : <Item path={item.path} />}
          </Route>
        ))}
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/sign-up">
          <SignUp />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route path="/change-password/:mail/:encryption" component={ChangePassword}>
        </Route>
        <Route path="/cart">
          {websiteUnderDevelopment ? <WebsiteUnderDevelopment /> : <Cart />}
        </Route>
        <Route path="/order-payment">
          {websiteUnderDevelopment ? <WebsiteUnderDevelopment /> : <OrderPayment />}
        </Route>
        <Route path="/user-area">
          {websiteUnderDevelopment ? <WebsiteUnderDevelopment /> : <UserArea />}
        </Route>
        <Route path="/calculator-product">
          {websiteUnderDevelopment ? <WebsiteUnderDevelopment /> : <CalculatorProductsScreen />}
        </Route>
        <Route path="/product-sku">
          {websiteUnderDevelopment ? <WebsiteUnderDevelopment /> : <ModelsSKUScreen />}
        </Route>
        <Route path="/categories">
          {websiteUnderDevelopment ? <WebsiteUnderDevelopment /> : <DisplayTabs />}
        </Route>
        <Route path="/edit-product/:pathNameBack/:id">
          {websiteUnderDevelopment ? <WebsiteUnderDevelopment /> : <EditProduct />}
        </Route>
      </Switch>
      {websiteUnderDevelopment ? '' : <Footer />}
    </div >
  );
}

const mapStateToProps = state => {
  return {
    users: state.users.loginCookies,
    user: state.users,
    loading: state.loading,
    uploadingFileData: state.uploadingFileData,
    popupHeandlerData: state.popupHeandlerData,
    webmaster: state.webmaster,
  }
}
export default connect(mapStateToProps, {
  changeValueUser,
  LoginFunCookies,
  requestAllDeliveries,
  updateUnLoginUserCartLocalStorage,
})(App);
