const {
    APPROVER_PAYMENT,
} = require('../actions/RequestUserDatabase')

const defaultState = false;

const variable = (state = defaultState, action) => {
    switch (action.type) {
        case APPROVER_PAYMENT:
            return action.payload;
        default:
            return state;
    }
}

export default variable;