import axios from 'axios';
export const REQUEST_ALL_DATA_DELIVERIES = 'REQUEST_ALL_DATA_DELIVERIES';

let url = process.env.REACT_APP_SERVER_NODE_URL;

if (localStorage.getItem('StubMode') === 'yes') url = process.env.REACT_APP_SERVER_NODE_URL_DEVELOP;

export const requestAllDeliveries = () => {
    return async (dispatch) => {
        axios.get(url + '/management-deliveries/all-data-deliveries')
        .then(function (response) {
                const data = response.data;
                dispatch({ type: REQUEST_ALL_DATA_DELIVERIES, payload: data });
            })
            .catch(function (error) {
                console.log(error);
            });

    }
};
