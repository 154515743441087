import React, { useState, useEffect, useRef } from 'react';
import styles from "./Cart.module.scss";
import { connect } from 'react-redux';
import { MDBIcon } from 'mdbreact';
import Cookies from 'universal-cookie';
import { deleteUserCartFun, sedOrderPayment } from '../../../actions/RequestUserDatabase';
import { useHistory, useLocation } from "react-router-dom";
import DisplayMore from '../../components/DisplayMore/DisplayMore';

const CryptoJS = require("crypto-js");

const Cart = props => {

    const history = useHistory();
    const { pathname } = useLocation();

    const cookies = new Cookies();
    const userData = props.userData;
    const [notLogin, setNotLogin] = useState(userData.isLogin);

    const carts = { ...props.carts };
    const [cartsCheckbox, setCartsCheckbox] = useState('');
    const [cartsAllCheckbox, setCartsAllCheckbox] = useState('');
    const [price, setPrice] = useState(0);
    const [countCart, setCountCart] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        let newPrice = 0;
        let newCountCart = 0;
        Object.keys(carts).map(key => {
            if (carts[key].checkd) {
                newPrice += (carts[key].price * 1);
                newCountCart++;
            };
        });
        setCountCart(newCountCart);
        setPrice(newPrice);
    }, [cartsCheckbox, cartsAllCheckbox]);

    window.addEventListener('scroll', () => {
        const div = document.getElementsByClassName(styles.contining_carts_sum)[0];
        if (div) {
            if (window.scrollY > 280) {
                div.classList.add(styles.position_fixed);
            } else {
                div.classList.remove(styles.position_fixed);
            }
        };
    });
    const onClickDeleteCart = (cartId, localStorageId) => {
        let password = '';
        if (notLogin) password = CryptoJS.AES.decrypt(cookies.get('passwordCrypto'), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
        props.deleteUserCartFun({
            localStorageId: localStorageId,
            id: cartId,
            password: password,
            userId: props.userData.userData.id,
        });
    };
    const onChangeCheckboxCart = (key, value) => {
        carts[key].checkd = !value ? '' : true;
        setCartsCheckbox(!cartsCheckbox);
    };
    const onChangeAllCheckboxCart = () => {
        Object.keys(carts).map(key => {
            carts[key].checkd = cartsAllCheckbox ? '' : true;
        });
        setCartsAllCheckbox(cartsAllCheckbox ? '' : true);
    };
    const onClickPayment = () => {
        if (price < 1) {
            alert('לא נבחר מוצר!');
            return;
        };
        const nweData = { ...carts }
        Object.keys(carts).map(key => {
            if (!carts[key].checkd) {
                delete nweData[key];
            }
        });
        props.sedOrderPayment(nweData);
        history.push("/order-payment");
    };

    const divCarts = () => {
        return Object.keys(carts).map((key, index) => (
            <div key={index} className={styles.contining_carts}>
                <input checked={carts[key].checkd} onChange={() => onChangeCheckboxCart(key, !carts[key].checkd)} type="checkbox" />
                <div className={styles.image_and_product_name}>
                    <div className={styles.contind_img_carts}>
                        {carts[key].productImage ? <img src={carts[key].productImage} /> : ''}
                    </div>
                    <div className={styles.product_name}>{carts[key].productName} {carts[key].nameSKU ? ` - מק''ט: ` + carts[key].nameSKU : ''}</div>
                </div>
                <div className={styles.details_box}>
                    <div className={styles.contind_details_carts}>
                        {carts[key].size ? <p ><b className={styles.contind_details_carts_b}>גודל: </b>רוחב: {JSON.parse(carts[key].size).widthSize} - גובה: {JSON.parse(carts[key].size).heightSize}</p> : ''}
                        {JSON.parse(carts[key].productDetails).map((item, index) => (
                            <p key={index} ><b className={styles.contind_details_carts_b}>{item.k}: </b>{item.v}</p>
                        ))}
                    </div>
                    <div className={styles.read_more_box}>
                        <DisplayMore carts={carts} keyB={key} />
                    </div>
                </div>
                <div className={styles.carts_contining_c}>
                    <div className={styles.carts_quantity}>{carts[key].quantity}</div>
                    <div className={styles.carts_price}>{carts[key].price} ש''ח</div>
                    <div onClick={() => onClickDeleteCart(carts[key].id, carts[key].localStorageId)}>
                        <MDBIcon className={styles.carts_trash} far icon="trash-alt" size="2x" />
                    </div>
                </div>
            </div>
        ));
    };
    return (
        <div className={styles.continer}>
            <div className={styles.right}>
                <h2>סל קניות</h2>
                <div className={styles.celect_all_continer}>
                    <input type="checkbox" checked={cartsAllCheckbox} onChange={onChangeAllCheckboxCart} />
                    <div className={styles.celect_all_continer_text}>
                        בחר הכל
                    </div>
                </div>
                <div className={styles.contining_carts_title}>
                    <div style={{ marginRight: '20px' }} className={styles.contining_carts_title_item}>מוצר</div>
                    <div style={{ width: '100px' }} className={styles.contining_carts_title_item}>מפרט</div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: '100px' }} className={styles.contining_carts_title_item}>כמות</div>
                        <div style={{ width: '100px' }} className={styles.contining_carts_title_item}>סכום ביניים</div>
                    </div>
                </div>
                {divCarts()}
            </div>
            <div className={styles.left}>
                <div className={styles.contining_carts_sum}>
                    <div className={styles.contining_carts_info}>
                        <h4>סה"כ בסל קניות:</h4>
                        <br />
                        <h4>נבחרו {countCart} מוצרים</h4>
                        <hr />
                        <div className={styles.contining_carts_price}>
                            <h5>סכום ביניים</h5>
                            <h5>{price} ש''ח</h5>
                        </div>

                    </div>
                    <div onClick={() => onClickPayment()} className={styles.button_for_payment}>מעבר לתשלום</div>
                </div>
            </div>
        </div>
    );
};


const mapStateToProps = state => {
    return {
        carts: state.cart,
        userData: state.users,
    }
}
export default connect(mapStateToProps, { deleteUserCartFun, sedOrderPayment })(Cart);

