import React from 'react';
import styles from "./HowItWorks.module.scss";
import { connect } from 'react-redux';


const HowItWorks = ({}) => {



    return (
        <div className={styles.continer}>
            <h1>איך זה עובד בפיתוח</h1>

        </div >
    );
};


const mapStateToProps = state => {
    return {}
}
export default connect(mapStateToProps, {})(HowItWorks);