import { UPDATE_MANAGER_STATUS } from "../actions/RequestUserDatabase";

const defaultState =  {
    isManager: false,
};

const variable = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_MANAGER_STATUS:
            if (action.payload.userType === 'management') {
                return {...state, isManager: true };
            } else {
                return {...state, isManager: false };
            };
        default:
            return state;

    }
}

export default variable;