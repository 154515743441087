import { CHANGE_STATUS_POPUP } from './popupsHeandler';
import axios from 'axios';

export const REQUEST_ALL_DATA = 'REQUEST_ALL_DATA';
export const REFRESH_ALL_DATA = 'REFRESH_ALL_DATA';

let url = process.env.REACT_APP_SERVER_NODE_URL;

if (localStorage.getItem('StubMode') === 'yes') url = process.env.REACT_APP_SERVER_NODE_URL_DEVELOP;


export const requestAllData = () => {
    return async (dispatch, getState) => {
        axios.get(url + '/customer-site/all-data-calculator')
            .then(function (response) {
                const data = response.data;
                dispatch({ type: REQUEST_ALL_DATA, payload: data });
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: CHANGE_STATUS_POPUP,
                    payload: {
                        type: 'ErrorMessage',
                        yesOrNo: true,
                        typeText: 'ErrorMessageText',
                        text: error.message,
                    }
                });
            });

    }
};
export const refreshData = (dataType, value) => {
    return async (dispatch, getState) => {
        dispatch({ type: REFRESH_ALL_DATA, payload: { dataType, value } });
    }
};