const { CHANGE_CALCULATOR_VALUES } = require('../actions/controlBodyScreens')

const defaultState =  {
    returnToScreen: '',
    valuesCalculatorProductsScreen: {},
    valuesProductSKUScreen: {},
    valuesModelsSKUScreen: {},
};

const variable = (state = defaultState, action) => {
    switch (action.type) {
        case CHANGE_CALCULATOR_VALUES:
            const newStateA = {...state};
            newStateA[action.payload.screen] = action.payload.obj;
            return newStateA;
        default:
            return state;

    }
}

export default variable;