import React, { useRef, useState, useEffect } from 'react';
import styles from "./ModelsSKUScreen.module.scss";
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { calculatorByQuantities } from './components/calculator';
import { MDBIcon } from 'mdbreact';
import { useHistory } from "react-router-dom";
import { addToUserCartFun, sedOrderPayment } from '../../../../actions/RequestUserDatabase';
import { changeScreensValues, changeScreens } from '../../../../actions/controlBodyScreens';
import LocationPath from '../../../components/LocationPath/LocationPath';
import { changeLocationPath } from '../../../../actions/locationPath';
import Questionnaire from '../../../components/Questionnaire/Questionnaire';
import SideCart from '../../../User/SideCart/SideCart';


const ModelsSKUScreen = props => {

    const history = useHistory();
    const location = useLocation();
    const { pathname } = useLocation();

    const [product, setProduct] = useState({});
    const modelImgesRef = useRef();
    const [selectedModel, setSelectedModel] = useState({});
    const [quantity, setQuantity] = useState(false);
    const [price, setPrice] = useState(0);
    const [forms, setForms] = useState([]);
    const [selections, setSelections] = useState([]);
    const [sideCartIsOpen, setSideCartIsOpen] = useState(false);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    useEffect(() => {
        if (!location.id) history.push("/");
        const modelsProduct = props.allData.productSKUData.find(element => element.id == location.id);
        setProduct(modelsProduct);
        props.changeLocationPath({ key: 'productSku', value: { name: modelsProduct.name, id: modelsProduct.id } })
    }, [location]);

    useEffect(() => {
        if (quantity && selectedModel) {
            setPrice(calculatorByQuantities(selectedModel, quantity).toFixed(2));
        } else {
            setPrice(0);
        };
    }, [selectedModel, quantity]);

    useEffect(() => {
        if (Object.keys(product).length === 0) return;
        const formsArr = [];
        const selectionsArr = [];
        setSelectedModel(false);
        setForms([]);
        setSelections([]);
        setSelectedModel(product.modelOfProductSKUs[0]);
        product.productSKUForms?.map(item => {
            JSON.parse(item.inputs)?.map(itemB => {
                formsArr.push({ title: itemB.title, value: '' })
            });
            JSON.parse(item.selectons)?.map(itemB => {
                selectionsArr.push({ title: itemB.title, value: '' })
            });
            setForms(formsArr);
            setSelections(selectionsArr);
        });
    }, [product]);

    const convertSpecificationsText = () => {
        const arrData = [];
        arrData.push({ k: `מפרט המק''ט`, v: product.description });
        arrData.push({ k: `מפרט דגם`, v: selectedModel.description });
        arrData.push({ k: `טופס לקוח`, v: '_____' });
        forms?.map(item => {
            arrData.push({ k: item.title, v: item.value });
        });
        selections?.map(item => {
            arrData.push({ k: item.title, v: item.value });
        });
        return JSON.stringify(arrData);
    }
    const addToCartFun = () => {
        if (sideCartIsOpen) return alert('זה כבר נוסף לסל!');
        if (price === '0.00') return alert('נא לבחור כמות!');
        if (!quantity) return alert('בחר כמות בבקשה :)')
        if (!price) return alert('אין מחיר!')
        if (price > 0) {
            props.addToUserCartFun({
                productName: selectedModel.titleForCustomer,
                productImage: selectedModel.image,
                nameSKU: selectedModel.nameSKU,
                productType: 'sku',
                productId: selectedModel.id,
                quantity: quantity,
                productDetails: convertSpecificationsText(),
                price: price,
                extraPriceForShipping: 0,
                size: '',
            });
        };
        // history.push("/cart");
        setSideCartIsOpen(true);
    };
    const onClickPayment = () => {
        if (!quantity) return alert('בחר כמות בבקשה :)');
        if (price === '0.00') return alert('נא לבחור כמות!');
        if (!price) return alert('אין מחיר!')
        props.sedOrderPayment({
            0: {
                productName: selectedModel.titleForCustomer,
                productImage: selectedModel.image,
                nameSKU: selectedModel.nameSKU,
                productType: 'sku',
                productId: selectedModel.id,
                quantity: quantity,
                productDetails: convertSpecificationsText(),
                price: price,
                extraPriceForShipping: 0,
                checkd: true,
                size: '',
            }
        });
        history.push("/order-payment");
    };
    const hendlerModelsClick = dataModel => {
        setSelectedModel(dataModel);
    };
    const hendlerModels = () => {
        return product.modelOfProductSKUs?.map((item, i) => (
            <div onClick={() => hendlerModelsClick(item)} className={styles.div_model_holder} key={i}>
                <img src={item.image} alt="" />
                {/* <div className={styles.text_of_img}>{item.titleForCustomer} #{item.nameSKU}#</div> */}
            </div>
        ));
    };
    const quantityChange = (e) => {
        let num = +e.currentTarget.value;
        setQuantity(num.toFixed(0));
    };
    const inputChange = (e, index) => {
        const newForms = [...forms];
        newForms[index].value = e.currentTarget.value;
        setForms(newForms);
    };
    const selectionChange = (e, index) => {
        const newSelections = [...selections];
        newSelections[index].value = e.currentTarget.value;
        setSelections(newSelections);
    };

    const onClickEditPdf = () => {
        history.push(`/edit-product/product-sku/${location.id}`);
    };


    const hendlerForms = () => {
        return (<>
            {product.productSKUForms?.map((item, i) => (
                <div key={i}>
                    {JSON.parse(item.selectons)?.map((item, index) => (
                        <div key={index} className={styles.contining_properties_elements}>
                            <label className={styles.label_calculator_parameter}>{item.title}:</label>
                            <select value={selections.length ? selections[index].value ? selections[index].value : 'בחר...' : ''} onChange={e => selectionChange(e, index)}>
                                <option disabled>בחר...</option>
                                {item.selectons?.map((item, key) => (
                                    <option key={key}>{item}</option>
                                ))}
                            </select>
                        </div>
                    ))}
                    {JSON.parse(item.inputs)?.map((item, index) => (
                        <div key={index} className={styles.contining_properties_elements}>
                            <label className={styles.label_calculator_parameter}>{item.title}:</label>
                            <textarea type="text" onChange={e => inputChange(e, index)} placeholder="הקלד..."
                                value={forms.length > 0 ? forms[index].value : ''} />
                        </div>
                    ))}
                </div>
            ))}
        </>)
    };

    return (
        <>
            <SideCart isOpenHeandler={sideCartIsOpen} />
            <div className={styles.continer}>
                <div className={styles.contining_right}>
                    <LocationPath />
                    {product.image && <div className={styles.contind_img_productModelsSKU}>
                        {selectedModel ?
                            <img src={selectedModel.image} /> :
                            <img src={product.image} />}
                    </div>}
                    {/* button to go edit pdf  */}

                    <button onClick={() => onClickEditPdf()}>עריכת קובץ</button>


                </div>
                <div className={styles.contining_middle}>
                    <div className={styles.models_continer} id={styles.contining_items_style_15} ref={modelImgesRef}>
                        {hendlerModels()}
                    </div>
                </div>
                <div className={styles.contining_left}>
                    <div className={styles.top_title}>{product.name}</div>
                    <div className={styles.normal_text}><b>מס' מק''ט': </b>{selectedModel?.nameSKU}</div>
                    <div className={styles.normal_text}><b>דגם: </b>{selectedModel?.titleForCustomer}</div>
                    <br />
                    <div className={styles.normal_text}>{product.description}</div>
                    <div className={styles.normal_text}>{selectedModel?.description}</div>
                    <br />
                    {hendlerForms()}
                    <div className={styles.contining_properties_elements}>
                        <label className={styles.label_calculator_parameter}>כמות:</label>
                        <input type="number" onChange={quantityChange} placeholder="הקלד כמות..." value={quantity} />
                    </div>
                    <br />
                    <div className={styles.normal_text}>מחיר: <b className={styles.price}>{price ? price : '0:00'}</b> ש''ח</div>
                    <div className={styles.contining_buttons}>
                        <div onClick={() => addToCartFun()} className={styles.button}>הוסף לסל</div>
                        <div onClick={() => onClickPayment()} className={styles.button_payment}>לתשלום</div>
                    </div>
                </div>
            </div>
            <Questionnaire quesData={[]} />
        </>
    );
};


const mapStateToProps = state => {
    return {
        allData: state.allCalculatorDatabase,
        propertieData: state.allCalculatorDatabase.propertieData,
        isManager: state.webmaster.isManager,
        userData: state.users,
    }
}
export default connect(mapStateToProps, {
    changeScreensValues,
    changeScreens,
    addToUserCartFun,
    sedOrderPayment,
    changeLocationPath,
})(ModelsSKUScreen);