import React, { useState, useEffect, useRef } from 'react';
import styles from "./Item.module.scss";
import { connect } from 'react-redux';
import { dataSaleProducts } from '../dataSaleProducts';
import background from '../imges/001-01.jpg';
import { MDBIcon } from 'mdbreact';
import { useHistory } from "react-router-dom";
import { addToUserCartFun, sedOrderPayment, fileTest } from '../../../../../../actions/RequestUserDatabase';

const Item = props => {

    const history = useHistory();

    const [quantity, setQuantity] = useState('');
    const [data, setData] = useState(props.salePageItemData.valuesSaleAsd);
    const [checkboxStarus, setCheckboxStarus] = useState(false);
    const [checkboxValue, setCheckboxValue] = useState(false);
    const [selectedFile, setSelectedFile] = useState('');
    const [errFile, setErrFile] = useState('');
    const boxTermsTextRef = useRef();
    if (!data) {
        const path = props.path;
        dataSaleProducts.map(item => {
            if (item.path === path) {
                item.background = background;
                setData(item);
            }
        });
    };
    useEffect(() => {
        if (boxTermsTextRef.current.offsetHeight === boxTermsTextRef.current.scrollHeight) {
            setCheckboxStarus(true);
        } else {
            boxTermsTextRef.current.addEventListener('scroll', function (e) {
                if (boxTermsTextRef.current.offsetHeight + boxTermsTextRef.current.scrollTop >= boxTermsTextRef.current.scrollHeight) {
                    setCheckboxStarus(true);
                };
            });
        };
    }, []);

    const quantityChange = e => {
        let quantityFix = e.currentTarget.value;
        if (!quantityFix) return setQuantity('');
        quantityFix = quantityFix * 1;
        quantityFix = quantityFix.toFixed(0);
        if (quantityFix < 1) quantityFix = '';
        setQuantity(quantityFix);
    };
    const priceHendler = () => {
        if (!quantity) return '0.00';
        let totalPrce = quantity * data.price;
        if (totalPrce < data.minPrice) totalPrce = data.minPrice;
        return totalPrce.toFixed(2);
    };
    const extraPriceForShippingHendler = () => {
        if (quantity > data.extraShipping.above) {
            const totalPrce = (quantity - data.extraShipping.above) * data.extraShipping.unitPrice;
            return totalPrce.toFixed(2);
        } else {
            return 0;
        }
    };

    const onChangeChecked = () => {
        if (!checkboxStarus) {
            alert('נא לגלול את התקנון עד הסוף!');
            return;
        }
        setCheckboxValue(!checkboxValue);
    };
    const addToCartFun = () => {
        if (errFile) {
            alert('הקובץ גדול מידי!');
            return;
        };
        if (priceHendler() < 1) {
            alert('לא נבחר כמות!');
            return;
        };
        if (!selectedFile) {
            alert('נא לעלות קובץ!');
            return;
        };
        if (!checkboxValue) {
            alert('נא לאשר תקנון!');
            return;
        };
        props.addToUserCartFun({
            productName: data.title,
            productImage: data.background,
            productType: 'sale',
            productId: data.id,
            quantity: quantity,
            productDetails: JSON.stringify([
                { k: 'מפרט', v: data.detail, p: priceHendler() },
            ]),
            price: priceHendler(),
            extraPriceForShipping: extraPriceForShippingHendler(),
            checkd: true,
            size: '',
            thereAreFiles: 'yas',
            file: selectedFile,
            fileID: Math.random().toString(36).slice(8) + '-' + new Date().getTime(),
        });
        history.push("/cart");
    };

    const onClickPayment = () => {
        if (errFile) {
            alert('הקובץ גדול מידי!');
            return;
        };
        if (priceHendler() < 1) {
            alert('לא נבחר כמות!');
            return;
        };
        if (!checkboxValue) {
            alert('נא לאשר תקנון!');
            return;
        };
        if (!selectedFile) {
            alert('נא לעלות קובץ!');
            return;
        };
        props.sedOrderPayment({
            0: {
                productName: data.title,
                productImage: data.background,
                productType: 'sale',
                productId: data.id,
                quantity: quantity,
                productDetails: JSON.stringify([{ k: 'מפרט', v: data.deliveryTimeText, p: priceHendler() }]),
                price: priceHendler(),
                extraPriceForShipping: extraPriceForShippingHendler(),
                checkd: true,
                size: '',
                file: selectedFile,
                fileID: Math.random().toString(36).slice(8) + '-' + new Date().getTime(),
            }
        });
        history.push("/order-payment");
    };

    const onChangeSelectedFile = file => {
        const maxAllowedSize = 2000000000;
        if (file.size > maxAllowedSize) {
            const fileSize = file.size / 1000000000;
            return setErrFile(`גודל הקובץ ${fileSize.toFixed(2)} ג'יגה נא לעלות קובץ מינימום 2 ג'יגה! `);
        }
        setErrFile('');
        setSelectedFile(file);
        // props.fileTest(file);
    };

    return (
        <div className={styles.continer}>
            <div className={styles.title} style={{ backgroundImage: `url(${data.background})` }}>
                <div onClick={() => history.push("/")} className={styles.backButton}>
                    <MDBIcon icon="arrow-left" size='3x' />
                </div>
                <h3>{data.title}</h3>
            </div>
            <div className={styles.middle} >
                <div className={styles.detail} >
                    <h3>מפרט</h3>
                    <p>
                        {data.detail}
                        <br />
                        *מינימום מחיר {data.minPrice} ₪
                        <br />
                        {data.deliveryTimeText}
                        <br />
                        {data.timeForSelfCollectionText}
                    </p>
                    <hr></hr>
                    <div className={styles.quantity} >
                        <div className={styles.quantityInput} >
                            <label>כמות</label>
                            <input type='number' onChange={quantityChange} value={quantity} placeholder="הקלד כמות" />
                        </div>
                        <div className={styles.price}>מחיר: {priceHendler()} ₪</div>
                    </div>
                </div>
                <div className={styles.uploadLile} >
                    <h3>העלת קובץ</h3>
                    <h5>תקנון קבצים</h5>
                    <p>לעלות קובץ 1 בגודל 50/70,<br /> במידה ויש כמה סוגים לעלות קובץ רץ במספר עמודים.</p>
                    {errFile && <div className={styles.err}>{errFile}</div>}
                    <input onChange={(e) => onChangeSelectedFile(e.target.files[0])} type="file" accept="application/pdf" />
                </div>
            </div>
            <hr></hr>
            <div className={styles.bottom} >
                <div className={styles.boxTerms} >
                    <div className={styles.checkedContiner} >
                        <label className={styles.main}>
                            <input checked={checkboxValue} onChange={() => onChangeChecked()} type="checkbox" />
                            <span className={styles.geekmark}></span>
                        </label>
                    </div>
                    <div className={styles.boxTermsAll}>
                        <h3>אישור תקנון</h3>
                        <div ref={boxTermsTextRef} className={styles.boxTermsText}>
                            {data.termsProduct}
                            {data.termsProduct}
                            {data.termsProduct}
                            {data.termsProduct}
                            {data.termsProduct}
                            {data.termsProduct}
                            {data.termsProduct}
                            {data.termsProduct}
                            {data.termsProduct}
                            {data.termsProduct}
                            {data.termsProduct}
                            {data.termsProduct}
                            {data.termsProduct}
                            {data.termsProduct}
                            {data.termsProduct}
                            {data.termsProduct}
                            {data.termsProduct}
                            {data.termsProduct}
                            {data.termsProduct}
                            {data.termsProduct}
                            {data.termsProduct}
                            {data.termsProduct}
                            {data.termsProduct}
                            {data.termsProduct}
                            {data.termsProduct}
                        </div>
                    </div>
                    <div className={styles.contining_buttons}>
                        <div onClick={() => addToCartFun()} className={styles.button}>הוסף לסל <MDBIcon icon="shopping-cart" /></div>
                        <div onClick={() => onClickPayment()} className={styles.button}>לתשלום <MDBIcon far icon="credit-card" /></div>
                    </div>
                </div>
            </div>
        </div>
    );
};


const mapStateToProps = state => {
    return {
        salePageItemData: state.salePageItemData,
    }
}
export default connect(mapStateToProps, { addToUserCartFun, sedOrderPayment, fileTest })(Item);