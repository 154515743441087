

export const calculatorByQuantities = (selectedModel, quantity) => {
    quantity = quantity * 1;
    let priceForPricing = 0;
    let quantityForPricing = 0;
    const priceByQuantities = JSON.parse(selectedModel.productPriceByQuantities);
    priceByQuantities.map(item => {
        if (quantity >= item.quantity * 1 && quantityForPricing < item.quantity * 1) {
            priceForPricing = item.price * 1;
            quantityForPricing = item.quantity * 1;
        };
    });
    if (priceForPricing === 0) priceByQuantities.map(item => {
        if (quantityForPricing > item.quantity * 1 || quantityForPricing === 0) {
            priceForPricing = item.price * 1;
            quantityForPricing = item.quantity * 1;
        };
    });;
    priceForPricing = priceForPricing * quantity;
    if (priceForPricing < selectedModel.minimumPrice) priceForPricing = selectedModel.minimumPrice;
    return priceForPricing;
};

