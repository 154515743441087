import React, { useEffect, useState } from 'react';
import styles from "./Search.module.scss";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { changeLocationPath } from '../../../actions/locationPath';
import noImage from '../../../assets/images/noImage.webp';


const Search = props => {

    const locationPathData = props.locationPathData;
    const isManager = props.webmaster.isManager;

    const history = useHistory();

    const [allDataArr, setAllDataArr] = useState([]);
    const [allDataArrFilter, setAllDataArrFilter] = useState([]);

    useEffect(() => {
        const newArr = filterSearch(allDataArr);
        setAllDataArrFilter(newArr);
    }, [props.search]);

    useEffect(() => {
        let arr = [];
        const tabTopData = props.allData.tabTopData;
        const categoriesData = props.allData.categoriesData;
        const productsData = props.allData.productsData;
        const regularProductData = props.allData.regularProductData;
        const productSKUData = props.allData.productSKUData;
        Object.keys(tabTopData).map(key => {
            tabTopData[key]['type'] = 'tab';
            arr.push(tabTopData[key]);
        })
        Object.keys(categoriesData).map(key => {
            categoriesData[key]['type'] = 'category';
            arr.push(categoriesData[key]);
        })
        Object.keys(productsData).map(key => {
            arr.push(productsData[key]);
        })
        Object.keys(regularProductData).map(key => {
            arr.push(regularProductData[key]);
        })
        Object.keys(productSKUData).map(key => {
            console.log(productSKUData[key]);
            arr.push(productSKUData[key]);
        })
        const newArr = filterSearch(arr);
        setAllDataArrFilter(newArr);
        setAllDataArr(arr);
    }, [
        props.allData,
    ]);
    const filterSearch = arr => {
        const newArr = [];
        arr.forEach(item => {
            if (item?.titleForCustomer.includes(props.search)) {
                newArr.push(item);
            };
        });
        return newArr;
    };

    const onClickItem = ({ id, data }) => {
        if (data.type === 'tab') {
            history.push({
                pathname: '/categories',
                id: id,
                type: 'tab',
            });
        }
        if (data.type === 'category') {
            console.log(data);
            history.push({
                pathname: '/categories',
                id: id,
                type: 'category',
                tabId: data.tabTops.length ? data.tabTops[0].id : 0,
            });
        }
        if (data.type === 'calculator') {
            if (data.categoriesProductTypes.length) {
                props.changeLocationPath({ key: 'category', value: { name: data.categoriesProductTypes[0].categoryName, id: data.categoriesProductTypes[0].id } });
            };
            history.push({
                pathname: '/calculator-product',
                id: id,
            });
        };
        if (data.type === 'regular') {
            history.push({
                tabId: locationPathData['tab'].id,
                categoryId: data.categoriesProductTypes.length ? data.categoriesProductTypes[0].id : 0,
                pathname: '/categories',
                id: id,
                type: 'category-sku',
            });
        };
        if (data.type === 'calculatorByQuantities') {
            if (data.regularProducts.length) {
                props.changeLocationPath({ key: 'categorySku', value: { name: data.regularProducts[0].productName, id: data.regularProducts[0].id } });
            };
            history.push({
                pathname: '/product-sku',
                id: id,
            });
        };
        props.setSearch('');
    };

    return (
        <div className={styles.continer}>
            {allDataArrFilter.filter(item => {
                if (isManager || item.activeStatus === 'כן') {
                    return true;
                } else { return false };
            }).length ? allDataArrFilter
            .filter(item => {
                if (isManager || item.activeStatus === 'כן') {
                    return true;
                } else { return false };
            })
            .map((item, index) => (
                <div key={index} className={styles.item_continer} onClick={() => onClickItem({ id: item.id, data: item })}>
                    <div className={styles.box}>
                        <div className={styles.img_box}>
                            {item.Image && <img src={item.Image} />}
                            {item.imgUrl && <img src={item.imgUrl} />}
                            {item.image && <img src={item.image} />}
                            {!item.image && !item.imgUrl && !item.Image ? <img src={noImage} /> : ''}
                        </div>
                        {/* {item.tabName && <div className={styles.hover_button}>
                            <div className={styles.hover_button_text}>
                                {item.tabName}</div>
                        </div>} */}
                        {item.titleForCustomer && <div className={styles.hover_button}>
                            <div className={styles.hover_button_text}>
                                {item.titleForCustomer}</div>
                        </div>}
                    </div>
                    <hr />
                </div>
            )) :
                <div className={styles.not_found}>
                    לא נמצא החיפוש הנדרש
                </div>
            }
        </div>
    );
};


const mapStateToProps = state => {
    return {
        allData: state.allCalculatorDatabase,
        locationPathData: state.locationPathData,
        webmaster: state.webmaster,
    }
}
export default connect(mapStateToProps, { changeLocationPath })(Search);