const { CHANGE_SALE_DATA } = require('../../actions/sale/controlSaleData')

const defaultState =  {
    valuesSaleAsd: false,
};

const variable = (state = defaultState, action) => {
    switch (action.type) {
        case CHANGE_SALE_DATA:
            const newStateA = {...state};
            newStateA[action.payload.typeSale] = action.payload.obj;
            return newStateA;
        default:
            return state;

    }
}

export default variable;