import React from 'react';
import styles from "./EditProduct.module.scss";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { MDBIcon } from 'mdbreact';
import { useParams } from 'react-router-dom';

const EditProduct = props => {

    const history = useHistory();
    const { pathNameBack, id } = useParams()
    console.log(pathNameBack, id);
    const goBack = () => {
        history.push({
            pathname: `/${pathNameBack}`,
            id: id,
        });
    };

    return (
        <div className={styles.continer}>
            {/* button go back */}
            <div className={styles.go_back_container}>
                <div className={styles.go_back_btn} onClick={() => goBack()}>
                    <MDBIcon icon="arrow-left" className={styles.icon} />
                    חזור
                </div>
            </div>
        </div>
    );
};


const mapStateToProps = state => {
    return {}
}
export default connect(mapStateToProps, {})(EditProduct);
