

const defaultState = 1.17;

const variable = (state = defaultState, action) => {
    switch (action.type) {
        case 'xxx':
            return action.payload;
        default:
            return state;
    }
}

export default variable;