import React from 'react';
import styles from "./LocationPath.module.scss";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';


const LocationPath = props => {

    const history = useHistory();
    const locationPathData = props.locationPathData;

    const onClickLocationPathTab = id => {
        history.push({
            pathname: '/categories',
            id: id,
            type: 'tab',
        });
    };
    const onClickLocationPathCategory = id => {
        history.push({
            pathname: '/categories',
            id: id,
            type: 'category',
            tabId: locationPathData['tab'].id,
        });
    };
    const onClickLocationPathProduct = id => {
        history.push({
            pathname: '/calculator-product',
            id: id,
        });
    };
    const onClickLocationPathCategorySku = id => {
        history.push({
            tabId: locationPathData['tab'].id,
            categoryId: locationPathData['category'].id,
            pathname: '/categories',
            id: id,
            type: 'category-sku',
        });
    };
    const onClickLocationPathProductSku = id => {
        history.push({
            pathname: '/product-sku',
            id: id,
        });
    };
    return (
        <div className={styles.continer}>
            <span onClick={() => onClickLocationPathTab(locationPathData['tab'].id)}>{locationPathData['tab'].id && locationPathData['tab'].name}</span>
            <span onClick={() => onClickLocationPathCategory(locationPathData['category'].id)}>{locationPathData['category'].id && ' / ' + locationPathData['category'].name}</span>
            <span onClick={() => onClickLocationPathCategorySku(locationPathData['categorySku'].id)}>{locationPathData['categorySku'].id && ' / ' + locationPathData['categorySku'].name}</span>
            <span onClick={() => onClickLocationPathProductSku(locationPathData['productSku'].id)}>{locationPathData['productSku'].id && ' / ' + locationPathData['productSku'].name}</span>
            <span onClick={() => onClickLocationPathProduct(locationPathData['product'].id)}>{locationPathData['product'].id && ' / ' + locationPathData['product'].name}</span>
        </div>
    );
};


const mapStateToProps = state => {
    return {
        locationPathData: state.locationPathData,
    }
}
export default connect(mapStateToProps, {})(LocationPath);

