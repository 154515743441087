import React, { useState, useEffect } from 'react';
import styles from "./OrderPayment.module.scss";
import { connect } from 'react-redux';
import { MDBIcon } from 'mdbreact';
import Cookies from 'universal-cookie';
import { loadingSpinner } from '../../../actions/Loading';
import { useHistory } from "react-router-dom";
import { addUserOdretFun, cleaningOdretRes, approvedPayment } from '../../../actions/RequestUserDatabase';
import OrderDetailsPayment from '../OrderDetailsPayment/OrderDetailsPayment';


import Paypal from '../../Payment/Paypal/Paypal'
import PersonalDetailsPayment from '../PersonalDetailsPayment/PersonalDetailsPayment';
const CryptoJS = require("crypto-js");
const OrderPayment = props => {
    const history = useHistory();
    const userData = props.userData;

    const cookies = new Cookies();

    const orders = props.orders;
    const orderPaymentRes = props.orderPaymentRes;
    const uData = props.userData.userData;
    const VAT = props.VAT;
    const [price, setPrice] = useState(0);
    const [extraPriceForShipping, setExtraPriceForShipping] = useState(0);
    const [priceDelivery, setPriceDelivery] = useState(0);
    const [succeeded, setSucceeded] = useState(false);
    const [thereIsShipping, setThereIsShipping] = useState(false);
    const [fullAddress, setFullAddress] = useState({ city: 'בחר עיר', street: '', houseNumber: '' });
    const [mailErr, setMailErr] = useState('');
    const [accountDetails, setAccountDetails] = useState({});
    const [aprovalRegulations, setApprovalRegulations] = useState(false);

    const [chackout, setChackout] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [chackout]);

    useEffect(() => {
        props.approvedPayment(false);
        setSucceeded(false);
    }, [orders]);
    useEffect(() => {
        if (userData.isLogin) {
            setAccountDetails({
                name: uData.name || '',
                lastName: uData.lastName || '',
                phone: uData.phone || '',
                mail: uData.mail || '',
                address: uData.address || '',
                companyName: uData.companyName || '',
                businessNumber: uData.businessNumber || '',
                postalCode: uData.postalCode || '',
                moreInfo: uData.moreInfo || '',
            });
        }
    }, [uData]);
    useEffect(() => {
        if (orderPaymentRes.type === 'succeeded') {
            setSucceeded(true);
            props.loadingSpinner(false);
        };
    }, [orderPaymentRes]);

    useEffect(() => {
        let newPrice = 0;
        let newExtraPriceForShipping = 0;
        Object.keys(orders).map(key => {
            if (orders[key].checkd) {
                newPrice += (orders[key].price * 1)
                newExtraPriceForShipping += (+orders[key].extraPriceForShipping);
                if (orders[key].extraShipping) {
                    if (orders[key].extraShipping.extraShippingPrice) newExtraPriceForShipping += (+orders[key].extraShipping.extraShippingPrice);
                };
            };
        });
        setPrice(newPrice * 1);
        setExtraPriceForShipping(newExtraPriceForShipping);
    }, []);
    const chackoutFun = paidData => {
        if (!aprovalRegulations) return alert('נא לאשר את התקנון.');
        if (!accountDetails.name) return alert('נא הקלד שם!');
        if (!accountDetails.lastName) return alert('נא הקלד משפחה!');
        if (!accountDetails.mail) return alert('נא הקלד מייל!');
        if (!accountDetails.phone) return alert('נא הקלד טלפון!');
        if (mailErr) return alert('נא הקלד מייל תקין!');
        if (price < 1) {
            alert('לא נבחר מוצר!');
            return;
        };
        if (thereIsShipping) {
            if (fullAddress.city === 'בחר עיר') return alert('נא בחר עיר!');
            if (!fullAddress.street) return alert('נא הקלד רחוב!');
            if (!fullAddress.houseNumber) return alert('נא הקלד מספר בית!');
        }
        setChackout(true);
    };
    useEffect(() => {
        if (props.orderPaymentResPaypal) {
            if (price < 1) {
                alert('לא נבחר מוצר!');
                return;
            };
            setChackout(false);
            let password = '';
            if (userData.isLogin) password = CryptoJS.AES.decrypt(cookies.get('passwordCrypto'), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
            const ordersSelected = { ...orders };
            Object.keys(orders).map(key => {
                if (!orders[key].checkd) delete ordersSelected[key];
            });
            props.addUserOdretFun({
                ordersObj: ordersSelected,
                customerComments: '',
                shippingAdress: JSON.stringify(fullAddress),
                thereIsDelivery: thereIsShipping,
                deliveryAllItemPrice: priceDelivery,
                allItemPrice: price * VAT,
                paymentDetails: JSON.stringify(props.orderPaymentResPaypal),
                costumerDetails: JSON.stringify(accountDetails),
                agent: '',
                coupon: '',
            }, { userId: userData.userData.id, password: password });
            props.approvedPayment(false);
        };
    }, [props.orderPaymentResPaypal]);


    return (
        <div className={styles.continer}>
            {succeeded && <div className={styles.contining_succeeded}>
                {/* {true && <div className={styles.contining_succeeded}> */}
                <div className={styles.div_succeeded}>
                    <h2>ההזמנה התקבלה בהצלחה!</h2>
                    <h3>תודה שבחרתם דפוס רימון :)</h3>
                    <h4>מספר ההזמנה {orderPaymentRes.orderNum}</h4>
                    <div className={styles.button_for_succeeded} onClick={() => {
                        props.cleaningOdretRes()
                        history.push("/")
                    }}>חזור למסך הבית</div >
                    <div className={styles.button_for_succeeded} onClick={() => {
                        props.cleaningOdretRes()
                        history.push("/user-area")
                    }}>הזמנות שלי</div >
                </div>
            </div>}

            <div className={styles.contining_right}>
                <div style={chackout ? { cursor: 'pointer' } : {}} chackout className={styles.contining_title}>
                    <h2 onClick={() => setChackout(false)}>קופה</h2>
                    <p>/פרטים אישיים</p>
                </div>
                {chackout ? <Paypal
                    total={(thereIsShipping ?
                        (price + priceDelivery + extraPriceForShipping) * VAT :
                        price * VAT
                    ).toFixed(2)}
                    name={accountDetails.name}
                    lastName={accountDetails.lastName}
                    mail={accountDetails.mail}
                    phone={accountDetails.phone}
                    fullAddress={fullAddress}
                /> :
                    <PersonalDetailsPayment
                        setAccountDetails={setAccountDetails}
                        accountDetails={accountDetails}
                        mailErr={mailErr}
                        setMailErr={setMailErr}
                    />
                }

                {!chackout && <div onClick={() => chackoutFun()} className={styles.button_for_payment}>בצעו הזמנה</div>}
            </div>
            <div className={styles.contining_left}>
                <OrderDetailsPayment
                    orders={orders}
                    thereIsShipping={thereIsShipping}
                    setFullAddress={setFullAddress}
                    setPriceDelivery={setPriceDelivery}
                    priceDelivery={priceDelivery}
                    price={price}
                    setThereIsShipping={setThereIsShipping}
                    extraPriceForShipping={extraPriceForShipping}
                />

                <div className={styles.aproval_regulations}>
                    <input type='checkbox' onChange={() => setApprovalRegulations(!aprovalRegulations)} />
                    <p>קראתי ואני מסכים ל<span>תנאי האתר</span></p>
                </div>
            </div>
        </div>
    );
};


const mapStateToProps = state => {
    return {
        orders: state.orderPayment,
        userData: state.users,
        orderPaymentRes: state.orderPaymentRes,
        orderPaymentResPaypal: state.orderPaymentResPaypal,
        VAT: state.VAT,
    }
}
export default connect(mapStateToProps, {
    addUserOdretFun,
    cleaningOdretRes,
    approvedPayment,
    loadingSpinner,
})(OrderPayment);

