import React from 'react';
import styles from "./UploadingFile.module.scss";
import { connect } from 'react-redux';

const UploadingFile = props => {

    const uploadingFileArr = props.uploadingFileData.uploadingFileArr;
    // const uploadingFileData = props.uploadingFileData;
    const progressHndler = () => {
        return uploadingFileArr.map((item, index) => (
            <div key={index} className={styles.box_progress}>
                <div className={styles.text_progress}>
                    {item.title} {item.numOrder}
                </div>
                <div className={styles.contining_progress_item + ' ' + 'progress'}>
                    <div className="progress-bar" role="progressbar" style={{ width: `${item.percentage}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{item.percentage}%</div>
                </div>
            </div>
        ));
    };

    return (
        <div className={styles.continer}>
            <div className={styles.contining_progress}>
                <h3>לא סגור את הדפדפן עד סיום טעינת הקבצים</h3>
                <div className={styles.loader}>Loading...</div>
                {progressHndler()}
            </div>
        </div>
    );
};


const mapStateToProps = state => {
    return {
        uploadingFileData: state.uploadingFileData,
    }
}
export default connect(mapStateToProps, {})(UploadingFile);

