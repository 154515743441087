const {
    LOAD_ORDERS,
    UPTATE_USER_ORDER,
} = require('../actions/RequestUserDatabase')

const defaultState = {};

const variable = (state = defaultState, action) => {
    switch (action.type) {
        case LOAD_ORDERS:
            return action.payload;
        case UPTATE_USER_ORDER:
            const newStateB = { ...state };
            newStateB[Object.keys(newStateB).length] = action.payload;
            return newStateB;
        default:
            return state;

    }
}

export default variable;