const {
    SING_UP,
    LOGIN,
    LOGIN_COOKIES,
    CLENING,
    CHANGE_VALUE_USER,
    RESET_PASSWORD,
    CHANGE_PASSWORD,
} = require('../actions/RequestUserDatabase')

const defaultState = {
    isLogin: false,
    userData: {userType: null},
    singUp: {},
    login: {},
    loginCookies: {},
    resetPassword: {},
    changePassword: {},
};

const variable = (state = defaultState, action) => {
    switch (action.type) {
        case SING_UP:
            const newStateA = { ...state };
            newStateA['singUp'] = action.payload;
            return newStateA;
        case LOGIN_COOKIES:
            const newStateF = { ...state };
            newStateF['loginCookies'] = action.payload;
            newStateF['userData'] = action.payload.data;
            return newStateF;
        case LOGIN:
            const newStateB = { ...state };
            newStateB['login'] = action.payload;
            newStateB['userData'] = action.payload.data;
            return newStateB;
        case RESET_PASSWORD:
            const newStateD = { ...state };
            newStateD['resetPassword'] = action.payload;
            return newStateD;
        case CHANGE_PASSWORD:
            const newStateE = { ...state };
            newStateE['changePassword'] = action.payload;
            return newStateE;
        case CHANGE_VALUE_USER:
            const newStateC = { ...state };
            newStateC[action.payload.key] = action.payload.value;
            return newStateC;
        case CLENING:
            const newStateZ = { ...state };
            newStateZ[action.payload] = {};
            return newStateZ;
        default:
            return state;

    }
}

export default variable;