const {
    LOAD_SHOPPING_CART,
    UPTATE_USER_CART,
    DELETE_USER_CART,
    DELETE_FROM_CART,
    UPTATE_USER_CART_LOCAL_STORIGE,
    UPDATE_CART_FROM_LOCAL_STORAGE,
    DELETE_USER_CART_LOCAL_STORIGE,
    DELETE_FROM_CARTLOCAL_STORAGE,
    CLEAR_USER_CART,
} = require('../actions/RequestUserDatabase')

const defaultState = {

};

const variable = (state = defaultState, action) => {
    switch (action.type) {
        case LOAD_SHOPPING_CART:
            const newStateW = action.payload.map(item => {
                try {
                    item.extraShipping = JSON.parse(item.extraShipping);
                    return item;
                } catch (error) {
                    return item;
                };
            });
            return newStateW;

        case CLEAR_USER_CART:
            return defaultState;

        case UPDATE_CART_FROM_LOCAL_STORAGE:
            if (!action.payload) return state;
            const newStateY = Object.keys(action.payload)
                ?.map(key => action.payload[key])
                .map(item => {
                    try {
                        item.extraShipping = JSON.parse(item.extraShipping);
                        return item;
                    } catch (error) {
                        return item;
                    };
                });
            return newStateY;

        case UPTATE_USER_CART:
            const newStateB = { ...state };
            const keyItemCard = Object.keys(newStateB).length;
            newStateB[keyItemCard] = action.payload;
            try {
                newStateB[keyItemCard].extraShipping = JSON.parse(newStateB[keyItemCard].extraShipping);
            } catch (error) {};
            return newStateB;

        case UPTATE_USER_CART_LOCAL_STORIGE:
            const newStateE = { ...state };
            action.payload.localStorageId = 'localStorageId-' + Object.keys(newStateE).length;
            const keyItem = Object.keys(newStateE).length + 1;
            newStateE[keyItem] = action.payload;
            localStorage.setItem('userCart', JSON.stringify(newStateE));
            try {
                newStateE[keyItem].extraShipping = JSON.parse(newStateE[keyItem].extraShipping);
            } catch (error) {};
            return newStateE;

        case DELETE_USER_CART_LOCAL_STORIGE:
            const newStateX = { ...state };
            Object.keys(state).map(key => {
                if (state[key].localStorageId === action.payload) {
                    delete newStateX[key]
                }
            });
            localStorage.setItem('userCart', JSON.stringify(newStateX));
            return newStateX;

        case DELETE_USER_CART:
            const newStateC = { ...state };
            Object.keys(state).map(key => {
                if (state[key].id === action.payload) {
                    delete newStateC[key]
                }
            });
            return newStateC;

        case DELETE_FROM_CART:
            const newStateD = { ...state };
            const idsToDelete = action.payload;
            const arrToDelete = [];
            Object.keys(idsToDelete).map(key => {
                arrToDelete.push(idsToDelete[key].id);
            });
            Object.keys(newStateD).map(key => {
                const id = newStateD[key].id;
                if (arrToDelete.includes(id)) {
                    delete newStateD[key]
                }
            });
            return newStateD;

        case DELETE_FROM_CARTLOCAL_STORAGE:
            const newStateT = { ...state };
            const idsToDeleteB = action.payload;
            const arrToDeleteB = [];
            Object.keys(idsToDeleteB).map(key => {
                arrToDeleteB.push(idsToDeleteB[key].localStorageId);
            });
            Object.keys(newStateT).map(key => {
                const localStorageId = newStateT[key].localStorageId;
                if (arrToDeleteB.includes(localStorageId)) {
                    delete newStateT[key]
                }
            });
            localStorage.setItem('userCart', JSON.stringify(newStateT));
            return newStateT;

        default:
            return state;

    }
}

export default variable;