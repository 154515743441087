import React, { useState, useEffect, useRef } from 'react';
import styles from "./DisplayMore.module.scss";
import { connect } from 'react-redux';

const DisplayMore = ({ carts, keyB }) => {


    const [isOpen, setIsOpen] = useState(false);

    const readMore = (e, opne) => {
        setIsOpen(!isOpen)
    }

    return (
        <div className={styles.continer}>
            <div onClick={e => readMore()} className={styles.read_more}>
                קרא עוד
            </div>
            {isOpen && <div className={styles.box}>
                <div className={styles.read_more_insid_box}>
                    {carts[keyB].size ? <p ><b className={styles.contind_details_carts_b}>גודל: </b>רוחב: {JSON.parse(carts[keyB].size).widthSize} - גובה: {JSON.parse(carts[keyB].size).heightSize}</p> : ''}
                    {JSON.parse(carts[keyB].productDetails).map((item, index) => (
                        <p keyB={index} ><b className={styles.contind_details_carts_b}>{item.k}: </b>{item.v}</p>
                    ))}
                    <div className={styles.button_close}>
                        <div className={styles.close} onClick={() => readMore()}></div>
                    </div>
                </div>
            </div>}
        </div>
    );
};


const mapStateToProps = state => {
    return {
    }
}
export default connect(mapStateToProps, {})(DisplayMore);

