import React, { useRef, useEffect, useState } from 'react';
import styles from "./Paypal.module.scss";
import { connect } from 'react-redux';
import { loadingSpinner } from '../../../actions/Loading';
import { approvedPayment } from '../../../actions/RequestUserDatabase';

const Paypal = props => {
    const [paidData, setPaidData] = useState();
    const [error, setError] = useState(null);

    const paypalRef = useRef();
    useEffect(() => {
        window.paypal.Buttons({
            createOrder: (data, actions, err) => {
                return actions.order.create({
                    payer: {
                        name: {
                            given_name: props.name,
                            surname: props.lastName
                        },
                        email_address: props.mail,
                        phone: {
                            phone_type: "MOBILE",
                            phone_number: {
                                national_number: props.phone
                            }
                        },
                        address: {
                            address_line_1: `${props.fullAddress.street}, ${props.fullAddress.houseNumber} `,
                            address_line_2: 'NO',
                            admin_area_1: props.fullAddress.city,
                            admin_area_2: 'NO',
                            postal_code: props.postalCode,
                            country_code: 'IS'
                          },
                    },
                    intent: "CAPTURE",
                    purchase_units: [
                        {
                            description: "Rimon Paypal",
                            amount: {
                                currency_code: "ILS",
                                value: props.total,
                            },
                        },
                    ],
                    application_context: {
                        shipping_preference: 'NO_SHIPPING'
                    }
                });
            },

            onApprove: async (data, actions) => {
                const order = await actions.order.capture();
                setPaidData(order);
            },
            onError: (err) => {
                setError(err);
                // console.error(err);
            },

        })
            .render(paypalRef.current);
    }, []);
    if (paidData) {
        props.approvedPayment(paidData);
        props.loadingSpinner(true);
        setPaidData(false);
    };
    return (
        <div className={styles.continer}>
            <div className={styles.title}>
                תשלום בכרטיס אשראי:
            </div>
            <div ref={paypalRef} />
            {error ? <div>Error Occurred in processing payment.! Please try again.</div> : ''}
        </div>
    );
};


const mapStateToProps = state => {
    return {
        userData: state.users,
    }
}
export default connect(mapStateToProps, { approvedPayment, loadingSpinner })(Paypal);

