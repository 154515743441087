const {
    LOADING,
    RUN_MESSAGE_SUCCEEDED,
} = require('../actions/Loading')

const defaultState = {
    loadingSpinner: false,
    messageSucceeded: false,
    messageSucceededData: '',
    messageFailed: false,
    messageFailedData: '',
};

const variable = (state = defaultState, action) => {
    switch (action.type) {
        case LOADING:
            const newState = { state };
            newState['loadingSpinner'] = action.payload
            return newState;
        case RUN_MESSAGE_SUCCEEDED:
            const newStateA = { state };
            newStateA['messageSucceeded'] = action.payload.yesOrNo;
            newStateA['messageSucceededData'] = action.payload.text;
            return newStateA;
        default:
            return state;

    }
}

export default variable;