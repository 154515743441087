import React, { useState, useEffect } from 'react';
import styles from "./SignUp.module.scss";
import { connect } from 'react-redux';
import { MDBIcon } from 'mdbreact';
import { Link, useHistory } from "react-router-dom";
import { singUp, variableCleaning } from "../../../actions/RequestUserDatabase";

const SignUp = props => {
    const history = useHistory();

    const usersData = props.users;

    const [userName, setUserName] = useState('');
    const [userNameErr, setUserNameErr] = useState('');
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [password, setPassword] = useState('');
    const [passwordErr, setPasswordErr] = useState('');
    const [passwordVerification, setPasswordVerification] = useState('');
    const [passwordVerificationErr, setPasswordVerificationErr] = useState('');
    useEffect(() => {
        if (usersData.type === 'mailIsExisist') {
            setEmailErr('המייל זה כבר קיים במערכת! בחר מייל אחר. ');
        };
        if (usersData.type === 'succeeded') {
            history.push("/login");
            props.variableCleaning('singUp');
        };
    }, [usersData]);

    const handleSudmit = () => {
        if (emailErr, passwordVerificationErr, userNameErr) {
            return;
        }
        if (!userName) {
            setUserNameErr('*לא הוקלד שם משתמש!');
            return;
        }
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(email)) {
            setEmailErr('האמייל לא תקין');
            return;
        }
        if (password.length < 6) {
            setPasswordErr('*הסיסמה צריך להיות יותר מ5 ספרות!');
            return;
        }
        if (password !== passwordVerification) {
            setPasswordVerificationErr('*הסיסמה לא אותו דבר!');
            return;
        }

        props.singUp({
            name: userName.trim(),
            mail: email.trim().toLowerCase(),
            password: password.trim()
        })
    };

    const passwordVerificationChange = (e) => {
        if (password === e.currentTarget.value) {
            setPasswordVerificationErr('');
        }
        setPasswordVerification(e.currentTarget.value);
    };
    const emailChange = (e) => {
        setEmailErr('');
        setEmail(e.currentTarget.value);
    };
    const userNameChange = (e) => {
        setUserNameErr('');
        setUserName(e.currentTarget.value);
    };

    return (
        <div>
            <div className={styles.continer}>
                <div className={styles.box}>
                    <h2>צור חשבון</h2>
                    <div className={styles.contining_properties_elements}>
                        <input onChange={userNameChange} value={userName} type="text" placeholder="שם משתמש" />
                        {userNameErr ? <div className={styles.err_message}>{userNameErr}</div> : ''}
                    </div>
                    <div className={styles.contining_properties_elements}>
                        <input onChange={emailChange} value={email} type="email" placeholder="מייל" />
                        {emailErr ? <div className={styles.err_message}>{emailErr}</div> : ''}
                    </div>
                    <div className={styles.contining_properties_elements}>
                        <input onChange={e => {
                            setPassword(e.currentTarget.value)
                             setPasswordErr('')
                             }} value={password} type="password" placeholder="סיסמה"></input>
                        {passwordErr ? <div className={styles.err_message}>{passwordErr}</div> : ''}
                    </div>
                    <div className={styles.contining_properties_elements}>
                        <input onChange={passwordVerificationChange} value={passwordVerification} type="password" placeholder="אימת סיסמה"></input>
                        {passwordVerificationErr ? <div className={styles.err_message}>{passwordVerificationErr}</div> : ''}
                    </div>
                    <div onClick={() => handleSudmit()} className={styles.login_but}>צור חשבון</div>
                    <Link to="/login">
                        <div onClick={() => { }} className={styles.other_but}>התחברות</div>
                    </Link>
                </div>

            </div>
        </div>
    );
};


const mapStateToProps = state => {
    return {
        allData: state.allCalculatorDatabase,
        webmaster: state.webmaster,
        users: state.users.singUp,
    }
}
export default connect(mapStateToProps, { singUp, variableCleaning })(SignUp);
