const { REQUEST_ALL_DATA } = require('../actions/RequestCalculatorDatabase')

const defaultState =  {
    tabTopData: [],
    categoriesData: [],
    productsData: [],
    regularProductData: [],
    productSKUData: [],
    modelsProductSKUData: [],
    productSKUFormsData: [],
    propertieData: [],
    parametersData: [],
    parametersByQuantitieData: [],
    parametersPaperData: [],
};

const variable = (state = defaultState, action) => {
    switch (action.type) {
        case REQUEST_ALL_DATA:
            return action.payload;
        default:
            return state;

    }
}

export default variable;