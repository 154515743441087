import React, { useEffect, useState } from 'react';
import styles from "./Header.module.scss";
import { connect } from 'react-redux';
import logo from './assets/logo.png';
import { useHistory, useLocation } from "react-router-dom";
import Cookies from 'universal-cookie';
import { requestAllData } from '../../actions/RequestCalculatorDatabase';
import { changeScreensValues, changeScreens } from '../../actions/controlBodyScreens';
import { changeValueUser, clearUserCartFun } from '../../actions/RequestUserDatabase';
import CategoriesWindow from './CategoriesWindow/CategoriesWindow'
import { NavLink } from 'react-router-dom';
import { MDBIcon } from 'mdbreact';
import Search from './Search/Search';

const Header = props => {

    const history = useHistory();
    const location = useLocation();
    const isManager = props.webmaster.isManager;

    const users = props.users;
    const [openCategories, setOpenCategories] = useState(false);
    const [search, setSearch] = useState('');
    useEffect(() => {
        props.requestAllData();
    }, []);
    useEffect(() => {
        setOpenCategories(false);
        setSearch('');
    }, [location]);
    useEffect(() => {
        setSearch('');
    }, [openCategories]);
    useEffect(() => {
        if (search) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        };
    }, [search]);

    const onClickRimonIcon = () => {
        history.push("/");
        props.changeScreensValues('valuesCalculatorProductsScreen', {});
        props.changeScreens('CalculatorProductsScreen', false);
        props.changeScreens('HomePage', true);
        props.changeScreensValues('valuesModelsSKUScreen', {});
        props.changeScreens('ModelsSKUScreen', false);
        props.changeScreensValues('valuesProductSKUScreen', {});
        props.changeScreens('ProductSKUScreen', false);
    };
    const onClickLogOut = () => {
        props.changeValueUser('isLogin', false);
        props.clearUserCartFun();
        const cookies = new Cookies();
        cookies.set('mailCrypto', '', { path: '/' });
        cookies.set('passwordCrypto', '', { path: '/' });
        history.push("/");
        window.location.reload()
    };
    const onClickCart = () => {
        history.push("/cart");
    };
    const onClickPrivateArea = () => {
        if (users.isLogin) {
            history.push("/user-area");
        } else {
            history.push("/login");
        }
    };

    return (
        <div>
            <div className={styles.continer}>
                <div className={styles.contining_right}>
                    <div className={`${styles.top_tab} ${openCategories ? styles.active_top_tab : ""}`} onClick={() => setOpenCategories(!openCategories)}>קטגוריות</div>
                    {isManager && <NavLink onMouseOver={() => setOpenCategories(false)} className={styles.top_tab} activeClassName={styles.active_top_tab} to="/home-page/how-it-works">איך זה עובד?</NavLink>}
                    {isManager && <NavLink className={styles.top_tab} activeClassName={styles.active_top_tab} to="/home-page/guides-designers">מדריכים למעצבים</NavLink>}
                    {isManager && <NavLink className={styles.top_tab} activeClassName={styles.active_top_tab} to="/home-page/inspiration">השראה</NavLink>}
                    <NavLink onMouseOver={() => setOpenCategories(false)} className={styles.top_tab} activeClassName={styles.active_top_tab} to="/user-area">מעקב הזמנות</NavLink>
                </div>
                <div className={styles.logo_continer}>
                    {location.pathname !== '/home-page' && <img onClick={() => onClickRimonIcon()} src={logo} alt="rimon" />}
                </div>
                <div className={styles.contining_left}>
                    <input value={search} onChange={e => setSearch(e.target.value)} type="text" placeholder="חיפוש..." name="search" />
                    <div onClick={() => onClickPrivateArea()} className={styles.contining_left_buttoms}>
                        <svg width="32" height="37" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.1507 19.137C7.42046 19.137 0.343018 25.4719 0.343018 33.287C0.343018 35.3372 2.20028 37 4.49108 37H27.8103C30.1007 37 31.9584 35.337 31.9584 33.287C31.9585 25.4719 24.8811 19.137 16.1507 19.137Z" fill="white" />
                            <path d="M16.1506 15.9589C20.8562 15.9589 24.6708 12.5444 24.6708 8.33239C24.6708 4.12038 20.8562 0.705872 16.1506 0.705872C11.445 0.705872 7.63037 4.12038 7.63037 8.33239C7.63037 12.5444 11.445 15.9589 16.1506 15.9589Z" fill="white" />
                        </svg>
                    </div>
                    <div className={styles.boundary_line} />
                    <div onClick={() => onClickCart()} className={styles.contining_left_buttoms}>
                        <svg width="31" height="37" viewBox="0 0 31 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M25.6257 12.714C25.6257 5.71175 21.0449 0 15.3941 0C9.74312 0 5.13103 5.71175 5.13103 12.714H0V37H30.7568V12.714H25.6257ZM6.39697 17.3429C5.71552 17.3429 5.13089 16.8448 5.13089 16.2C5.13089 15.5553 5.71538 15.0289 6.39697 15.0289C7.11284 15.0289 7.69733 15.5552 7.69733 16.2C7.69748 16.8448 7.11284 17.3429 6.39697 17.3429ZM15.3939 2.31399C19.6172 2.31399 23.0592 7.00122 23.0592 12.714H7.69747C7.69747 7.00122 11.1393 2.31399 15.3939 2.31399ZM24.3587 17.3429C23.6448 17.3429 23.0592 16.8448 23.0592 16.2C23.0592 15.5553 23.6448 15.0289 24.3587 15.0289C25.0724 15.0289 25.6257 15.5552 25.6257 16.2C25.6257 16.8448 25.0724 17.3429 24.3587 17.3429Z" fill="white" />
                        </svg>
                    </div>
                    {users.isLogin &&
                        <>
                            <div className={styles.boundary_line} />
                            <div className={styles.log_out} onClick={() => onClickLogOut()}><MDBIcon icon="sign-out-alt" /></div>
                        </>
                    }
                </div>

                {openCategories && <CategoriesWindow setOpenCategories={setOpenCategories} openCategories={openCategories} />}
                {search && <Search setSearch={setSearch} search={search} />}

            </div>
        </div>
    );
};


const mapStateToProps = state => {
    return {
        allData: state.allCalculatorDatabase,
        webmaster: state.webmaster,
        users: state.users,
    }
}
export default connect(mapStateToProps, { requestAllData, changeScreensValues, changeScreens, changeValueUser, clearUserCartFun })(Header);
