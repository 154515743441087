
export const maximumSizeChecker = (data, propertieData) => {
    const arrSizes = [];
    const properties = [];
    data.calculatorProductsProperties.map(property => {
        const findedProperty = propertieData.find(pro => property.id == pro.id);
        properties.push(findedProperty);
    });
    properties.map(item => {
        if (item.multiplePagesYesOrNo === 'כן') {
            arrSizes.push([
                (+item.calculationByWidthSizeColor) - (+item.gater),
                (+item.calculationByHeightSizeColor) - (+item.gater),
            ])
            item.parametersOfPropertiePapers.map(paperItem => {
                arrSizes.push([
                    (+paperItem.calculationByHeightSize) - (+item.gater),
                    (+paperItem.calculationByWidthSize) - (+item.gater),
                ])
            })
        }
    });
    const result = minimumSizeCalculation(+data.maximumHeightSize, +data.maximumWidthSize, arrSizes);
    return {
        maxWidthSize: result.width,
        maxHeightSize: result.height,
    };
};

const minimumSizeCalculation = (height, width, arrSizes) => {
    let minSize, maxSize;
    if (height > width) {
        maxSize = height;
        minSize = width;
    } else {
        maxSize = width;
        minSize = height;
    };
    arrSizes = arrSizes.map(item => {
        return item.sort((a, b) => a - b);
    });
    arrSizes.forEach(item => {
        if (item[0] < minSize) minSize = item[0];
        if (item[1] < maxSize) maxSize = item[1];
    });
    if (height > width) {
        return {
            height: maxSize,
            width: minSize,
        };
    } else {
        return {
            height: minSize,
            width: maxSize,
        };
    };
};

export const sizeAdjustmentCheck = ({
    list,
    minHeight,
    minWidth,
    maxWidth,
    maxHeight,
}) => {
    const newList = [];
    list.forEach(item => {
        if (
            (+item.heightSize) >= (+minHeight)
            &&
            (+item.widthSize) >= (+minWidth)
            &&
            (+item.heightSize) <= (+maxHeight)
            &&
            (+item.widthSize) <= (+maxWidth)
        ) {
            newList.push(item);
        };
    });

    return newList;
};