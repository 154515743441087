import React, { useEffect, useState } from 'react';
import styles from "./TopBar.module.scss";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import logoRimon from '../assets/logoRimon.png';
import { arrow } from './svgs';



const TopBar = props => {

    const history = useHistory();
    const isManager = props.webmaster.isManager;

    const onClickCategory = data => {
        history.push({
            pathname: '/categories',
            id: data.id,
            type: 'category',
            tabId: data.associationsTabTopCategories.tabTopId,
        });
    };

    const onClickTab = data => {
        history.push({
            pathname: '/categories',
            id: data.id,
            type: 'tab',
        });
    };

    const titleTopFun = props.allData.tabTopData
        .filter(item => {
            if (isManager || item.activeStatus === 'כן') {
                return true;
            } else { return false };
        })
        .map((item, index) => (
            <div key={index} className={styles.box_item}>
                <div className={styles.title} onClick={() => onClickTab(item)}>
                    {item.titleForCustomer}
                    {arrow()}
                </div>
                <div className={styles.category_box} >
                    {Object.keys(item.categoriesProductTypes)
                        .map(key => item.categoriesProductTypes[key])
                        .filter(item => {
                            if (isManager || item.activeStatus === 'כן') {
                                return true;
                            } else { return false };
                        })
                        .map((itemB, index) => (
                            <div key={index} className={styles.category_tab} onClick={() => onClickCategory(itemB)}>
                                {itemB.titleForCustomer}
                            </div>
                        ))}
                </div>
            </div>
        ));

    return (
        <div className={styles.continer}>
            <img src={logoRimon} />
            <div className={styles.continer_titleTopFun}>
                {titleTopFun}
            </div>
        </div>
    );
};


const mapStateToProps = state => {
    return {
        allData: state.allCalculatorDatabase,
        webmaster: state.webmaster,
    }
}
export default connect(mapStateToProps, {})(TopBar);