import React from 'react';
import styles from "./GuidesDesigners.module.scss";
import { connect } from 'react-redux';


const GuidesDesigners = ({ }) => {



    return (
        <div className={styles.continer}>
            <div className={styles.text_continer}>
                <h1>מדריכים למעצבים</h1>
                <p>
                    לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אל
                    ית נולום ארווס סאפיאן - פוסיליס קווי
                    קווזמן קוואזי במר מודוף. אודיפו בלאסטיק מונופץ קליר, בנפת נפקט למסון בלרק - וענוף לורם איפסום דולור סיט אמט
                    קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף קוואזי במר מודוף. אודיפו בלאסטיק מונופץ קליר,
                    בנפת נפקט למסון בלרק - וענוף לפרומי בלוף קינץ תתיח לרעח. לת צשחמי צש בליא, מנסוטו צמלח לביקו ננבי, צמוקו בלוקריה שיצמה ברורק

                    צש בליא, מנסוטו צמלח לביקו ננבי, צמוקו בלוקריה שיצמה ברורק. להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורךגולר מונפרר ס
                    וברט לורם שבצק יהול, לכנוץ בעריר גק ליץ, ושבעגט. ליבם סולגק. בראיט ולחת צורק מונחף, בגורמי מגמש. תרבנך וסתע
                    ד לכנו סתשם השמה - לתכי מורגם בורק? לתיג ישבע
                    לפרומי בלוף קינץ תתיח לרעח. לת צשחמי צש בליא, מנסוטו צמלח לביקו ננבי, צמוקו בלוקריה שיצמה בר
                    רק. לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית. סת אה דיאם. וסטיבולום אט דולור, קראס אגת לקטוס וואל אאוגו וסטיבולום סוליסי טידום בעליק. ושבעגט ליבם סולגק. בראיט ולחת צורק מונחף, בגורמי מגמש. תרבנך וסתעד לכנו סתשם השמה - לתכי מורגם בורק? לתיג ישבעס

                    קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים תוק, הדש שנרא התידם הכייר וק
                </p>
            </div >
        </div >
    );
};


const mapStateToProps = state => {
    return {}
}
export default connect(mapStateToProps, {})(GuidesDesigners);