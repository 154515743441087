import React, { useState, useEffect } from 'react';
import styles from "./CalculatorProductsScreen.module.scss";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import FilesHendler from "./components/FilesHendler/FilesHendler";
import {
    calculatorByCut,
    calculatorBySingle,
    calculatorByQuantities,
    calculatorByPaper,
} from './components/calculator';
import { addToUserCartFun, sedOrderPayment } from '../../../../actions/RequestUserDatabase';
import { useLocation } from 'react-router-dom';
import LocationPath from '../../../components/LocationPath/LocationPath';
import { changeLocationPath } from '../../../../actions/locationPath';
import Questionnaire from '../../../components/Questionnaire/Questionnaire';
import SideCart from '../../../User/SideCart/SideCart';
import { maximumSizeChecker, sizeAdjustmentCheck } from './components/maximumSizeChecker';
import { calculatorExtraShipping } from './components/calculatorExtraShipping';


const CalculatorProductsScreen = props => {

    const history = useHistory();
    const location = useLocation();
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const [product, setProduct] = useState({});
    const parameters = props.propertieData;
    const isManager = props.userData?.userData?.userType;
    const [quantityProducts, setQuantityProducts] = useState('');
    const [widthSize, setWidthSize] = useState('');
    const [widthSizeErrMessage, setWidthSizeErrMessage] = useState(false);
    const [heightSize, setHeightSize] = useState('');
    const [heightSizeErrMessage, setHeightSizeErrMessage] = useState(false);
    const [thereIsSize, setThereIsSize] = useState(true);
    const [allProperties, setAllProperties] = useState({});
    const [sumPriceObj, setSumPriceObj] = useState({});
    const [sumPriceObjRefresh, setSumPriceObjRefresh] = useState(true);
    const [sumPriceErr, setSumPriceErr] = useState(false);
    const [price, setPrice] = useState(0);
    const [arrFiles, setArrFiles] = useState([]);
    const [arrFilesErr, setArrFilesErr] = useState([]);
    const [sideCartIsOpen, setSideCartIsOpen] = useState(false);

    const [maxWidthSize, setMaxWidthSize] = useState(0);
    const [maxHeightSize, setMaxHeightSize] = useState(0);
    const [listSizes, setListSizes] = useState([]);
    const [selectedSizeFromList, setSelectedSizeFromList] = useState('בחר...');



    useEffect(() => {
        if (!location.id) return history.push("/");
        const productData = props.allData.productsData.find(element => element.id == location.id);
        const maximumSize = maximumSizeChecker(productData, props.allData.propertieData);
        setMaxWidthSize(maximumSize.maxWidthSize);
        setMaxHeightSize(maximumSize.maxHeightSize);
        const newListSizes = sizeAdjustmentCheck({
            list: productData.listPaperSizes,
            minHeight: productData.minimumHeightSize,
            minWidth: productData.minimumWidthSize,
            maxWidth: maximumSize.maxWidthSize,
            maxHeight: maximumSize.maxHeightSize,
        });
        setListSizes(newListSizes);
        setProduct(productData);
        props.changeLocationPath({ key: 'product', value: { name: productData.productName, id: productData.id } });
        props.changeLocationPath({ key: 'productSku', value: { name: '', id: '' } });
    }, [location]);

    useEffect(() => {
        if (Object.keys(product).length === 0) return;
        const arrProperties = [];
        const obj = product.calculatorProductsProperties;
        obj.map(item => { arrProperties.push(parameters.find(parametersItem => parametersItem.id === item.id)) });
        const newAllProperties = {};
        arrProperties.map((item) => {
            if (!newAllProperties[item.titleForCustomer]) {
                newAllProperties[item.titleForCustomer] = {};
                newAllProperties[item.titleForCustomer].isSelected = false;
                newAllProperties[item.titleForCustomer].selectedParameter = 'בחר פרמטר';
                newAllProperties[item.titleForCustomer].propertyValues = item;
                newAllProperties[item.titleForCustomer].selectePaperWeight = false;
                newAllProperties[item.titleForCustomer].selectePaperWeightValue = 'בחר עובי';
                newAllProperties[item.titleForCustomer].colorPrintingMethod = JSON.parse(item.colorPrintingMethod);
                newAllProperties[item.titleForCustomer].colorPrintingMethodValue = 'בחר שיטת צבע הדפסה';
                newAllProperties[item.titleForCustomer].printingSidesValue = 'דו צדדי';
                newAllProperties[item.titleForCustomer].numberPagesValue = '';
                newAllProperties[item.titleForCustomer].numberPagesErrMessage = false;
            };
        });
        if (product.thereIsSize === 'כן') {
            setWidthSize('');
            setHeightSize('');
        } else {
            setWidthSize(true);
            setHeightSize(true);
        }
        setQuantityProducts(false);
        setWidthSizeErrMessage(false);
        setHeightSizeErrMessage(false);
        setThereIsSize(true);
        setSumPriceObj({});
        setSumPriceObjRefresh(true);
        setSumPriceObj({});
        setSumPriceErr(false);
        setSumPriceObj({});
        setAllProperties(newAllProperties);
    }, [product]);
    const convertSpecificationsText = () => {
        const arrData = [];
        if (product.thereIsSize === 'כן') arrData.push({ k: 'כולל חיתוך', v: thereIsSize ? 'כן' : 'לא', p: sumPriceObj.thereIsSize ? sumPriceObj.thereIsSize : 0 });
        Object.keys(allProperties).map(key => {
            const data = allProperties[key];
            if (data.selectePaperWeight) {
                arrData.push({ k: key, v: data.selectedParameter + ' - עובי: ' + data.selectePaperWeightValue, p: sumPriceObj[key] ? sumPriceObj[key] : 0 });
                arrData.push({ k: `שיטת הדפסת ל${key}`, v: data.colorPrintingMethodValue, p: sumPriceObj[`שיטת הדפסת ל${key}`] ? sumPriceObj[`שיטת הדפסת ל${key}`] : 0 });
                arrData.push({ k: `צדדי הדפסת ל${key}`, v: data.printingSidesValue });
                arrData.push({ k: `מספר עמודים ל${key}`, v: data.numberPagesValue });
            } else {
                if (data.selectedParameter !== 'בחר פרמטר') arrData.push({ k: key, v: data.selectedParameter, p: sumPriceObj[key] ? sumPriceObj[key] : 0 });
            }

        });
        return JSON.stringify(arrData);
    };
    useEffect(() => {
        let sumPrice = 0;
        Object.keys(sumPriceObj).map(key => {
            sumPrice = (sumPriceObj[key] * 1) + sumPrice;
        });
        if (product.minimumPrice > sumPrice && sumPrice > 0) sumPrice = product.minimumPrice;
        setPrice(sumPrice.toFixed(2));
    }, [sumPriceObj]);
    const addToCartFun = () => {
        if (sideCartIsOpen) return alert('זה כבר נוסף לסל!');
        if (price === '0.00') return alert('נא בחור את כל הפרמטרים!');
        console.log('product...');
        console.log(product);
        const extraShipping = calculatorExtraShipping({
            widthSize: +widthSize,
            heightSize: +heightSize,
            thereIsSize: thereIsSize,
            allProperties: allProperties,
            shippingBoxes: product.shippingBoxes,
            quantity: quantityProducts,
            extraShippingPrice: product.extraShippingPrice,
        });
        console.log('extraShipping..............');
        console.log(extraShipping);
        if (price > 0) {
            props.addToUserCartFun({
                productName: product.titleForCustomer,
                productImage: product.Image,
                productType: 'calculator',
                productId: product.id,
                quantity: quantityProducts,
                productDetails: convertSpecificationsText(),
                price: price,
                extraPriceForShipping: 0,
                size: product.thereIsSize === 'כן' ? JSON.stringify({ widthSize: widthSize, heightSize: heightSize }) : '',
                thereAreFiles: 'yas',
                file: arrFiles,
                fileID: Math.random().toString(36).slice(8) + '-' + new Date().getTime(),
                extraShipping: extraShipping ? JSON.stringify(extraShipping) : extraShipping,
            });
        };
        setSideCartIsOpen(true);
    };
    const onClickPayment = () => {
        if (price === '0.00') return alert('נא בחור את כל הפרמטרים!');
        const extraShipping = calculatorExtraShipping({
            widthSize: +widthSize,
            heightSize: +heightSize,
            thereIsSize: thereIsSize,
            allProperties: allProperties,
            shippingBoxes: product.shippingBoxes,
            quantity: quantityProducts,
            extraShippingPrice: product.extraShippingPrice,
        });
        console.log('extraShipping..............');
        console.log(extraShipping);
        props.sedOrderPayment({
            0: {
                productName: product.titleForCustomer,
                productImage: product.Image,
                productType: 'calculator',
                productId: product.id,
                quantity: quantityProducts,
                productDetails: convertSpecificationsText(),
                price: price,
                extraPriceForShipping: 0,
                checkd: true,
                size: product.thereIsSize === 'כן' ? JSON.stringify({ widthSize: widthSize, heightSize: heightSize }) : '',
                file: arrFiles,
                fileID: Math.random().toString(36).slice(8) + '-' + new Date().getTime(),
                extraShipping: extraShipping,
            }
        });
        history.push("/order-payment");
    };
    const calculatorAllstate = () => {
        // Checks if all existing values
        let ifCorrect = true;
        const chackSize = product.thereIsSize === 'כן' ? widthSize && heightSize ? true : false : true;
        if (quantityProducts && !widthSizeErrMessage && !heightSizeErrMessage && chackSize && ifCorrect) {
            Object.keys(allProperties).map(key => {
                const item = allProperties[key];
                if (item.isSelected && ifCorrect) {
                    if (item.parameterValues.type === 'calculatorByPaper') {
                        if (item.selectePaperWeight &&
                            item.selectePaperWeightValue !== 'בחר עובי' &&
                            item.colorPrintingMethodValue !== 'בחר שיטת צבע הדפסה' &&
                            item.numberPagesValue &&
                            !item.numberPagesErrMessage) { ifCorrect = true; } else {
                            ifCorrect = false;
                        };
                    } else { ifCorrect = true; };
                } else { ifCorrect = false; };
            });
        } else { ifCorrect = false; };
        // Calculates all parameters
        if (ifCorrect && sumPriceObjRefresh) {
            let sumPriceObjNew = { ...sumPriceObj };
            sumPriceObjNew['productPrice'] = product.productPrice * quantityProducts;
            if (product.thereIsSize === 'כן' && product.priceSingleCut > 0 && thereIsSize) {
                const price = calculatorByCut(product.priceSingleCut, allProperties, widthSize, heightSize);
                sumPriceObjNew['thereIsSize'] = price.toFixed(2);
            } else { sumPriceObjNew['thereIsSize'] = 0 };
            sumPriceObjNew = calculatorBySingle(sumPriceObjNew, allProperties, quantityProducts);
            sumPriceObjNew = calculatorByQuantities(sumPriceObjNew, allProperties, quantityProducts);
            sumPriceObjNew = calculatorByPaper(sumPriceObjNew, allProperties, quantityProducts, widthSize, heightSize);
            setSumPriceObj(sumPriceObjNew);
            setSumPriceObjRefresh(false);
        } else {
            if (!sumPriceErr) setSumPriceErr(true);
        };
    };
    calculatorAllstate();
    const numberPagesChange = (e, propertyName) => {
        const newAllProperties = { ...allProperties };
        newAllProperties[propertyName].numberPagesValue = e.currentTarget.value;
        newAllProperties[propertyName].numberPagesErrMessage = false;
        setAllProperties(newAllProperties);
    };
    const numberPagesBlur = (e, propertyName, jumpsPages) => {
        const inputNum = e.currentTarget.value * 1;
        const newAllProperties = { ...allProperties };
        if (inputNum < newAllProperties[propertyName].propertyValues.multiplePagesMinimum) {
            newAllProperties[propertyName].numberPagesErrMessage = 'מינימום עמודים ' + newAllProperties[propertyName].propertyValues.multiplePagesMinimum + ' לבחירה!';
        } else if (inputNum > newAllProperties[propertyName].propertyValues.multiplePagesMaximum) {
            newAllProperties[propertyName].numberPagesErrMessage = 'מקסימום עמודים ' + newAllProperties[propertyName].propertyValues.multiplePagesMaximum + ' לבחירה!';
        } else {
            if ((inputNum % jumpsPages) !== 0) {
                newAllProperties[propertyName].numberPagesErrMessage = `מס' עמודים לא מתחלק ב ${jumpsPages} בחר או ${inputNum - (inputNum % jumpsPages)} או ${inputNum + (jumpsPages - (inputNum % jumpsPages))} עמודים`
            };
        }
        setAllProperties(newAllProperties);
        setSumPriceObjRefresh(true);
        setSumPriceObj({});
    };
    const quantityProductsChange = (e) => {
        let num = +e.currentTarget.value;
        setQuantityProducts(num.toFixed(0));
        setSumPriceObjRefresh(true);
        setSumPriceObj({});
    };
    const widthSizeChange = (e) => {
        setWidthSize(e.currentTarget.value);
        setWidthSizeErrMessage(false);
        setSelectedSizeFromList('בחר...');
    };
    const widthSizeBlur = (e) => {
        setSumPriceObjRefresh(true);
        setSumPriceObj({});
        const inputNum = e.currentTarget.value;
        if (inputNum < product.minimumWidthSize) {
            setWidthSizeErrMessage('מינימום גודל רוחב לבחירה ' + product.minimumWidthSize + " ס''מ!");
        } else if (inputNum > maxWidthSize) {
            setWidthSizeErrMessage('מקסימום גודל רוחב לבחירה ' + maxWidthSize + " ס''מ!");
        }
    };
    const heightSizeChange = (e) => {
        setHeightSize(e.currentTarget.value);
        setHeightSizeErrMessage(false);
        setSelectedSizeFromList('בחר...');
    };
    const heightSizeBlur = (e) => {
        setSumPriceObjRefresh(true);
        setSumPriceObj({});
        const inputNum = e.currentTarget.value;
        if (inputNum < product.minimumHeightSize) {
            setHeightSizeErrMessage('מינימום גודל גובה לבחירה ' + product.minimumHeightSize + " ס''מ!");
        } else if (inputNum > maxHeightSize) {
            setHeightSizeErrMessage('מקסימום גודל גובה לבחירה ' + maxHeightSize + " ס''מ!");
        }
    };
    const paperWeightChange = (e, propertyName) => {
        const newAllProperties = { ...allProperties };
        newAllProperties[propertyName].selectePaperWeightValue = e.currentTarget.value;
        setAllProperties(newAllProperties);
        setSumPriceObjRefresh(true);
        setSumPriceObj({});
    };
    const colorPrintingMethodChange = (e, propertyName) => {
        const newAllProperties = { ...allProperties };
        newAllProperties[propertyName].colorPrintingMethodValue = e.currentTarget.value;
        setAllProperties(newAllProperties);
        setSumPriceObjRefresh(true);
        setSumPriceObj({});
    };
    const printingSidesValueChange = (e, propertyName) => {
        const newAllProperties = { ...allProperties };
        newAllProperties[propertyName].printingSidesValue = e.currentTarget.value;
        setAllProperties(newAllProperties);
        setSumPriceObjRefresh(true);
        setSumPriceObj({});
    };

    const handleSelectProperties = (e, propertyName) => {
        setSumPriceObjRefresh(true);
        setSumPriceObj({});
        const newAllProperties = { ...allProperties };
        const index = e.target.selectedIndex;
        const optionElement = e.target.childNodes[index];
        const typeParameter = optionElement.getAttribute('data-type');
        const parameterId = optionElement.getAttribute('data-id');
        newAllProperties[propertyName].selectedParameter = e.currentTarget.value;
        newAllProperties[propertyName].isSelected = true;
        const objValues = newAllProperties[propertyName].propertyValues[typeParameter];
        let objValuesResult = '';
        Object.keys(objValues).map(key => {
            if (objValues[key].id === parameterId * 1) objValuesResult = objValues[key];
        });
        newAllProperties[propertyName].parameterValues = objValuesResult;
        if (typeParameter === 'parametersOfPropertiePapers') {
            const objPaperWeight = newAllProperties[propertyName].propertyValues.parametersOfPropertiePapers;
            let paperWeight = '';
            Object.keys(objPaperWeight).map(key => {
                if (objPaperWeight[key].id === parameterId * 1) {
                    paperWeight = objPaperWeight[key].PaperPriceListWeight;
                }
            });
            newAllProperties[propertyName].selectePaperWeight = JSON.parse(paperWeight);
            newAllProperties[propertyName].selectePaperWeightValue = 'בחר עובי';
        } else {
            newAllProperties[propertyName].selectePaperWeight = false;
            newAllProperties[propertyName].selectePaperWeightValue = 'בחר עובי';
        };
        setAllProperties(newAllProperties);
    };
    const handleProperties = () => {
        if (Object.keys(allProperties).length === '0') return;
        return Object.keys(allProperties).map((name, index) => {
            const item = allProperties[name];
            return (
                <div key={index} className={item.selectePaperWeight ? styles.label_calculator_parameter_paper : ''}>
                    <div className={styles.contining_properties_elements}>
                        <div className={styles.label_calculator_parameter}>{name}:</div>
                        <select type="text" value={allProperties[name].selectedParameter} onChange={e => handleSelectProperties(e, name)}>
                            <option disabled>{allProperties[name].selectedParameter}</option>
                            {item.propertyValues.parametersOfProperties.map((param, index) => <option data-id={param.id} data-type='parametersOfProperties' key={index}>{param.titleForCustomer}</option>)}
                            {item.propertyValues.parametersOfPropertieByQuantities.map((param, index) => <option data-id={param.id} data-type='parametersOfPropertieByQuantities' key={index + 500}>{param.titleForCustomer}</option>)}
                            {item.propertyValues.parametersOfPropertiePapers.map((param, index) => <option data-id={param.id} data-type='parametersOfPropertiePapers' key={index + 1000}>{param.titleForCustomer}</option>)}
                        </select>
                    </div>
                    {item.selectePaperWeight && <div className={styles.contining_properties_elements}>
                        <div className={styles.label_calculator_parameter}>עובי:</div>
                        <select value={allProperties[name].selectePaperWeightValue} onChange={e => paperWeightChange(e, name)}>
                            <option disabled>{allProperties[name].selectePaperWeightValue}</option>
                            {item.selectePaperWeight.map((item, key) => (
                                <option key={key}>{item.paperWeight}</option>
                            ))}
                        </select>
                    </div>}
                    {item.selectePaperWeight && <div>
                        <div className={styles.contining_properties_elements}>
                            <div className={styles.label_calculator_parameter}>שיטת הדפסה:</div>
                            <select value={allProperties[name].colorPrintingMethodValue} onChange={e => colorPrintingMethodChange(e, name)}>
                                <option disabled>{allProperties[name].colorPrintingMethodValue}</option>
                                {item.colorPrintingMethod.map((item, key) => (
                                    <option key={key}>{item.color}</option>
                                ))}
                            </select>
                        </div>
                        <div className={styles.contining_properties_elements}>
                            <div className={styles.label_calculator_parameter}>בחר צדדי הדפסה:</div>
                            <select value={allProperties[name].printingSidesValue} onChange={e => printingSidesValueChange(e, name)}>
                                {allProperties[name].propertyValues.selectPaperSides !== 'רק צד 1' && <option>דו צדדי</option>}
                                {allProperties[name].propertyValues.selectPaperSides !== `רק דו''צ` && <option>צד 1</option>}
                            </select>
                        </div>
                        <div className={styles.contining_properties_elements}>
                            <div className={styles.label_calculator_parameter}> בחירת מספר עמודים ל{name} מ {allProperties[name].propertyValues.multiplePagesMinimum} עד {allProperties[name].propertyValues.multiplePagesMaximum}:</div>
                            <div>
                                {allProperties[name].numberPagesErrMessage && <div className={styles.err_message}>{allProperties[name].numberPagesErrMessage}</div>}
                                <input type="number" onBlur={e => numberPagesBlur(e, name, allProperties[name].propertyValues.jumpsPages)} onChange={e => numberPagesChange(e, name)} placeholder="הכנס את מספר עמודים" value={allProperties[name].numberPagesValue} required />
                            </div>
                        </div>
                    </div>}
                </div>
            );
        });
    };
    const handleSpecificationsText = () => {
        return Object.keys(allProperties).map(key => (
            <div className={styles.div_specifications_text} key={key}>
                <b className={styles.bold_text}>{key}: </b>
                {allProperties[key].selectedParameter !== 'בחר פרמטר' ? allProperties[key].selectedParameter : <b className={styles.red_text}> לא נבחר פרמטר! </b>}
                {allProperties[key].selectePaperWeightValue !== 'בחר עובי' ? ' - עובי: ' + allProperties[key].selectePaperWeightValue : allProperties[key].selectePaperWeight ? <b className={styles.red_text}> לא נבחר עובי! </b> : ''}
                {isManager && sumPriceObj[key] ? <b className={styles.price_to_manager}>מחיר: {sumPriceObj[key]}</b> : ''}
                {allProperties[key].selectePaperWeight ? <div>
                    <b className={styles.bold_text}>שיטת הדפסת ל{key}: </b>
                    {allProperties[key].colorPrintingMethodValue !== 'בחר שיטת צבע הדפסה' ? allProperties[key].colorPrintingMethodValue : <b className={styles.red_text}> לא נבחר שיטת הדפסה! </b>}
                    {isManager && sumPriceObj[`שיטת הדפסת ל${key}`] ? <b className={styles.price_to_manager}>מחיר: {sumPriceObj[`שיטת הדפסת ל${key}`]}</b> : ''}
                    <br />
                    <b className={styles.bold_text}>צדדי הדפסת ל{key}: </b>
                    {allProperties[key].printingSidesValue}
                    <br />
                    <b className={styles.bold_text}>מספר עמודים ל{key}: </b>
                    {allProperties[key].numberPagesValue ? allProperties[key].numberPagesValue : <b className={styles.red_text}> לא נבחר עמודים! </b>}
                </div> : ''}
            </div>
        ));
    };
    const isJson = str => {
        try {
            JSON.parse(str);
            if (JSON.parse(str) === null) return false;
        } catch (e) {
            return false;
        }
        return true;
    };
    const onChangeThereIsSize = e => {
        setThereIsSize(e.currentTarget.value === 'כן' ? true : false);
        setSumPriceObjRefresh(true);
        setSumPriceObj({});
    };

    const handleSelectListSizes = e => {
        const index = e.target[e.target.selectedIndex].getAttribute('arr-index');
        const data = listSizes[+index];
        setSelectedSizeFromList(e.currentTarget.value);
        setHeightSize(data.heightSize);
        setWidthSize(data.widthSize);
        setWidthSizeErrMessage(false);
        setHeightSizeErrMessage(false);
    };
    return (
        <div className={styles.continer}>
            <SideCart isOpenHeandler={sideCartIsOpen} />
            <div className={styles.top_box}>
                <div className={styles.top_box_right}>
                    <LocationPath />
                    {product.Image && <div className={styles.contind_img_top}>
                        <img src={product.Image} />
                    </div>}
                    {isJson(product.howManyFiles) && <div>
                        {JSON.parse(product.howManyFiles).length > 0 && <div className={styles.top_box_right_title}>העלאת קבצים</div>}
                        <FilesHendler
                            howManyFiles={product.howManyFiles}
                            arrFiles={arrFiles}
                            setArrFiles={setArrFiles}
                            arrFilesErr={arrFilesErr}
                            setArrFilesErr={setArrFilesErr}
                        />
                    </div>}
                </div>
                <div className={styles.top_box_left}>
                    <h1 className={styles.titleForCustomer}>{product.titleForCustomer}{isManager && sumPriceObj.productPrice ? <b className={styles.price_to_manager}>מחיר: {sumPriceObj.productPrice}</b> : ''}</h1>
                    {product.id && <div className={styles.selecting_all_properties_continer}>
                        <div className={styles.selecting_properties_continer}>
                            {product.thereIsSize === 'כן' && <div className={styles.contining_properties_elements}>
                                <div className={styles.label_calculator_parameter}>גדלים סטנדרטיים</div>
                                <select type="text" value={selectedSizeFromList} onChange={handleSelectListSizes}>
                                    <option disabled >בחר...</option>
                                    {listSizes.map((param, index) => <option arr-index={index} key={index}>{param.name}</option>)}
                                </select>
                            </div>}
                            {product.thereIsSize === 'כן' && <div className={styles.sizes_box}>
                                <div className={`${styles.label_sizes}`}>גודל המוצר:</div>
                                <div className={styles.contining_properties_elements}>
                                    <div className={styles.label_calculator_parameter}>רוחב</div>
                                    <div className={styles.contining_input}>
                                        {widthSizeErrMessage && <div className={styles.err_message}>{widthSizeErrMessage}</div>}
                                        <input type="number" onBlur={widthSizeBlur} onChange={widthSizeChange} placeholder="הכנס רוחב הגודל" value={widthSize} required />
                                    </div>
                                </div>
                                <div className={styles.contining_properties_elements}>
                                    <div className={styles.label_calculator_parameter}>גובה:</div>
                                    <div className={styles.contining_input}>
                                        {heightSizeErrMessage && <div className={styles.err_message}>{heightSizeErrMessage}</div>}
                                        <input type="number" onBlur={heightSizeBlur} onChange={heightSizeChange} placeholder="הכנס רוחב הגודל" value={heightSize} required />
                                    </div>
                                </div>
                            </div>}
                            {product.thereIsSize === 'כן' && <div className={styles.contining_properties_elements}>
                                <div className={styles.label_calculator_parameter}>כולל חיתוך:</div>
                                {/* <input type="checkbox" defaultChecked={thereIsSize} onChange={() => { setThereIsSize(!thereIsSize); setSumPriceObjRefresh(true); setSumPriceObj({}); }} /> */}
                                <select onChange={onChangeThereIsSize}>
                                    <option>כן</option>
                                    <option>לא</option>
                                </select>
                            </div>}
                            {handleProperties()}
                            <div className={styles.contining_properties_elements}>
                                <div className={styles.label_calculator_parameter}>כמות:</div>
                                <div className={styles.contining_input}>
                                    {/* <div> */}
                                    <input type="number" onChange={quantityProductsChange} placeholder="הכנס כמות" value={quantityProducts} required />
                                </div>
                            </div>

                        </div>
                        {/* <div className={styles.selecting_properties_left}>
                            <div className={styles.product_specifications}>
                                <h4>מפרט למוצר</h4>
                                <p><b className={styles.bold_text}>כמות: </b>  {quantityProducts ? quantityProducts : <b className={styles.red_text}>לא נבחר כמות! </b>} </p>
                                {product.thereIsSize === 'כן' ? <p><b className={styles.bold_text}>גודל הנבחר: </b>  {heightSize ? "גובה " + heightSize + " ס''מ " : <b className={styles.red_text}>לא נבחר גודל גובה! </b>} -  {widthSize ? " רוחב " + widthSize + " ס''מ " : <b className={styles.red_text}>לא נבחר גודל רוחב! </b>} </p> : ''}
                                {product.thereIsSize === 'כן' ? <p><b className={styles.bold_text}>כולל חיתוך: </b> {thereIsSize ? 'כן' : 'לא'}{isManager && sumPriceObj.thereIsSize ? <b className={styles.price_to_manager}>מחיר: {sumPriceObj.thereIsSize}</b> : ''}</p> : ''}
                                {handleSpecificationsText()}
                            </div>
                        </div> */}
                        <div className={styles.product_sum_price}>
                            <p>מחיר:</p>
                            <h3>{price}</h3>
                            <p>ש''ח</p>
                        </div>
                        {price > 0 ? <div className={styles.contining_buttons}>
                        </div> : ''}
                        <div onClick={() => addToCartFun()} className={styles.button}>שמירה בסל קניות</div>
                        <div onClick={() => onClickPayment()} className={styles.button_payment}>מעבר לתשלום</div>
                    </div>}
                </div>
            </div>
            <Questionnaire quesData={[]} />
        </div>
    );
};


const mapStateToProps = state => {
    return {
        // controlValuesScreens: state.controlValuesScreens.valuesCalculatorProductsScreen,
        propertieData: state.allCalculatorDatabase.propertieData,
        userData: state.users,
        allData: state.allCalculatorDatabase,
    }
}
export default connect(mapStateToProps, { addToUserCartFun, sedOrderPayment, changeLocationPath })(CalculatorProductsScreen);