
export const dataSaleProducts =  [
    {
        title: `כרומו דק 115 גרם`,
        path: 'sale-street-ads-115',
        detail: `
        50*70 ס"מ כולל שוליים לבנים הדפסה צד אחד בצבע מלא
        `,
        id: 1,
        price: 1,
        minPrice: 100,
        termsFile: `
        ממתין לעו"ד ממוטי
        `,
        termsProduct: `
        תקנון מקצועי ממתין למוטי מהעו"ד
        `,
        extraShipping: {
            above: 200,
            unitPrice: 0.1
        },
        deliveryTimeText: `שליח עד הבית 3 ימי עבודה`,
        deliveryDays: 3,
        timeForSelfCollectionText: `איסוף עצמי: מה שיישלח מהשעה 8:00 עד השעה 16:00 יהיו מוכנים באותו יום לא יאוחר מ 5 שעות הדפסה `,
        selfCollectionDays: 1,
    },
    {
        title: `כרומו דק 130 גרם`,
        path: 'sale-street-ads-130',
        detail: `
        50*70 ס"מ כולל שוליים לבנים הדפסה צד אחד בצבע מלא
        `,
        id: 2,
        price: 1.2,
        minPrice: 100,
        termsFile: `
        ממתין לעו"ד ממוטי
        `,
        termsProduct: `
        תקנון מקצועי ממתין למוטי מהעו"ד
        `,
        extraShipping: {
            above: 200,
            unitPrice: 0.1
        },
        deliveryTimeText: `שליח עד הבית 3 ימי עבודה`,
        deliveryDays: 3,
        timeForSelfCollectionText: `איסוף עצמי: מה שיישלח מהשעה 8:00 עד השעה 16:00 יהיו מוכנים באותו יום לא יאוחר מ 5 שעות הדפסה `,
        selfCollectionDays: 1,
    },
    {
        title: `כרומו דק 157-170 גרם `,
        path: 'sale-street-ads-170',
        detail: `
        50*70 ס"מ כולל שוליים לבנים הדפסה צד אחד בצבע מלא, הנייר שמגיע מגיע מספקים שונים ונייר 157 גרם מחושב תחת הגדרה של 170 גרם ההדםסה היא על הנייר שזמין במלאי
        `,
        id: 3,
        price: 1.4,
        minPrice: 100,
        termsFile: `
        ממתין לעו"ד ממוטי
        `,
        termsProduct: `
        תקנון מקצועי ממתין למוטי מהעו"ד
        `,
        extraShipping: {
            above: 100,
            unitPrice: 0.1
        },
        deliveryTimeText: `שליח עד הבית 3 ימי עבודה`,
        deliveryDays: 3,
        timeForSelfCollectionText: `איסוף עצמי: מה שיישלח מהשעה 8:00 עד השעה 16:00 יהיו מוכנים באותו יום לא יאוחר מ 5 שעות הדפסה `,
        selfCollectionDays: 1,
    },
    {
        title: `נייר כרומו עבה 300 גרם  `,
        path: 'sale-street-ads-300',
        detail: `
        50*70 ס"מ כולל שוליים לבנים הדפסה צד אחד בצבע מלא מחיר הגרמים מתייחס לנייר מטר על מטר
        `,
        id: 4,
        price: 2,
        minPrice: 100,
        termsFile: `
        ממתין לעו"ד ממוטי
        `,
        termsProduct: `
        תקנון מקצועי ממתין למוטי מהעו"ד
        `,
        extraShipping: {
            above: 50,
            unitPrice: 0.2
        },
        deliveryTimeText: `שליח עד הבית 3 ימי עבודה`,
        deliveryDays: 3,
        timeForSelfCollectionText: `איסוף עצמי: מה שיישלח מהשעה 8:00 עד השעה 16:00 יהיו מוכנים באותו יום לא יאוחר מ 5 שעות הדפסה `,
        selfCollectionDays: 1,
    },
];