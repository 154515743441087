import { combineReducers } from 'redux';
import allCalculatorDatabase from './allCalculatorDatabase';
import controlBodyScreen from './controlBodyScreen';
import controlValuesScreens from './controlValuesScreens';
import webmaster from './webmaster';
import users from './users';
import cart from './cart';
import orderPayment from './orderPayment';
import orderPaymentRes from './orderPaymentRes';
import orders from './orders';
import loading from './loading';
import deliveries from './deliveries';
import orderPaymentResPaypal from './orderPaymentResPaypal';
import VAT from './VAT';
import salePageItem from './sale/salePageItem';
import uploadingFile from './uploadingFile/uploadingFile';
import uploadingFileLocalStorage from './uploadingFile/uploadingFileLocalStorage';
import locationPath from './locationPath';
import popupHeandler from './popupHeandler';

export default combineReducers({
    allCalculatorDatabase: allCalculatorDatabase,
    controlBodyScreen: controlBodyScreen,
    controlValuesScreens: controlValuesScreens,
    webmaster: webmaster,
    users: users,
    cart: cart,
    orderPayment: orderPayment,
    orderPaymentRes: orderPaymentRes,
    orders: orders,
    loading: loading,
    deliveries: deliveries,
    orderPaymentResPaypal: orderPaymentResPaypal,
    VAT: VAT,
    salePageItemData: salePageItem,
    uploadingFileData: uploadingFile,
    uploadingFileLocalStorageData: uploadingFileLocalStorage,
    locationPathData: locationPath,
    popupHeandlerData: popupHeandler,
});