import React from 'react';
import styles from "./HomePage.module.scss";
import { connect } from 'react-redux';
import CustomersRecommend from './CustomersRecommend/CustomersRecommend';
import DisplayTabs from './DisplayTabs/DisplayTabs';
import TopBar from './TopBar/TopBar';
import AboutUs from './AboutUs/AboutUs';
import { Switch, Route } from 'react-router-dom';
import { } from 'react-router-dom';
import HowItWorks from './pages/HowItWorks/HowItWorks';
import GuidesDesigners from './pages/GuidesDesigners/GuidesDesigners';
import Inspiration from './pages/Inspiration/Inspiration';
import About from './pages/About/About';
import AllProducts from './pages/AllProducts/AllProducts';
import LocationMap from './pages/LocationMap/LocationMap';


const HomePage = props => {

    const isManager = props.webmaster.isManager;


    return (
        <>
            <Switch>
                <Route exact path="/home-page">
                    <div className={styles.continer}>
                        <TopBar />
                        <AboutUs />
                        <DisplayTabs />
                        {isManager && <CustomersRecommend />}
                    </div >
                </Route>
                <Route path="/home-page/how-it-works">
                    <HowItWorks />
                </Route>
                <Route path="/home-page/guides-designers">
                    <GuidesDesigners />
                </Route>
                <Route path="/home-page/inspiration">
                    <Inspiration />
                </Route>
                <Route path="/home-page/about">
                    <About />
                </Route>
                <Route path="/home-page/all-products">
                    <AllProducts />
                </Route>
                <Route path="/home-page/location-map">
                    <LocationMap />
                </Route>
            </Switch>
        </>
    );
};


const mapStateToProps = state => {
    return {
        webmaster: state.webmaster,
    }
}
export default connect(mapStateToProps, {})(HomePage);