const { CHANGE_CALCULATOR_SCREENS } = require('../actions/controlBodyScreens')

const defaultState =  {
    HomePage: true,
    CalculatorProductsScreen: false,
    ProductSKUScreen: false,
    ModelsSKUScreen: false,
    SaleProducts: false,
};

const variable = (state = defaultState, action) => {
    switch (action.type) {
        case CHANGE_CALCULATOR_SCREENS:
            const newStateA = {...state};
            newStateA[action.payload.screen] = action.payload.value
            return newStateA;
        default:
            return state;

    }
}

export default variable;