import React, { useState, useEffect } from 'react';
import styles from "./ResetPassword.module.scss";
import { connect } from 'react-redux';
import { MDBIcon } from 'mdbreact';
import { Link, useHistory } from "react-router-dom";
import { resetPasswordFun, variableCleaning } from "../../../actions/RequestUserDatabase";

const ResetPassword = props => {

    const usersData = props.users;

    const history = useHistory();

    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [succeeded, setSucceeded] = useState(false);
    useEffect(() => {
        if (usersData.type === 'mailIsNotExisist') {
            setEmailErr('המייל זה לא קיים במערכת! בחר מייל אחר. ');
        };
        if (usersData.type === 'succeeded') {
            setSucceeded(true);
        };
    }, [usersData]);

    const handleSudmit = () => {
        if (emailErr) {
            return;
        }

        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(email)) {
            setEmailErr('האמייל לא תקין');
            return;
        }

        props.resetPasswordFun({
            mail: email.trim().toLowerCase(),
            path: process.env.REACT_APP_CURRENT_WEB_SIT_PATH,
        })
    };

    const emailChange = (e) => {
        setEmailErr('');
        setEmail(e.currentTarget.value);
    };

    return (
        <div className={styles.continer}>
            {succeeded ? <div className={styles.signup_classic}>
                <h2>נשלח לך למייל {usersData.data.mail} לינק לאיפוס סיסמה</h2>
                <div className={styles.form}>
                    <Link to="/">
                        <button onClick={() => props.variableCleaning('resetPassword')} className={styles.btn}>חזור למסך הבית</button>
                    </Link>
                </div>
            </div> :
                <div className={styles.box}>
                    <h2>איפוס סיסמה</h2>
                    <div className={styles.contining_properties_elements}>
                        <input onChange={emailChange} value={email} type="email" placeholder="מייל לאיפוס" />
                        {emailErr ? <div className={styles.err_message}>{emailErr}</div> : ''}
                    </div>
                <div onClick={() => handleSudmit()} className={styles.login_but}>המשך</div>

                    <div className={styles.form}>
                    <div onClick={() => history.push("/login")} className={styles.other_but}>חזור להתחברות</div>
                    </div>
                </div>
            }
        </div>
    );
};


const mapStateToProps = state => {
    return {
        allData: state.allCalculatorDatabase,
        webmaster: state.webmaster,
        users: state.users.resetPassword,
    }
}
export default connect(mapStateToProps, { resetPasswordFun, variableCleaning })(ResetPassword);
