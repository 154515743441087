import React from 'react';
import styles from "./Footer.module.scss";
import { connect } from 'react-redux';
import paymentFacilities from './assets/payment-facilities.png'
import { NavLink, useHistory } from 'react-router-dom';

const Footer = props => {
    const history = useHistory();
    const isManager = props.webmaster.isManager;

    return (
        <div className={styles.footer}>
            <div className={styles.continer}>
                <div className={styles.continer_right}>
                   {isManager && <div className={styles.right_content}>
                        <div className={styles.title}>דפוס רימון אונליין</div>
                        <NavLink className={styles.text_tab} activeClassName={styles.active_text_tab} to="/home-page/about">אודות</NavLink>
                        <NavLink className={styles.text_tab} activeClassName={styles.active_text_tab} to="/home-page/questions-answers">שאלות ותשובות</NavLink>
                        <NavLink className={styles.text_tab} activeClassName={styles.active_text_tab} to="/home-page/terms-user">תקנון האתר ותנאי שימוש</NavLink>
                    </div>}
                    <div className={styles.right_content}>
                        <div className={styles.title}>שירות לקוחות</div>
                        {isManager && <NavLink className={styles.text_tab} activeClassName={styles.active_text_tab} to="/home-page/shipping-policy">מדיניות משלוחים</NavLink>}
                        <NavLink className={styles.text_tab} activeClassName={styles.active_text_tab} to="/user-area">מעקב הזמנות</NavLink>
                        {isManager && <NavLink className={styles.text_tab} activeClassName={styles.active_text_tab} to="/home-page/my-account">החשבון שלי</NavLink>}
                    </div>
                </div>
                <div className={styles.continer_left}>
                    <div onClick={() => history.push('/home-page/location-map')} className={styles.item_left}>
                        <div className={styles.icon_text}>
                            רח' כהנמן 100 בני ברק
                        </div>
                        <div className={styles.icon}>
                            <svg width="28" height="35" viewBox="0 0 28 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.6865 0C6.14178 0 0 6.13996 0 13.6824C0 14.541 0.0786503 15.4067 0.243288 16.2513C0.250625 16.3013 0.279258 16.4519 0.336612 16.7094C0.543841 17.6325 0.85182 18.5414 1.25268 19.4072C2.72726 22.878 5.96981 28.209 13.0849 33.9195C13.2639 34.0628 13.4789 34.1344 13.6935 34.1344C13.9084 34.1344 14.123 34.0627 14.302 33.9195C21.4101 28.209 24.66 22.8779 26.1342 19.4072C26.5351 18.5414 26.8431 17.6395 27.0507 16.7094C27.108 16.4519 27.1366 16.3013 27.1436 16.2513C27.3013 15.4067 27.3869 14.541 27.3869 13.6824C27.373 6.13996 21.2312 0 13.6865 0ZM25.2255 15.9152C25.2255 15.9291 25.2182 15.9435 25.2182 15.9578C25.2112 15.9938 25.1895 16.1011 25.1538 16.2656V16.28C24.9749 17.0815 24.7099 17.8614 24.359 18.6126C24.352 18.62 24.352 18.6343 24.3447 18.6413C23.0064 21.8115 20.0715 26.6491 13.6865 31.9229C7.30149 26.6491 4.36656 21.8115 3.0279 18.6413C3.02092 18.6343 3.02092 18.62 3.01358 18.6126C2.66999 17.8688 2.40505 17.0815 2.21912 16.28V16.2656C2.17608 16.1011 2.16176 15.9938 2.15442 15.9578C2.15442 15.9435 2.14745 15.9291 2.14745 15.9078C2.0041 15.171 1.93288 14.4266 1.93288 13.6751C1.93288 7.19896 7.20817 1.92497 13.6865 1.92497C20.1644 1.92497 25.4401 7.19896 25.4401 13.6751C25.4401 14.4336 25.3685 15.1853 25.2255 15.9152Z" fill="white" />
                                <path d="M13.6864 5.14502C8.89762 5.14502 4.99634 9.04515 4.99634 13.8325C4.99634 18.6198 8.89762 22.5201 13.6864 22.5201C18.4752 22.5201 22.3762 18.6198 22.3762 13.8325C22.3762 9.04515 18.4751 5.14502 13.6864 5.14502ZM13.6864 20.5878C9.95703 20.5878 6.92913 17.5534 6.92913 13.8325C6.92913 10.1112 9.96401 7.07724 13.6864 7.07724C17.4085 7.07724 20.4437 10.1112 20.4437 13.8325C20.4436 17.5534 17.4158 20.5878 13.6864 20.5878Z" fill="white" />
                            </svg>
                        </div>
                    </div>
                    <a href="mailto: 8000133@gmail.com" target="_blank" >
                    <div className={styles.item_left}>
                        <div dir='ltr' className={styles.icon_text}>
                            8000133@gmail.com
                        </div>
                        <div className={styles.icon}>
                            <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.1735 15.0322C22.0657 15.0322 21.9579 14.9933 21.8717 14.9114L16.7919 10.1486C16.615 9.97965 16.6064 9.70374 16.7704 9.52352C16.9385 9.34671 17.2189 9.33722 17.3956 9.5063L22.4798 14.2691C22.6566 14.4372 22.6652 14.7175 22.497 14.8942C22.4107 14.9856 22.2943 15.0322 22.1735 15.0322Z" fill="white" />
                                <path d="M5.21433 15.0319C5.09191 15.0319 4.97633 14.9854 4.89088 14.894C4.72099 14.7172 4.73048 14.437 4.90645 14.2689L9.99147 9.50621C10.1726 9.33725 10.4538 9.34674 10.6167 9.52343C10.7857 9.70365 10.7771 9.97955 10.6004 10.1485L5.51527 14.9112C5.42905 14.9931 5.32042 15.0319 5.21433 15.0319Z" fill="white" />
                                <path d="M25.1791 19.4638H2.20786C0.990996 19.4638 0 18.4774 0 17.2566V2.20719C0 0.992353 0.990996 0 2.20786 0H25.1791C26.3951 0 27.3869 0.992353 27.3869 2.20719V17.2566C27.3869 18.4775 26.3951 19.4638 25.1791 19.4638ZM2.20786 0.884643C1.47904 0.884643 0.884025 1.47959 0.884025 2.20719V17.2566C0.884025 17.9895 1.47915 18.58 2.20786 18.58H25.1791C25.9122 18.58 26.5029 17.9894 26.5029 17.2566V2.20719C26.5029 1.47948 25.9121 0.884643 25.1791 0.884643H2.20786Z" fill="white" />
                                <path d="M13.6948 11.9508C13.1048 11.9508 12.5184 11.7568 12.0751 11.368L0.733107 1.47957C0.547647 1.3201 0.529542 1.0399 0.689943 0.854499C0.850344 0.669096 1.12898 0.656185 1.31432 0.811349L12.652 10.7005C13.2178 11.1919 14.1699 11.1919 14.7348 10.7005L26.0587 0.827682C26.2441 0.668986 26.5201 0.68543 26.6839 0.870833C26.8434 1.05624 26.8262 1.33213 26.6408 1.49248L15.3169 11.3635C14.8686 11.7568 14.2829 11.9508 13.6948 11.9508Z" fill="white" />
                            </svg>
                        </div>
                    </div>
                    </a>
                    <a href="tel:+97238000133" target="_blank">
                        <div className={styles.item_left}>
                            <div className={styles.icon_text}>
                                03-8000133
                            </div>
                            <div className={styles.icon}>
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.8215 18.1119C19.9268 17.229 18.8099 17.229 17.9208 18.1119C17.2426 18.7843 16.5644 19.4564 15.8977 20.1402C15.7151 20.3283 15.5613 20.368 15.3392 20.2426C14.9004 20.0034 14.4328 19.8097 14.0113 19.5477C12.0449 18.3114 10.3978 16.7217 8.93909 14.9327C8.21531 14.0439 7.5711 13.0925 7.12087 12.0214C7.0299 11.805 7.04685 11.6626 7.22352 11.4859C7.90171 10.8305 8.56288 10.1583 9.22967 9.48593C10.1585 8.55148 10.1585 7.45765 9.22404 6.51757C8.69396 5.98209 8.16387 5.45792 7.634 4.92244C7.08689 4.37548 6.54541 3.82269 5.99245 3.28136C5.09784 2.4098 3.98082 2.4098 3.0917 3.2872C2.40774 3.95957 1.75242 4.64896 1.05727 5.30965C0.413063 5.91948 0.0882871 6.66585 0.019827 7.53748C-0.0883841 8.95629 0.259188 10.2949 0.749522 11.5997C1.75242 14.3003 3.27984 16.6989 5.13204 18.8981C7.634 21.8723 10.6202 24.2252 14.1136 25.9231C15.6867 26.6865 17.3166 27.2734 19.0889 27.3702C20.3086 27.4387 21.3685 27.131 22.2179 26.1795C22.7992 25.53 23.4545 24.9374 24.07 24.3165C24.982 23.3934 24.9875 22.2767 24.0814 21.3651C22.9988 20.2769 21.9102 19.1943 20.8215 18.1119Z" fill="white" />
                                    <path d="M19.7332 13.5712L21.8361 13.2123C21.5055 11.2809 20.5937 9.53189 19.2087 8.14173C17.7441 6.67764 15.8919 5.7546 13.8516 5.46973L13.5552 7.58339C15.1339 7.80566 16.5701 8.51783 17.7043 9.65147C18.7757 10.7225 19.4766 12.0787 19.7332 13.5712Z" fill="white" />
                                    <path d="M23.0217 4.43232C20.5937 2.00543 17.5219 0.472872 14.1311 0L13.8347 2.11361C16.7641 2.52396 19.4197 3.8512 21.5171 5.94231C23.5059 7.93051 24.811 10.4431 25.2841 13.2063L27.387 12.8472C26.8344 9.64553 25.3241 6.73985 23.0217 4.43232Z" fill="white" />
                                </svg>
                            </div>
                        </div>
                    </a>
                    <a href="https://wa.me/97238000144" target="_blank" >
                        <div className={styles.item_left}>
                            <div className={styles.icon_text}>
                                03-8000144
                            </div>
                            <div className={styles.icon}>
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 27.3788L1.96357 20.0556C0.852088 18.0498 0.267173 15.804 0.267173 13.5223C0.267173 6.0675 6.33506 0 13.7934 0C21.2518 0 27.3197 6.0675 27.3197 13.5223C27.3197 20.9797 21.2518 27.0458 13.7934 27.0458C11.554 27.0458 9.34327 26.4805 7.36397 25.407L0 27.3788ZM7.73016 22.6627L8.19377 22.939C9.89601 23.9534 11.8327 24.4903 13.7936 24.4903C19.8422 24.4903 24.7636 19.5703 24.7636 13.5223C24.7636 7.47545 19.8422 2.55675 13.7936 2.55675C7.74501 2.55675 2.82415 7.47545 2.82415 13.5223C2.82415 15.5203 3.37865 17.4859 4.42824 19.2088L4.71292 19.6762L3.61683 23.7652L7.73016 22.6627Z" fill="#52B95C" />
                                    <path d="M18.8247 15.4035C18.2695 15.0705 17.544 14.6989 16.8882 14.9673C16.3846 15.1723 16.0646 15.9596 15.7378 16.3627C15.5712 16.5691 15.3698 16.6015 15.1143 16.497C13.2247 15.7457 11.7776 14.4847 10.7356 12.7476C10.5588 12.4778 10.5904 12.2648 10.8035 12.0145C11.1184 11.644 11.5136 11.2233 11.5988 10.7238C11.6841 10.2242 11.4498 9.64089 11.2431 9.19561C10.9791 8.62748 10.684 7.817 10.1148 7.49582C9.59124 7.20026 8.90129 7.36554 8.43502 7.74488C7.63014 8.40051 7.2415 9.42665 7.2537 10.4448C7.25688 10.734 7.29242 11.023 7.35961 11.302C7.52228 11.9732 7.83278 12.6003 8.18199 13.1953C8.44545 13.6457 8.73137 14.0808 9.04045 14.5002C10.0508 15.8723 11.3076 17.0648 12.7627 17.9568C13.4899 18.402 14.2732 18.7918 15.0846 19.0601C15.9947 19.361 16.8044 19.6733 17.7868 19.4861C18.8146 19.291 19.8292 18.655 20.237 17.6647C20.3584 17.3704 20.4175 17.0439 20.3508 16.734C20.2111 16.0942 19.3437 15.7132 18.8247 15.4035Z" fill="#52B95C" />
                                </svg>
                            </div>
                        </div>
                    </a>
                </div>

            </div>
            <div className={styles.botton_footer}>
                <div className={styles.botton_footer_right}>
                    כל הזכויות שמורות לדפוס רימון און ליין
                </div>
                <div className={styles.botton_footer_left}>
                    <div className={styles.botton_footer_payment}>
                        האתר מאובטח באמצעות SLL
                    </div>
                    <img src={paymentFacilities} />
                </div>

            </div>
        </div >
    );
};


const mapStateToProps = state => {
    return {
    webmaster: state.webmaster,
    }
}
export default connect(mapStateToProps, {})(Footer);