const {
    REQUEST_ALL_DATA_DELIVERIES,
} = require('../actions/deliveriesDatabase');

const defaultState = {};

const variable = (state = defaultState, action) => {
    switch (action.type) {
        case REQUEST_ALL_DATA_DELIVERIES:
            return action.payload;
        default:
            return state;

    }
}

export default variable;