import React, { useState, useEffect } from 'react';
import styles from "./PersonalDetailsPayment.module.scss";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

const PersonalDetailsPayment = ({
    userData,
    setAccountDetails,
    accountDetails,
    mailErr,
    setMailErr,
}) => {

    const history = useHistory();

    const [businessNumberErr, setBusinessNumberErr] = useState('');
    const [postalCodeErr, setPostalCodeErr] = useState('');

    const nameChange = e => {
        const mewDetails = { ...accountDetails };
        mewDetails['name'] = e.currentTarget.value;
        setAccountDetails(mewDetails);
    };
    const lastNameChange = e => {
        const mewDetails = { ...accountDetails };
        mewDetails['lastName'] = e.currentTarget.value;
        setAccountDetails(mewDetails);
    };
    const phoneChange = e => {
        const mewDetails = { ...accountDetails };
        mewDetails['phone'] = e.currentTarget.value;
        setAccountDetails(mewDetails);
    };
    const mailChange = e => {
        const mewDetails = { ...accountDetails };
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(e.currentTarget.value)) {
            setMailErr('האמייל לא תקין');
            mewDetails['mail'] = e.currentTarget.value;
            setAccountDetails(mewDetails);
        } else {
            setMailErr('');
            mewDetails['mail'] = e.currentTarget.value;
            setAccountDetails(mewDetails);
        };
    };
    const addressChange = e => {
        const mewDetails = { ...accountDetails };
        mewDetails['address'] = e.currentTarget.value;
        setAccountDetails(mewDetails);
    };
    const businessNumberChange = e => {
        const mewDetails = { ...accountDetails };
        if (e.currentTarget.value.length !== 9) {
            setBusinessNumberErr('שים לב! המספר הייב להיות 9 ספרות.');
        } else {
            setBusinessNumberErr('');
        }
        mewDetails['businessNumber'] = e.currentTarget.value;
        setAccountDetails(mewDetails);
    };
    const postalCodeChange = e => {
        const mewDetails = { ...accountDetails };
        if (e.currentTarget.value.length !== 7) {
            setPostalCodeErr('שים לב! המספר הייב להיות 7 ספרות.');
        } else {
            setPostalCodeErr('');
        }
        mewDetails['postalCode'] = e.currentTarget.value;
        setAccountDetails(mewDetails);
    };
    const companyNameChange = e => {
        const mewDetails = { ...accountDetails };
        mewDetails['companyName'] = e.currentTarget.value;
        setAccountDetails(mewDetails);
    };
    const moreInfoChange = e => {
        const mewDetails = { ...accountDetails };
        mewDetails['moreInfo'] = e.currentTarget.value;
        setAccountDetails(mewDetails);
    };

    return (
        <div className={styles.continer}>
            <div className={styles.login_question}>
                <p>קנית כאן בעבר? <span onClick={() => history.push("/login")}>לחץ כאן כדי להתחבר</span></p>
            </div>
            <div className={styles.title}>
                השלימו את הפרטים שלכם:
            </div>
            <div className={styles.contining_customer_details}>
                <div className={styles.two_box_details}>
                    <div className={styles.box_input_for_two}>
                        <label >שם פרטי*</label>
                        <input type="text" onChange={nameChange} placeholder="הכנס את שם הפרטי" value={accountDetails.name} />
                    </div>
                    <div className={styles.box_input_for_two}>
                        <label >שם משפחה*</label>
                        <input type="text" onChange={lastNameChange} placeholder="הכנס את שם המשפחה" value={accountDetails.lastName} />
                    </div>
                </div>
                <div className={styles.two_box_details}>
                    <div className={styles.box_input_for_two}>
                        <label >מס' טלפון*</label>
                        <input type="number" onChange={phoneChange} placeholder="הכנס את המס' טלפון" value={accountDetails.phone} />
                    </div>
                    <div className={styles.box_input_for_two}>
                        {userData.isLogin ?
                            <>
                                <label >אמייל</label>
                                <input readOnly="readOnly" type="text" placeholder="הכנס את האמייל" value={accountDetails.mail} />
                            </>
                            :
                            <>
                                <label >אמייל*</label>
                                {mailErr ? <div className={styles.err_message}>{mailErr}</div> : ''}
                                <input type="text" onChange={mailChange} placeholder="הכנס את האמייל" value={accountDetails.mail} />
                            </>
                        }
                    </div>
                </div>
                <div className={styles.two_box_details}>
                    <div className={styles.box_input_for_two}>
                        <label >כתובת</label>
                        <input type="text" onChange={addressChange} placeholder="הכנס את הכתובת" value={accountDetails.address} />
                    </div>
                    <div className={styles.box_input_for_two}>
                        <label >מיקוד</label>
                        {postalCodeErr ? <div className={styles.err_message}>{postalCodeErr}</div> : ''}
                        <input type="number" onChange={postalCodeChange} placeholder="הכנס את המס' בן 7 ספרות" value={accountDetails.postalCode} />
                    </div>
                </div>
                <div className={styles.two_box_details}>
                    <div className={styles.box_input_for_two}>
                        <label >מס' עוסק / עמותה / ת.ז.</label>
                        {businessNumberErr ? <div className={styles.err_message}>{businessNumberErr}</div> : ''}
                        <input type="number" onChange={businessNumberChange} placeholder="הכנס את המס' בן 9 ספרות" value={accountDetails.businessNumber} />
                    </div>
                    <div className={styles.box_input_for_two}>
                        <label >שם החברה</label>
                        <input type="text" onChange={companyNameChange} placeholder="הכנס את שם החברה" value={accountDetails.companyName} />
                    </div>
                </div>
                <label >פרטים נוספים</label>
                <textarea type="text" onChange={moreInfoChange} placeholder="הכנס פרטים נוספים" value={accountDetails.moreInfo} />
            </div>
        </div>
    );
};


const mapStateToProps = state => {
    return {
        userData: state.users,

    }
}
export default connect(mapStateToProps, {})(PersonalDetailsPayment);

