const {
    CHANGE_UPLOADING_STATUS,
    CHANGE_PERCENTAGE_UPLOADING,
    FINISH_PERCENTAGE_UPLOADING,
} = require('../../actions/uploadingFile/uploadingFile');
const _ = require('lodash');

const defaultState = {
    status: false,
    uploadingFileArr: [],
    // uploadingFileArr: [{numOrder: 'SAL-2022-23-8', percentage: 23, title: 'מעלה קובץ של מספר הזמנה:'}],
    effect: 0,
};

const variable = (state = defaultState, action) => {
    switch (action.type) {
        case CHANGE_UPLOADING_STATUS:
            const newStateA = { ...state };
            newStateA.status = action.payload;
            return newStateA;
        case CHANGE_PERCENTAGE_UPLOADING:
            const newStateB = { ...state };
            let isExist = false;
            newStateB.uploadingFileArr.map(item => {
                if (item.numOrder === action.payload.numOrder) {
                    isExist = true;
                }
            });
            if (!isExist) {
                newStateB.uploadingFileArr.push({ 
                    numOrder: action.payload.numOrder,
                     percentage: action.payload.percentage,
                     title: action.payload.title,
                     });
            } else {
                newStateB.uploadingFileArr.map(item => {
                    if (item.numOrder === action.payload.numOrder) {
                        item.percentage = action.payload.percentage;
                        item.effect = action.payload.percentage;
                    }
                });
            }
            return newStateB;
        case FINISH_PERCENTAGE_UPLOADING:
            const newStateC = _.cloneDeep(state);
            const newArr = [];
            newStateC.uploadingFileArr.map(item => {
                if (item.numOrder !== action.payload.numOrder) {
                    newArr.push(item);
                }
            });
            newStateC.uploadingFileArr = newArr;
            if (newStateC.uploadingFileArr.length === 0) newStateC.status = false;
            return newStateC;
        default:
            return state;

    }
}

export default variable;