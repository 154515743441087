import React, { useState, useEffect } from 'react';
import styles from "./Login.module.scss";
import { connect } from 'react-redux';
import { MDBIcon } from 'mdbreact';
import { Link, useHistory } from "react-router-dom";
import { LoginFun, variableCleaning, changeValueUser, LoginFunGoogle } from "../../../actions/RequestUserDatabase";
import Cookies from 'universal-cookie';
import GoogleLogin from 'react-google-login';
import googelLogo from './googel-logo.png';

const CryptoJS = require("crypto-js");



const Login = props => {

    const history = useHistory();

    const usersData = props.users;

    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [password, setPassword] = useState('');
    const [passwordErr, setPasswordErr] = useState('');
    useEffect(() => {
        if (usersData.type === 'mailIsNotExisist') {
            setEmailErr('המייל זה לא קיים במערכת! הקלד מייל תקין. ');
        };
        if (usersData.type === 'invalidPassword') {
            setPasswordErr('הסיסמה לא נכונה!');
        };
        if (usersData.type === 'succeeded') {
            history.push("/");
            props.variableCleaning('login');
            props.changeValueUser('isLogin', true);
            const cookies = new Cookies();
            cookies.set('mailCrypto', CryptoJS.AES.encrypt(email.trim().toLowerCase(), process.env.REACT_APP_SECRET_KEY).toString(), { path: '/' });
            cookies.set('passwordCrypto', CryptoJS.AES.encrypt(password.trim(), process.env.REACT_APP_SECRET_KEY).toString(), { path: '/' });

        };
    }, [usersData]);

    const handleSudmit = () => {
        if (emailErr, passwordErr) return;
        props.LoginFun({
            mail: email.trim().toLowerCase(),
            password: password.trim()
        })
    };
    const emailChange = (e) => {
        setEmailErr('');
        setEmail(e.currentTarget.value);
    };
    const passwordChange = (e) => {
        setPasswordErr('');
        setPassword(e.currentTarget.value);
    };
    const responseGoogle = (result) => {
        props.LoginFunGoogle(result)
    };
    const responseGoogleOnFailure = (result) => {
        alert('החיבור נכשל נסה מאוחר יותר')
    };
    return (
        <div className={styles.continer}>
            <div className={styles.box}>
                <h2>על מנת לגשת לחשבונך מלא את פרטיך כאן</h2>
                
                <div className={styles.contining_properties_elements}>
                    <input onChange={emailChange} value={email} type="email" placeholder="מייל" />
                    {emailErr ? <div className={styles.err_message}>{emailErr}</div> : ''}
                </div>
                <div className={styles.contining_properties_elements}>
                    <input onChange={passwordChange} value={password} type="password" placeholder="סיסמה"></input>
                    {passwordErr ? <div className={styles.err_message}>{passwordErr}</div> : ''}
                </div>
                <div onClick={() => history.push("/reset-password")} className={styles.resetPassword}>שכחתי סיסמא...</div>
                <div onClick={() => handleSudmit()} className={styles.login_but}>התחבר</div>
                <div className={styles.googel_but}>
                    <GoogleLogin
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        onSuccess={responseGoogle}
                        onFailure={responseGoogleOnFailure}
                        cookiePolicy={'single_host_origin'}
                    >
                        <div className={styles.googel_but_in}>
                            <img src={googelLogo} />
                            <p>התחבר עם גוגל</p>
                        </div>
                    </GoogleLogin>

                </div>
                <div className={styles.title_but}>
                    אין לך עדיין חשבון?
                </div>
                <Link to="/sign-up">
                    <div onClick={() => props.variableCleaning('login')} className={styles.other_but}>צור חשבון</div>
                </Link>
            </div>
        </div>
    );
};


const mapStateToProps = state => {
    return {
        allData: state.allCalculatorDatabase,
        webmaster: state.webmaster,
        users: state.users.login,
    }
}
export default connect(mapStateToProps, { LoginFun, variableCleaning, changeValueUser, LoginFunGoogle })(Login);
