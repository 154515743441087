import React, { useEffect, useState } from 'react';
import styles from "./FilesHendler.module.scss";
import { connect } from 'react-redux';

const FilesHendler = props => {

    const [arrNum, setArrNum] = useState([]);
    const [howManyFiles, setHowManyFiles] = useState([]);
    const isJson = str => {
        try {
            JSON.parse(str);
            if (JSON.parse(str) === null) return false;
        } catch (e) {
            return false;
        }
        return true;
    };
    useEffect(() => {
        const arr = []
        console.log(props.howManyFiles);
        if (isJson(props.howManyFiles)) {
            JSON.parse(props.howManyFiles).map(item => {
                arr.push(item);
            });
        }
        setArrNum(arr);
        props.setArrFiles(arr.map(() => false));
        props.setArrFilesErr(arr.map(() => false));
        setHowManyFiles(JSON.parse(props.howManyFiles));
    }, [props.howManyFiles]);

    if (howManyFiles.length === 0) return null;

    const onChangeSelectedFile = (objFile, index) => {
        if (!objFile) return; 
        const newArrFilesErr = props.arrFilesErr;
        const maxAllowedSize = 2000000000;
        const newArrFiles = [...props.arrFiles];

        if (objFile.size > maxAllowedSize) {
            const fileSize = objFile.size / 1000000000;
            newArrFilesErr[index] = `גודל הקובץ ${fileSize.toFixed(2)} ג'יגה נא לעלות קובץ מינימום 2 ג'יגה! `;
            props.setArrFiles(newArrFiles)
            props.setArrFilesErr(newArrFilesErr);
            return null;
        }

        newArrFilesErr[index] = false;
        newArrFiles[index] = objFile;
        props.setArrFiles(newArrFiles)
        props.setArrFilesErr(newArrFilesErr);
    };

    const inputFilesHendler = () => {
        return arrNum.map((item, index) => (
            <div className={styles.file_box} key={index}>
                {props.arrFilesErr[index] && <div className={styles.err}>{props.arrFilesErr[index]}</div>}
                <label htmlFor={"inputTag" + index}>
                    <div className={styles.file_title} >{item}:</div>
                    {props.arrFiles[index] ? props.arrFiles[index].name : 'העלה קובץ PDF'}
                    <input id={"inputTag" + index} onChange={(e) => onChangeSelectedFile(e.target.files[0], index)} type="file" accept="application/pdf" />
                </label>
            </div>
        ));
    };

    // inputFilesHendler()
    return (
        <div className={styles.continer}>
            {inputFilesHendler()}
        </div >
    );
};


const mapStateToProps = state => {
    return {
        // controlFilesHendlerScreen: state.controlFilesHendlerScreen,
    }
}
export default connect(mapStateToProps, {})(FilesHendler);

