import React from 'react';
import styles from "./Inspiration.module.scss";
import { connect } from 'react-redux';


const Inspiration = ({}) => {



    return (
        <div className={styles.continer}>
            <h1>השראה בפיתוח</h1>

        </div >
    );
};


const mapStateToProps = state => {
    return {}
}
export default connect(mapStateToProps, {})(Inspiration);