import React, { useEffect, useRef } from 'react';
import styles from "./CategoriesWindow.module.scss";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";

const CategoriesWindow = props => {

    const history = useHistory();
    const allData = props.allData;
    const continerRef = useRef(null);
    const isManager = props.webmaster.isManager;

    useEffect(() => {
        if (props.openCategories) {
            continerRef.current.style.transform = 'translateY(0px)';
        } else {
            continerRef.current.style.transform = 'translateY(-100vh)';
        }
    }, [props.openCategories]);

    const onClickCategory = data => {
        closeCategories(false);
        history.push({
            pathname: '/categories',
            id: data.id,
            type: 'category',
            tabId: data.associationsTabTopCategories.tabTopId,
        });
    };

    const onClickTab = data => {
        closeCategories(false);
        history.push({
            pathname: '/categories',
            id: data.id,
            type: 'tab',
        });
    };

    const closeCategories = () => {
        if (props.openCategories) {
            continerRef.current.style.transform = 'translateY(-100vh)';
        };
        setTimeout(() => {
            props.setOpenCategories(false);
        }, 1000);
    }

    const titleTopFun = allData.tabTopData
        .filter(item => {
            if (isManager || item.activeStatus === 'כן') {
                return true;
            } else { return false };
        })
        .sort(({ tabLocation: a }, { tabLocation: b }) => a - b)
        .map((item, index) => (
            <div key={index} className={styles.box_item}>
                <div className={styles.title} onClick={() => onClickTab(item)}>{item.titleForCustomer}</div>
                {Object.keys(item.categoriesProductTypes)
                    .map(key => item.categoriesProductTypes[key])
                    .filter(item => {
                        if (isManager || item.activeStatus === 'כן') {
                            return true;
                        } else { return false };
                    })
                    .sort(({ tabLocation: a }, { tabLocation: b }) => a - b)
                    .map((itemB, index) => (
                        <div key={index} className={styles.category_tab} onClick={() => onClickCategory(itemB)}>
                            {itemB.titleForCustomer}
                        </div>
                    ))}
            </div>
        ))
    return (
        <div className={styles.box_continer}>
            <div ref={continerRef} className={styles.continer} onMouseLeave={() => closeCategories(false)}>
                {titleTopFun}
            </div>
        </div>

    );
};


const mapStateToProps = state => {
    return {
        allData: state.allCalculatorDatabase,
        webmaster: state.webmaster,
    }
}
export default connect(mapStateToProps, {
    //  requestAllData, changeScreensValues, changeScreens, changeValueUser, clearUserCartFun 
})(CategoriesWindow);
