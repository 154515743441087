const {
    SET_DATA_PAYMENT,
} = require('../actions/RequestUserDatabase')

const defaultState = {

};

const variable = (state = defaultState, action) => {
    switch (action.type) {
        case SET_DATA_PAYMENT:
            return action.payload;
        default:
            return state;
    }
}

export default variable;