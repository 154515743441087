import React from 'react';
import styles from "./MessageSucceeded.module.css";
import { connect } from 'react-redux';
import { MDBIcon } from 'mdbreact';
import { runMessageSucceeded } from '../../../actions/Loading';


const MessageSucceeded = props => {
    const data = props.data.messageSucceededData;
    return (
        <div className={styles.continer}>
            <div className={styles.div_succeeded}>
                <MDBIcon className={styles.check_double} icon="check-double" size="5x" />
                <br />
                <h2>{data}</h2>
                <div className={styles.button_for_succeeded} onClick={() => props.runMessageSucceeded(false, '')}>סגור</div >
            </div>
        </div>
    );
};


const mapStateToProps = state => {
    return {
        data: state.loading,
    }
}
export default connect(mapStateToProps, { runMessageSucceeded })(MessageSucceeded);

