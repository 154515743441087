const {
    ADD_USER_ORDERS,
} = require('../actions/RequestUserDatabase')

const defaultState = {

};

const variable = (state = defaultState, action) => {
    switch (action.type) {
        case ADD_USER_ORDERS:
            return action.payload;
        default:
            return state;
    }
}

export default variable;