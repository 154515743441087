import React from 'react';
import styles from "./CustomersRecommend.module.scss";
import { connect } from 'react-redux';
import SlideshowWithPagination from "react-slideshow-with-pagination";

import logo1 from './imges/1.png';
import logo2 from './imges/2.jpg';
import logo3 from './imges/3.jpg';
import logo4 from './imges/4.jpg';
import logo5 from './imges/5.jpg';
import logo6 from './imges/6.png';
import logo7 from './imges/7.jpg';
import logo8 from './imges/8.png';
import logo9 from './imges/9.png';
import logo10 from './imges/10.png';
import logo11 from './imges/11.png';
import logo12 from './imges/12.png';
import logo13 from './imges/13.png';
import logo14 from './imges/14.png';
import logo15 from './imges/15.jpg';
import logo16 from './imges/16.jpg';
import logo17 from './imges/17.jpg';
import logo18 from './imges/18.png';
import logo19 from './imges/19.jpg';

const CARDS_DETAILS = [
    { image: logo1 },
    { image: logo2 },
    { image: logo3 },
    { image: logo4 },
    { image: logo5 },
    { image: logo6 },
    { image: logo7 },
    { image: logo8 },
    { image: logo9 },
    { image: logo10 },
    { image: logo11 },
    { image: logo12 },
    { image: logo13 },
    { image: logo14 },
    { image: logo15 },
    { image: logo16 },
    { image: logo17 },
    { image: logo18 },
    { image: logo19 },
];

const springConfig = { duration: '2s', easeFunction: 'ease-in-out', delay: '0s' };


const CustomersRecommend = props => {
    return (
        <div className={styles.continer} dir='ltr'>
        <div className={styles.title}>בין לקוחותינו</div >

            <SlideshowWithPagination
                options={CARDS_DETAILS}
                showNumbers={false}
                showDots={true}
                showArrows={true}
                numberOfCardsPerScreen={7}
                showOneCardForWidthLower="sm"
                slideshowContainerMaxWidth={false}
                cardWidth={120}
                cardHeight={120}
                springConfig={springConfig}
                interval={8000}
            />

        </div >
    );
};


const mapStateToProps = state => {
    return {}
}
export default connect(mapStateToProps, {})(CustomersRecommend);