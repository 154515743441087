import React from 'react';
import styles from "./Body.module.css";
import { connect } from 'react-redux';
import HomePage from './components/HomePage/HomePage';
// import CalculatorProductsScreen from './components/CalculatorProductsScreen/CalculatorProductsScreen';
// import ProductSKUScreen from './components/ProductSKUScreen/ProductSKUScreen';
// import ModelsSKUScreen from './components/ProductSKUScreen/components/ModelsSKUScreen/ModelsSKUScreen';


const Body = props => {
    const controlBodyScreen = props.controlBodyScreen;
    return (
        <div className={styles.continer}>
            {controlBodyScreen.HomePage && <HomePage />}
            {/* {controlBodyScreen.CalculatorProductsScreen && <CalculatorProductsScreen />} */}
            {/* {controlBodyScreen.ProductSKUScreen && <ProductSKUScreen />} */}
            {/* {controlBodyScreen.ModelsSKUScreen && <ModelsSKUScreen />} */}
        </div >
    );
};


const mapStateToProps = state => {
    return {
        controlBodyScreen: state.controlBodyScreen,
    }
}
export default connect(mapStateToProps, {})(Body);

