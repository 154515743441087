import React, { useState } from 'react';
import styles from "./StubMode.module.scss";
import { connect } from 'react-redux';

const StubMode = props => {

    const [isMode, setIsMode] = useState(localStorage.getItem('StubMode') === 'yes' ? true : false);

    const onChangeStubMode = () => {
        setIsMode(!isMode);
        if (isMode) {
            localStorage.setItem('StubMode', 'no');
            window.location.reload()
        } else {
            localStorage.setItem('StubMode', 'yes');
            window.location.reload()
        }
    };
    
    return (
        <div className={styles.continer}>
            <input type='checkbox' checked={isMode} onChange={() => onChangeStubMode()} />
            <div className={styles.text}>
                Stub Mode
            </div>
        </div>
    );
};


const mapStateToProps = state => {
    return {
        popupHeandlerData: state.popupHeandlerData,
    }
}
export default connect(mapStateToProps, {  })(StubMode);

