
export const calculatorBySingle = (sumPriceObj, allProperties, quantity) => {
    Object.keys(allProperties).map(key => {
        const item = allProperties[key];
        if (item.parameterValues.type === 'calculatorBySingle') {
            let price = item.parameterValues.productPrice * quantity;
            if (price < item.parameterValues.minimumPrice) price = item.parameterValues.minimumPrice;
            sumPriceObj[key] = price;
        };
    });
    return sumPriceObj;
};
export const calculatorByQuantities = (sumPriceObj, allProperties, quantity) => {
    quantity = quantity * 1;
    Object.keys(allProperties).map(key => {
        const item = allProperties[key];
        if (item.parameterValues.type === 'calculatorByQuantities') {
            const priceByQuantities = JSON.parse(item.parameterValues.productPriceByQuantities);
            let priceForPricing = 0;
            let quantityForPricing = 0;
            priceByQuantities.map(item => {
                if (quantity >= item.quantity * 1 && quantityForPricing < item.quantity * 1) {
                    priceForPricing = item.price * 1;
                    quantityForPricing = item.quantity * 1;
                };
            });
            if (priceForPricing === 0) priceByQuantities.map(item => {
                if (quantityForPricing > item.quantity * 1 || quantityForPricing === 0) {
                    priceForPricing = item.price * 1;
                    quantityForPricing = item.quantity * 1;
                };
            });;
            priceForPricing = priceForPricing * quantity;
            if (priceForPricing < item.parameterValues.minimumPrice) priceForPricing = item.parameterValues.minimumPrice;
            sumPriceObj[key] = priceForPricing;
        };
    });
    return sumPriceObj;
};
export const calculatorByPaper = (sumPriceObj, allProperties, quantity, widthSize, heightSize) => {
    quantity = quantity * 1;
    Object.keys(allProperties).map(key => {
        const item = allProperties[key];
        if (item.parameterValues.type === 'calculatorByPaper') {
            const ListWeight = JSON.parse(item.parameterValues.PaperPriceListWeight);
            let weight = '';
            let priceWeight = 0;
            ListWeight.map(listItem => {
                if (listItem.paperWeight === item.selectePaperWeightValue) {
                    weight = listItem.paperWeight;
                    priceWeight = listItem.price;
                }
            });
            let color = '';
            let priceColor = 0;
            item.colorPrintingMethod.map(listItem => {
                if (listItem.color === item.colorPrintingMethodValue) {
                    color = listItem.color;
                    priceColor = listItem.price;
                }
            });
            const quantitisInSheetColor = calculatorHowMuchGoesIntoPage(item.propertyValues.calculationByHeightSizeColor, item.propertyValues.calculationByWidthSizeColor, heightSize * 1, widthSize * 1, item.propertyValues.gater);
            let priceColorResult = 0;
            let numberPages = (item.numberPagesValue * 1);
            priceColorResult = Math.ceil((numberPages * quantity) / quantitisInSheetColor) * priceColor;
            sumPriceObj[`שיטת הדפסת ל${key}`] = priceColorResult.toFixed(2);

            const quantitisInSheetPaper = calculatorHowMuchGoesIntoPage(item.parameterValues.calculationByHeightSize, item.parameterValues.calculationByWidthSize, heightSize * 1, widthSize * 1, item.parameterValues.gater);
            let priceWeightResult = 0;
            let numberPagesForPaper = item.printingSidesValue === "דו צדדי" ? (item.numberPagesValue * 1) / 2 : item.numberPagesValue * 1;
            numberPagesForPaper = Math.ceil(numberPagesForPaper);
            priceWeightResult = Math.ceil((numberPagesForPaper * quantity) / quantitisInSheetPaper) * priceWeight;
            if (item.parameterValues.minimumPrice > priceWeightResult.toFixed(2)) priceWeightResult = item.parameterValues.minimumPrice;
            sumPriceObj[key] = priceWeightResult.toFixed(2);
        };
    });
    return sumPriceObj;
};
export const calculatorByCut = (priceSingleCut, allProperties, widthSize, heightSize) => {
    let sizeForCuttingHeight = 0;
    let sizeForCuttingWidth = 0;
    let gater = 0;
    Object.keys(allProperties).map(key => {
        const item = allProperties[key];
        if (item.parameterValues.type === 'calculatorByPaper') {
            if (gater === 0) gater = item.propertyValues.gater;
            if (gater > item.propertyValues.gater) gater = item.propertyValues.gater;
            if (sizeForCuttingHeight < item.parameterValues.calculationByHeightSize) sizeForCuttingHeight = item.parameterValues.calculationByHeightSize;
            if (sizeForCuttingWidth < item.parameterValues.calculationByWidthSize) sizeForCuttingWidth = item.parameterValues.calculationByWidthSize;
        };
    });
    const quantitisInSheet = calculatorHowMuchGoesIntoPage(sizeForCuttingHeight, sizeForCuttingWidth, heightSize * 1, widthSize * 1, gater);
    return quantitisInSheet * priceSingleCut;
};
const calculatorHowMuchGoesIntoPage = (sheetHeight, sheetWidth, singleHeight, singleWidth, gater) => {
    const resultA = calculatorImposing(sheetHeight, sheetWidth, singleHeight, singleWidth, gater);
    const resultB = calculatorImposing(sheetWidth, sheetHeight, singleHeight, singleWidth, gater);
    if (resultA > resultB) {
        return resultA;
    } else {
        return resultB;
    };
};
const calculatorImposing = (sheetHeight, sheetWidth, singleHeight, singleWidth, gater) => {
    const imposingHeight = Math.floor(sheetHeight / (singleHeight + gater));
    const imposingWidth = Math.floor(sheetWidth / (singleWidth + gater));
    return imposingHeight * imposingWidth;
};


