const {
    UPLOAD_FILE_TO_LOCAL_STORAGE,
} = require('../../actions/uploadingFile/uploadingFileLocalStorage');

const defaultState = {
    uploadingFileArr: [],
    // uploadingFileArr: [{fileID: 'd56dejhd65', file: {}}],
};

const variable = (state = defaultState, action) => {
    switch (action.type) {
        case UPLOAD_FILE_TO_LOCAL_STORAGE:
            const newStateA = { ...state };
            newStateA.uploadingFileArr.push({fileID: action.payload.fileID, file: action.payload.file});
            return newStateA;
       
        default:
            return state;

    }
}

export default variable;