import React, { useEffect, useState } from 'react';
import styles from "./DisplayTabs.module.scss";
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { changeLocationPath } from '../../../../actions/locationPath';
import LocationPath from '../../../components/LocationPath/LocationPath';


const DisplayTabs = props => {

    const history = useHistory();
    const location = useLocation();
    const locationPathData = props.locationPathData;
    const isManager = props.webmaster.isManager;

    const [itemsData, setItemsData] = useState([]);
    const [type, setType] = useState('');
    const [headerTitle, setHeaderTitle] = useState('');
    const [selector, setSelector] = useState({ img: '', text: '' });

    useEffect(() => {
        if (location.type === 'tab') {
            const tabData = props.allData.tabTopData.find(element => element.id == location.id);
            setHeaderTitle(tabData.titleForCustomer)
            setItemsData(tabData.categoriesProductTypes);
            setType('tab');
            setSelector({ img: 'imgUrl', text: 'titleForCustomer' })
            props.changeLocationPath({ key: 'tab', value: { name: tabData.titleForCustomer, id: tabData.id } });
            props.changeLocationPath({ key: 'category', value: { name: '', id: '' } })
            props.changeLocationPath({ key: 'product', value: { name: '', id: '' } })
            props.changeLocationPath({ key: 'categorySku', value: { name: '', id: '' } })
            props.changeLocationPath({ key: 'productSku', value: { name: '', id: '' } });
        }
        if (location.type === 'category') {
            let tabData = { titleForCustomer: '', id: '' };
            if (location.tabId) tabData = props.allData.tabTopData.find(element => element.id == location.tabId);
            const categoryData = props.allData.categoriesData.find(element => element.id == location.id);
            setHeaderTitle(categoryData.titleForCustomer);
            setItemsData([...categoryData.calculatorProducts, ...categoryData.regularProducts]);
            setType('category')
            setSelector({ img: 'Image', text: 'titleForCustomer' })
            props.changeLocationPath({ key: 'tab', value: { name: tabData.titleForCustomer, id: tabData.id } })
            props.changeLocationPath({ key: 'category', value: { name: categoryData.titleForCustomer, id: categoryData.id } });
            props.changeLocationPath({ key: 'product', value: { name: '', id: '' } })
            props.changeLocationPath({ key: 'categorySku', value: { name: '', id: '' } })
            props.changeLocationPath({ key: 'productSku', value: { name: '', id: '' } });
        }
        if (location.type === 'category-sku') {
            let tabData = { titleForCustomer: '', id: '' };
            if (location.tabId) tabData = props.allData.tabTopData.find(element => element.id == location.tabId);
            props.changeLocationPath({ key: 'tab', value: { name: tabData.titleForCustomer, id: tabData.id } })
            let categoryData = { titleForCustomer: '', id: '' };
            if (location.categoryId) categoryData = props.allData.categoriesData.find(element => element.id == location.categoryId);
            props.changeLocationPath({ key: 'category', value: { name: categoryData.titleForCustomer, id: categoryData.id } });
            const regularProductData = props.allData.regularProductData.find(element => element.id == location.id);
            setHeaderTitle(regularProductData.titleForCustomer);
            setItemsData(regularProductData.productSKUs);
            setType('category-sku');
            setSelector({ img: 'image', text: 'titleForCustomer' });
            props.changeLocationPath({ key: 'categorySku', value: { name: regularProductData.titleForCustomer, id: regularProductData.id } });
            props.changeLocationPath({ key: 'product', value: { name: '', id: '' } });
            props.changeLocationPath({ key: 'productSku', value: { name: '', id: '' } });
        }
    }, [location]);

    const onClickItem = ({ id, data }) => {
        if (type === 'tab') {
            history.push({
                pathname: '/categories',
                id: id,
                type: 'category',
                tabId: locationPathData['tab'].id,
            });
        }
        if (type === 'category') {
            if (data.type === 'calculator') {
                history.push({
                    pathname: '/calculator-product',
                    id: id,
                });
            };
            if (data.type === 'regular') {
                history.push({
                    tabId: locationPathData['tab'].id,
                    categoryId: locationPathData['category'].id,
                    pathname: '/categories',
                    id: id,
                    type: 'category-sku',
                });
            };
        }
        if (type === 'category-sku') {
            history.push({
                pathname: '/product-sku',
                id: id,
            });
        }
    };
    return (
        <div className={styles.continer}>
            <div className={styles.continer_top}>
                <LocationPath />
                <div className={styles.buffer} />
                <div className={styles.title}>{headerTitle}</div>
            </div >
            <div className={styles.items_continer}>
                <div className={styles.items_continer_box}>
                    {itemsData
                    .filter(item => {
                        if (isManager || item.activeStatus === 'כן') {
                            return true;
                        } else {
                            return false;
                        }
                    })
                    .map(item => (
                        <div className={styles.box_items_list} onClick={() => onClickItem({ id: item.id, data: item })}>
                            <div className={styles.box_items_list_image}>
                                <img src={item[selector.img]} />
                            </div>
                            <div className={styles.box_items_list_text}>{item[selector.text]}</div>
                        </div>
                    ))
                    }
                </div>
            </div>

        </div >
    );
};


const mapStateToProps = state => {
    return {
        allData: state.allCalculatorDatabase,
        locationPathData: state.locationPathData,
        webmaster: state.webmaster,
    }
}
export default connect(mapStateToProps, { changeLocationPath })(DisplayTabs);