import React, { useState, useEffect, useRef } from 'react';
import styles from "./SideCart.module.scss";
import { connect } from 'react-redux';
import { MDBIcon } from 'mdbreact';
import Cookies from 'universal-cookie';
import { deleteUserCartFun, sedOrderPayment } from '../../../actions/RequestUserDatabase';
import { useHistory } from "react-router-dom";

const CryptoJS = require("crypto-js");

const SideCart = props => {

    const history = useHistory();
    const cookies = new Cookies();
    const userData = props.userData;
    const [notLogin, setNotLogin] = useState(userData.isLogin);

    const carts = { ...props.carts };
    const [cartsCheckbox, setCartsCheckbox] = useState('');
    const [cartsAllCheckbox, setCartsAllCheckbox] = useState('');
    const [price, setPrice] = useState(0);
    const [countCart, setCountCart] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const refSideCart = useRef(null);

    useEffect(() => {
        let newPrice = 0;
        let newCountCart = 0;
        Object.keys(carts).map(key => {
            if (carts[key].checkd) {
                newPrice += (carts[key].price * 1);
                newCountCart++;
            };
        });
        setCountCart(newCountCart);
        setPrice(newPrice);
    }, [cartsCheckbox, cartsAllCheckbox]);


    useEffect(() => {
        if (!isOpen) {
            setIsOpen(props.isOpenHeandler);
        }
    }, [props.isOpenHeandler]);

    useEffect(() => {
        if (isOpen) {
            refSideCart.current.style.transform = "translateX(0px)"
        } else {
            refSideCart.current.style.transform = "translateX(-700px)"
        }
    }, [isOpen]);

    const onClickDeleteCart = (cartId, localStorageId) => {
        let password = '';
        if (notLogin) password = CryptoJS.AES.decrypt(cookies.get('passwordCrypto'), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
        props.deleteUserCartFun({
            localStorageId: localStorageId,
            id: cartId,
            password: password,
            userId: props.userData.userData.id,
        });
    };
    const onChangeCheckboxCart = (key, value) => {
        carts[key].checkd = !value ? '' : true;
        setCartsCheckbox(!cartsCheckbox);
    };
    const onChangeAllCheckboxCart = () => {
        Object.keys(carts).map(key => {
            carts[key].checkd = cartsAllCheckbox ? '' : true;
        });
        setCartsAllCheckbox(cartsAllCheckbox ? '' : true);
    };
    const onClickPayment = () => {
        if (price < 1) {
            alert('לא נבחר מוצר!');
            return;
        };
        const nweData = { ...carts }
        Object.keys(carts).map(key => {
            if (!carts[key].checkd) {
                delete nweData[key];
            }
        });
        props.sedOrderPayment(nweData);
        history.push("/order-payment");
    };
    const divCarts = () => {
        const arrCarts = Object.keys(carts).map((key) => {
            carts[key]['key'] = key;
            return carts[key];
        });

        return arrCarts
            .sort(({ key: a }, { key: b }) => b - a)
            .map((item, index) => (
                <div key={index} className={styles.contining_carts}>
                    <input checked={item.checkd} onChange={() => onChangeCheckboxCart(item.key, !item.checkd)} type="checkbox" />

                    <div className={styles.contining_carts_right}>
                        <div className={styles.contind_img_carts}>
                            <img src={item.productImage} />
                        </div>
                        <div className={styles.trash_and_quantity}>
                            {/* <div className={styles.carts_trash_continer} onClick={() => onClickDeleteCart(item.id, item.localStorageId)}>
                                <MDBIcon className={styles.carts_trash} far icon="trash-alt" size="2x" />
                            </div> */}
                            <div className={styles.carts_quantity}>{item.quantity}</div>
                        </div>

                    </div>
                    <div className={styles.contining_carts_left}>
                        <div className={styles.product_name}>{item.productName} {item.nameSKU ? ` - מק''ט: ` + item.nameSKU : ''}</div>
                        <div className={styles.contind_details_carts}>
                            {item.size ? <p ><b className={styles.contind_details_carts_b}>גודל: </b>רוחב: {JSON.parse(item.size).widthSize} - גובה: {JSON.parse(item.size).heightSize}</p> : ''}
                            {JSON.parse(item.productDetails).map((item, index) => (
                                <p key={index} ><b className={styles.contind_details_carts_b}>{item.k}: </b>{item.v}</p>
                            ))}
                        </div>
                        <div className={styles.carts_contining_c}>
                            <div className={styles.carts_price}>{item.price} <p>ש''ח</p></div>
                        </div>
                    </div>
                </div>
            ));
    };
    return (
        <div ref={refSideCart} className={styles.continer}>
            <div>
                <h2>סל קניות</h2>
                <div className={styles.close} onClick={() => setIsOpen(false)}>
                    <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="1.35355" y1="0.646447" x2="33.3536" y2="32.6464" stroke="black" />
                        <line y1="-0.5" x2="45.2548" y2="-0.5" transform="matrix(-0.707107 0.707107 0.707107 0.707107 33 1)" stroke="black" />
                    </svg>
                </div>
                <hr />
                <div className={styles.celect_all_continer}>
                    <input type="checkbox" checked={cartsAllCheckbox} onChange={onChangeAllCheckboxCart} />
                    <div className={styles.celect_all_continer_text}>
                        בחר הכל
                    </div>
                </div>
            </div>
            <div className={styles.carts_continer} id={styles.contining_items_style_15}>
                {divCarts()}
            </div>
            <div className={styles.contining_carts_sum}>
                <div className={styles.contining_carts_info}>
                    <hr />
                    <div className={styles.contining_carts_price}>
                        <p>נבחרו</p>
                        <div className={styles.inside}>
                            <h5>{countCart}</h5>
                            <p>מוצרים</p>
                        </div>
                    </div>
                    <div className={styles.contining_carts_price}>
                        <p>סכום ביניים</p>
                        <div className={styles.inside}>
                            <h5>{price}</h5>
                            <p> ש''ח</p>
                        </div>
                    </div>
                    <hr />
                </div>
                <div onClick={() => history.push("/cart")} className={styles.button_for_cart}> מעבר לסל קניות</div>
                <div onClick={() => onClickPayment()} className={styles.button_for_payment}> לתשלום</div>
            </div>
        </div>
    );
};


const mapStateToProps = state => {
    return {
        carts: state.cart,
        userData: state.users,
    }
}
export default connect(mapStateToProps, { deleteUserCartFun, sedOrderPayment })(SideCart);

