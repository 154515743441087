export const CHANGE_CALCULATOR_SCREENS = 'CHANGE_CALCULATOR_SCREENS';
export const CHANGE_CALCULATOR_VALUES = 'CHANGE_CALCULATOR_VALUES';

export const changeScreens = (screen, value) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_CALCULATOR_SCREENS, payload: {screen: screen, value: value} });
    };
};
export const changeScreensValues = (screen, obj) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_CALCULATOR_VALUES, payload: {screen: screen, obj: obj} });
    };
};
